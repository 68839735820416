import { FindCountNotReadNotificationOutput } from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const findCountNotReadNotificationRequest = (
  select: Select<FindCountNotReadNotificationOutput> | string
) => `query findCountNotReadNotification {
  data: findCountNotReadNotification {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'FindCountNotReadNotificationOutput')}`;
export const findCountNotReadNotification =
  (select: Select<FindCountNotReadNotificationOutput> | string) => () =>
    query<FindCountNotReadNotificationOutput>(
      findCountNotReadNotificationRequest(select)
    );
