import { combine } from 'effector';
import { createGate } from 'effector-react';
import i18next from 'i18next';
import type { useTranslation } from 'react-i18next';
import type { SchemaOf } from 'yup';
import { array, mixed, object, string } from 'yup';

import { listen, modelFactory, setState } from '@kuna-pay/utils/effector';
import { notify } from '@kuna-pay/ui/notification';
import { createSingleModal } from '@kuna-pay/ui/ui/modal';
import { createForm } from '@kuna-pay/form';
import { State } from '@kuna-pay/core/shared/lib/state';

import type {
  FeedbackFormInValues,
  FeedbackFormOutValues,
  GenericFeedbackModelProps,
} from './generic-feedback.types';
import { GenericFeedbackSteps } from './generic-feedback.types';

const GenericFeedbackModel = modelFactory(
  ({
    MAX_DESCRIPTION_LENGTH = 500,
    MAX_ATTACHMENTS = 10,
    sendFeedbackFx,
  }: GenericFeedbackModelProps) => {
    const $$modal = createSingleModal();
    const Gate = createGate<{ i18n: ReturnType<typeof useTranslation> }>();

    const $$state = State.factory.createModel<GenericFeedbackSteps>(
      GenericFeedbackSteps.Feedback
    );

    const $$form = createForm<FeedbackFormInValues>({
      initialValues: {
        feedback: '',
        attachments: [],
      },

      schema: combine(
        Gate.state,
        (): SchemaOf<FeedbackFormInValues> =>
          object({
            feedback: string()
              .trim()
              .max(MAX_DESCRIPTION_LENGTH)
              .required()
              .label(
                i18next.t(
                  'shared.feedback.generic-feedback.fields.feedback.errors.label',
                  { ns: 'core' }
                )
              ),

            attachments: array()
              .of(mixed<File>())
              .max(MAX_ATTACHMENTS)
              .label(
                i18next.t(
                  'shared.feedback.generic-feedback.fields.attachments.errors.label',
                  { ns: 'core' }
                )
              ),
          })
      ),
    });

    listen({
      clock: $$form.submitted.map((values) => values as FeedbackFormOutValues),

      handler: async (values) => {
        try {
          setState($$form.$disabled, true);

          await sendFeedbackFx(values);

          $$state.next(GenericFeedbackSteps.Success);
        } catch {
          notify.warning(
            i18next.t(
              'shared.feedback.generic-feedback.send-feedback-request.failed',
              { ns: 'core' }
            )
          );
        } finally {
          setState($$form.$disabled, false);
        }
      },
    });

    listen({
      clock: $$modal.closed,
      handler: () => {
        $$state.reset();
        $$form.reinit();
      },
    });

    return {
      $$modal,

      $$ui: {
        Gate,

        $$modal: $$modal.$$modal,
        ID: $$modal.ID,

        $$state,
        $$form,

        MAX_DESCRIPTION_LENGTH,
        MAX_ATTACHMENTS,
      },
    };
  }
);

export { GenericFeedbackModel };
