import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import type {
  ComponentPropsWithoutRef,
  HTMLAttributes,
  ReactNode,
} from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { ChevronRight24Icon } from '@kuna-pay/ui/icons';
import { Link } from '@kuna-pay/ui/router';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';
import { Typography } from '@kuna-pay/ui/ui/typography';

import styles from './nav-button.module.scss';

type NavButtonProps = ComponentPropsWithoutRef<typeof Link.ForwardRef> & {
  icon: ReactNode;
};

const NavButton = createCompoundComponent(
  (C) =>
    ({ icon, children, ...props }: NavButtonProps) => (
      <C.Root asChild>
        <Link.ForwardRef {...props}>
          <C.Content>
            <C.Icon>{icon}</C.Icon>

            <C.Text>{children}</C.Text>
          </C.Content>

          <C.Arrow />
        </Link.ForwardRef>
      </C.Root>
    ),
  {
    Root: ({
      asChild,
      className,
      ...props
    }: HTMLAttributes<HTMLDivElement> & { asChild?: boolean }) => {
      const Component = asChild ? Slot : 'div';

      return <Component className={clsx(styles.root, className)} {...props} />;
    },

    Content: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.content, className)} {...props} />
    ),

    Icon: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.icon, className)} {...props} />
    ),

    Text: ({ className, ...props }: Partial<TypographyProps>) => (
      <Typography
        className={clsx(styles.text, className)}
        variant='subtitle5'
        {...props}
      />
    ),

    Arrow: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.arrow, className)} {...props}>
        <ChevronRight24Icon />
      </div>
    ),
  }
);

export { NavButton };
