export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  /** The `BigInt` custom scalar type */
  BigInt: { input: any; output: any; }
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: { input: any; output: any; }
  /** The `Decimal` custom scalar type */
  Decimal: { input: any; output: any; }
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: { input: any; output: any; }
};

export type ApiKeyOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
};

export type ApiKeyOutput = {
  allowedOperations: Array<CompanyAllowedOperationEnum>;
  createdAt: Scalars['DateTime']['output'];
  creatorUserId: Scalars['String']['output'];
  endAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  ipWhiteList: Array<Scalars['String']['output']>;
  merchantId: Scalars['String']['output'];
  name: Scalars['String']['output'];
  payload: Scalars['JSON']['output'];
  publicKey?: Maybe<Scalars['String']['output']>;
  type: TypeOfSignature;
};

export type AssetNullableRelationFilter = {
  is?: InputMaybe<AssetWhereInput>;
  isNot?: InputMaybe<AssetWhereInput>;
};

export type AssetOrderByInput = {
  code?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  name?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
  rateFreezeSeconds?: InputMaybe<SortOrder>;
  type?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type AssetPaymentMethod = {
  Asset?: Maybe<PublicAssetOutput>;
  Product: ProductOutput;
  asset: Scalars['String']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customTitle?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  fields: Array<PaymentMethodField>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isPrefill?: Maybe<Scalars['Boolean']['output']>;
  isRequisiteDeleteDisabled?: Maybe<Scalars['Boolean']['output']>;
  isRequisiteUpdateDisabled?: Maybe<Scalars['Boolean']['output']>;
  logicalTime: Scalars['BigInt']['output'];
  maxRequisiteCount?: Maybe<Scalars['Int']['output']>;
  merchantId: Scalars['String']['output'];
  messages: Array<PaymentMethodMessage>;
  name: Scalars['String']['output'];
  network?: Maybe<Scalars['String']['output']>;
  payload: Scalars['JSON']['output'];
  position: Scalars['Int']['output'];
  processor: Scalars['String']['output'];
  purposeField?: Maybe<Scalars['String']['output']>;
  saasOperation: Scalars['String']['output'];
  transferType: TransferType;
  trustWalletCoinId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type AssetRelationFilter = {
  is?: InputMaybe<AssetWhereInput>;
  isNot?: InputMaybe<AssetWhereInput>;
};

export type AssetSettingsOutput = {
  invoiceCoefficient: Scalars['Decimal']['output'];
};

export enum AssetType {
  Crypto = 'Crypto',
  Fiat = 'Fiat'
}

export type AssetWhereInput = {
  AND?: InputMaybe<Array<AssetWhereInput>>;
  CompanyAssetSettings?: InputMaybe<CompanyAssetSettingsListRelationFilter>;
  CompanyReferralPayoutCurrency?: InputMaybe<CompanyListRelationFilter>;
  CompanyWithAutoConvertCurrency?: InputMaybe<CompanyListRelationFilter>;
  CompanyWithReferenceCurrency?: InputMaybe<CompanyListRelationFilter>;
  ConvertFrom?: InputMaybe<ConvertListRelationFilter>;
  ConvertTo?: InputMaybe<ConvertListRelationFilter>;
  InvoiceWithAsset?: InputMaybe<InvoiceListRelationFilter>;
  InvoiceWithPaymentAsset?: InputMaybe<InvoiceListRelationFilter>;
  NOT?: InputMaybe<Array<AssetWhereInput>>;
  OR?: InputMaybe<Array<AssetWhereInput>>;
  PaymentMethod?: InputMaybe<PaymentMethodListRelationFilter>;
  PayoutPaymentSummary?: InputMaybe<PayoutPaymentSummaryListRelationFilter>;
  Requisite?: InputMaybe<RequisiteListRelationFilter>;
  Transfer?: InputMaybe<TransferListRelationFilter>;
  TransferLimit?: InputMaybe<TransferLimitListRelationFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  defaultInvoiceCoefficient?: InputMaybe<DecimalFilter>;
  description?: InputMaybe<StringFilter>;
  enabled?: InputMaybe<BoolFilter>;
  hidden?: InputMaybe<BoolFilter>;
  isAutoConvertCurrency?: InputMaybe<BoolFilter>;
  isInvoiceCurrency?: InputMaybe<BoolFilter>;
  isPaymentCurrency?: InputMaybe<BoolFilter>;
  isReferenceCurrency?: InputMaybe<BoolFilter>;
  isReferralPayoutCurrency?: InputMaybe<BoolFilter>;
  minInvoiceAmount?: InputMaybe<DecimalFilter>;
  name?: InputMaybe<StringFilter>;
  payload?: InputMaybe<JsonFilter>;
  position?: InputMaybe<IntFilter>;
  precision?: InputMaybe<IntFilter>;
  rateFreezeSeconds?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumAssetTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type AuthTokensOutput = {
  /** Jwt token with session info */
  accessToken: Scalars['String']['output'];
  /** When access token will expire */
  expiresIn: Scalars['Int']['output'];
  /** Token for refresh access token */
  refreshToken: Scalars['String']['output'];
};

export type AuthenticateFreshDeskOutput = {
  /** FreshDesk token */
  token: Scalars['String']['output'];
};

export type AutoConvertPaymentMethodPublicOutput = {
  Product: PublicProductOutput;
  name: Scalars['String']['output'];
};

export type AvailableCoworkerRolesOutput = {
  roles: Array<Scalars['String']['output']>;
};

export enum AvailableLanguages {
  De = 'de',
  En = 'en',
  Es = 'es',
  Fr = 'fr',
  It = 'it',
  Lt = 'lt',
  Pl = 'pl',
  Pt = 'pt',
  Uk = 'uk'
}

export type AvailableRabacRolesOutput = {
  roles: Array<Scalars['String']['output']>;
};

export type AvailableTypesForCreateCompanyOutput = {
  types: Array<CompanyType>;
};

export type BigIntFilter = {
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type BoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type CpuDetails = {
  /** Value examples: '68k', 'amd64', 'arm', 'arm64', 'avr'... */
  architecture?: Maybe<Scalars['String']['output']>;
};

export type CalculatedCompanyLimitOutput = {
  CompanyLimit?: Maybe<CompanyLimitOutput>;
  isExceeded: Scalars['Boolean']['output'];
  /** Left amount according to limits */
  leftAmount: Scalars['Decimal']['output'];
  usedAmount: Scalars['Decimal']['output'];
};

export type CheckCompanyExistOutput = {
  exists: Scalars['Boolean']['output'];
};

export type CheckResult = {
  meta?: Maybe<Scalars['JSON']['output']>;
  name: Scalars['String']['output'];
  ok: Scalars['Boolean']['output'];
};

export type CheckUsernameOutput = {
  exists: Scalars['Boolean']['output'];
};

export type CocktailPromoOutput = {
  Address: NonAuthDepositAddressOutput;
  Company: NonAuthCompanyDetailsOutput;
  EquivalentAsset: PublicAssetOutput;
  Method: PublicPaymentMethodOutput;
  PaymentAsset: PublicAssetOutput;
  equivalentAmount: Scalars['String']['output'];
  expiresAt?: Maybe<Scalars['DateTime']['output']>;
  paymentAmount: Scalars['String']['output'];
};

export type Company = {
  autoConversionCurrency?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  identCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  identReminderSentAt?: Maybe<Scalars['DateTime']['output']>;
  identificationStatus: IdentificationStatus;
  industry?: Maybe<Scalars['String']['output']>;
  invoiceFeeDiscountDisabledAt?: Maybe<Scalars['DateTime']['output']>;
  invoiceFeeDiscountEndAt?: Maybe<Scalars['DateTime']['output']>;
  invoiceFeeDiscountReminderSentAt?: Maybe<Scalars['DateTime']['output']>;
  invoiceReminderSentAt?: Maybe<Scalars['DateTime']['output']>;
  invoiceUnpaidPercent: Scalars['Decimal']['output'];
  isApiBlocked: Scalars['Boolean']['output'];
  isLimitsExceeded: Scalars['Boolean']['output'];
  isPsp: Scalars['Boolean']['output'];
  isSuspicious: Scalars['Boolean']['output'];
  isVolatilityCoverageEnabled: Scalars['Boolean']['output'];
  kycId?: Maybe<Scalars['String']['output']>;
  kycSource: CompanyKycSource;
  legalName?: Maybe<Scalars['String']['output']>;
  logicalTime: Scalars['BigInt']['output'];
  merchantId: Scalars['String']['output'];
  payload: Scalars['JSON']['output'];
  referenceCurrency: Scalars['String']['output'];
  referralExpirationSeconds?: Maybe<Scalars['Int']['output']>;
  referralPayoutCurrency: Scalars['String']['output'];
  status: StatusOfCompany;
  supportInfo?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: CompanyType;
  updatedAt: Scalars['DateTime']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export enum CompanyAllowedOperationEnum {
  Deposit = 'Deposit',
  Invoicing = 'Invoicing',
  Read = 'Read',
  Withdrawal = 'Withdrawal'
}

export type CompanyAsset = {
  Asset: PublicAssetOutput;
  arrestedBalance: Scalars['Decimal']['output'];
  balance: Scalars['Decimal']['output'];
  lockBalance: Scalars['Decimal']['output'];
  suspendedBalance: Scalars['Decimal']['output'];
};

export type CompanyAssetSettingsListRelationFilter = {
  every?: InputMaybe<CompanyAssetSettingsWhereInput>;
  none?: InputMaybe<CompanyAssetSettingsWhereInput>;
  some?: InputMaybe<CompanyAssetSettingsWhereInput>;
};

export type CompanyAssetSettingsWhereInput = {
  AND?: InputMaybe<Array<CompanyAssetSettingsWhereInput>>;
  Asset?: InputMaybe<AssetRelationFilter>;
  Company?: InputMaybe<CompanyRelationFilter>;
  NOT?: InputMaybe<Array<CompanyAssetSettingsWhereInput>>;
  OR?: InputMaybe<Array<CompanyAssetSettingsWhereInput>>;
  assetCode?: InputMaybe<StringFilter>;
  companyId?: InputMaybe<StringFilter>;
  invoiceCoefficient?: InputMaybe<DecimalFilter>;
};

export type CompanyDetailsOutput = {
  AutoConvertAsset?: Maybe<PublicAssetOutput>;
  CompanyOwner: CompanyOwnerOutput;
  CompanyPayload?: Maybe<CompanyPayloadForCompanyOutput>;
  ReferenceCurrencyAsset: PublicAssetOutput;
  ReferralPayoutAsset: PublicAssetOutput;
  autoConversionCurrency?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  /** @deprecated Will be removed, need use `CompanyOwner` */
  companyOwner: CompanyOwnerOutput;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  identCompletedAt?: Maybe<Scalars['DateTime']['output']>;
  identReminderSentAt?: Maybe<Scalars['DateTime']['output']>;
  identificationStatus: IdentificationStatus;
  industry?: Maybe<Scalars['String']['output']>;
  invoiceFeeDiscountDisabledAt?: Maybe<Scalars['DateTime']['output']>;
  invoiceFeeDiscountEndAt?: Maybe<Scalars['DateTime']['output']>;
  invoiceFeeDiscountReminderSentAt?: Maybe<Scalars['DateTime']['output']>;
  invoiceReminderSentAt?: Maybe<Scalars['DateTime']['output']>;
  invoiceUnpaidPercent: Scalars['Decimal']['output'];
  isApiBlocked: Scalars['Boolean']['output'];
  isLimitsExceeded: Scalars['Boolean']['output'];
  isPsp: Scalars['Boolean']['output'];
  isSuspicious: Scalars['Boolean']['output'];
  isVolatilityCoverageEnabled: Scalars['Boolean']['output'];
  legalName?: Maybe<Scalars['String']['output']>;
  merchantId: Scalars['String']['output'];
  payload: Scalars['JSON']['output'];
  referenceCurrency: Scalars['String']['output'];
  referralPayoutCurrency: Scalars['String']['output'];
  status: StatusOfCompany;
  supportInfo?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  type: CompanyType;
  updatedAt: Scalars['DateTime']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type CompanyFeeSettingsListRelationFilter = {
  every?: InputMaybe<CompanyFeeSettingsWhereInput>;
  none?: InputMaybe<CompanyFeeSettingsWhereInput>;
  some?: InputMaybe<CompanyFeeSettingsWhereInput>;
};

export type CompanyFeeSettingsOutput = {
  feeStrategy?: Maybe<CompanyFeeStrategy>;
  type: CompanyFeeType;
};

export type CompanyFeeSettingsWhereInput = {
  AND?: InputMaybe<Array<CompanyFeeSettingsWhereInput>>;
  Company?: InputMaybe<CompanyRelationFilter>;
  NOT?: InputMaybe<Array<CompanyFeeSettingsWhereInput>>;
  OR?: InputMaybe<Array<CompanyFeeSettingsWhereInput>>;
  companyId?: InputMaybe<StringFilter>;
  feeStrategy?: InputMaybe<EnumCompanyFeeStrategyNullableFilter>;
  increaseFeeRate?: InputMaybe<DecimalFilter>;
  type?: InputMaybe<EnumCompanyFeeTypeFilter>;
};

export enum CompanyFeeStrategy {
  External = 'external',
  Internal = 'internal'
}

export enum CompanyFeeType {
  Invoice = 'Invoice'
}

export type CompanyIdOutput = {
  /** Company ID */
  id: Scalars['String']['output'];
};

export enum CompanyKycSource {
  KunaPay = 'KUNA_PAY',
  KunaPro = 'KUNA_PRO'
}

export type CompanyLimitOutput = {
  companyType: CompanyType;
  id: Scalars['String']['output'];
  limit: Scalars['Decimal']['output'];
  period: CompanyLimitPeriod;
  type: CompanyLimitType;
};

export enum CompanyLimitPeriod {
  Day = 'Day',
  Month = 'Month'
}

export enum CompanyLimitType {
  Income = 'Income',
  Outcome = 'Outcome'
}

export type CompanyListRelationFilter = {
  every?: InputMaybe<CompanyWhereInput>;
  none?: InputMaybe<CompanyWhereInput>;
  some?: InputMaybe<CompanyWhereInput>;
};

export enum CompanyNotificationEntities {
  AutoConvert = 'autoConvert',
  Deposit = 'deposit',
  Invoice = 'invoice',
  Payout = 'payout',
  ReferralProgram = 'referralProgram',
  Withdraw = 'withdraw'
}

export type CompanyNotificationSettingsOutput = {
  autoConvert: NotificationSettingsDetailsOutput;
  deposit: NotificationSettingsDetailsOutput;
  invoice: NotificationSettingsDetailsOutput;
  payout: NotificationSettingsDetailsOutput;
  referralProgram: NotificationSettingsDetailsOutput;
  withdraw: NotificationSettingsDetailsOutput;
};

export type CompanyNullableRelationFilter = {
  is?: InputMaybe<CompanyWhereInput>;
  isNot?: InputMaybe<CompanyWhereInput>;
};

export type CompanyOwnerOutput = {
  /** Owner email */
  email: Scalars['String']['output'];
  /** Owner user id */
  id: Scalars['String']['output'];
  /** Is owner KUNA employee */
  isKUNAEmployee: Scalars['Boolean']['output'];
};

export type CompanyPayloadForCompanyOutput = {
  pspSettings?: Maybe<CompanyPayloadPspSettingsOutput>;
};

export type CompanyPayloadInput = {
  averageTransactionAmount?: InputMaybe<Scalars['String']['input']>;
  goal?: InputMaybe<Scalars['String']['input']>;
  monthlyTurnover?: InputMaybe<Scalars['String']['input']>;
  sourceOfKnowledge: Scalars['String']['input'];
};

export type CompanyPayloadPspSettingsOutput = {
  increaseInvoiceRateForReferrals?: Maybe<Scalars['Float']['output']>;
};

export type CompanyReferralListRelationFilter = {
  every?: InputMaybe<CompanyReferralWhereInput>;
  none?: InputMaybe<CompanyReferralWhereInput>;
  some?: InputMaybe<CompanyReferralWhereInput>;
};

export type CompanyReferralWhereInput = {
  AND?: InputMaybe<Array<CompanyReferralWhereInput>>;
  Affiliate?: InputMaybe<CompanyRelationFilter>;
  NOT?: InputMaybe<Array<CompanyReferralWhereInput>>;
  OR?: InputMaybe<Array<CompanyReferralWhereInput>>;
  Referral?: InputMaybe<CompanyRelationFilter>;
  affiliateCompanyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expireAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  referralCompanyId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CompanyRelationCoworkerProfileOrderByInput = {
  fullName?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  username?: InputMaybe<SortOrder>;
};

export type CompanyRelationDetailsOrderByInput = {
  Profile?: InputMaybe<CompanyRelationCoworkerProfileOrderByInput>;
  companyId?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  role?: InputMaybe<SortOrder>;
  userId?: InputMaybe<SortOrder>;
};

export type CompanyRelationFilter = {
  is?: InputMaybe<CompanyWhereInput>;
  isNot?: InputMaybe<CompanyWhereInput>;
};

export type CompanyRelationOutput = {
  Profile: CoworkerInfo;
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  isActive: Scalars['Boolean']['output'];
  role: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
  userId: Scalars['String']['output'];
};

export type CompanyStatisticByInvoicesOutput = {
  Asset: PublicAssetOutput;
  paid: Scalars['Decimal']['output'];
  pending: Scalars['Decimal']['output'];
  timeout: Scalars['Decimal']['output'];
};

export type CompanyTransferOrderInput = {
  amount?: InputMaybe<SortOrder>;
  asset?: InputMaybe<SortOrder>;
  createdAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type CompanyTransferOutput = {
  APIKey?: Maybe<TransferApiKeyCreatorOutput>;
  AdditionalFields: Array<TransferAdditionalFieldsOutput>;
  AmlDetails?: Maybe<TransferAmlDetailsForCompanyOutput>;
  Asset: PublicAssetOutput;
  Company: Company;
  Convert?: Maybe<ConvertPublicOutput>;
  Creator?: Maybe<InitiatorProfileDetailsOutput>;
  Details: TransferDetailsOutput;
  Invoice?: Maybe<InvoiceDetailsOutput>;
  PaymentMethod: PaymentMethod;
  PayoutOperation?: Maybe<PublicPayoutOperationOutput>;
  TransferStatusHistory?: Maybe<Array<TransferStatusHistory>>;
  address?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Decimal']['output'];
  asset: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  convertId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorComment?: Maybe<Scalars['String']['output']>;
  creatorId?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['String']['output'];
  invoiceId?: Maybe<Scalars['String']['output']>;
  isCreatedByApi: Scalars['Boolean']['output'];
  logicalTime: Scalars['BigInt']['output'];
  merchantId: Scalars['String']['output'];
  paymentCode: Scalars['String']['output'];
  payoutOperationId?: Maybe<Scalars['String']['output']>;
  processedAmount?: Maybe<Scalars['Decimal']['output']>;
  reason: Array<Scalars['String']['output']>;
  status: TransferStatus;
  type: TransferType;
  updatedAt: Scalars['DateTime']['output'];
};

export enum CompanyType {
  Basic = 'BASIC',
  Entrepreneur = 'ENTREPRENEUR',
  LegalEntity = 'LEGAL_ENTITY'
}

export type CompanyUserUniqueInput = {
  /** Coworker User ID */
  userId: Scalars['String']['input'];
};

export type CompanyWhereInput = {
  AND?: InputMaybe<Array<CompanyWhereInput>>;
  Affiliate?: InputMaybe<CompanyReferralListRelationFilter>;
  AutoConvertAsset?: InputMaybe<AssetNullableRelationFilter>;
  CompanyAssetSettings?: InputMaybe<CompanyAssetSettingsListRelationFilter>;
  CompanyFeeSettings?: InputMaybe<CompanyFeeSettingsListRelationFilter>;
  Convert?: InputMaybe<ConvertListRelationFilter>;
  DepositAddress?: InputMaybe<DepositAddressListRelationFilter>;
  InvitedCoworker?: InputMaybe<InvitedCoworkerListRelationFilter>;
  Invoices?: InputMaybe<InvoiceListRelationFilter>;
  NOT?: InputMaybe<Array<CompanyWhereInput>>;
  Note?: InputMaybe<NoteListRelationFilter>;
  Notification?: InputMaybe<NotificationListRelationFilter>;
  NotificationSettings?: InputMaybe<CustomNotificationSettingsListRelationFilter>;
  OR?: InputMaybe<Array<CompanyWhereInput>>;
  Payout?: InputMaybe<PayoutListRelationFilter>;
  Questionnaire?: InputMaybe<QuestionnaireListRelationFilter>;
  ReferenceCurrencyAsset?: InputMaybe<AssetRelationFilter>;
  Referral?: InputMaybe<CompanyReferralListRelationFilter>;
  ReferralPayoutAsset?: InputMaybe<AssetRelationFilter>;
  ReferralUser?: InputMaybe<ProfileListRelationFilter>;
  Requisite?: InputMaybe<RequisiteListRelationFilter>;
  Transfer?: InputMaybe<TransferListRelationFilter>;
  TransferLimit?: InputMaybe<TransferLimitListRelationFilter>;
  UserRelation?: InputMaybe<RelationUserToCompanyListRelationFilter>;
  Zen?: InputMaybe<ZenNullableRelationFilter>;
  autoConversionCurrency?: InputMaybe<StringNullableFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  identCompletedAt?: InputMaybe<DateTimeNullableFilter>;
  identReminderSentAt?: InputMaybe<DateTimeNullableFilter>;
  identificationStatus?: InputMaybe<EnumIdentificationStatusFilter>;
  industry?: InputMaybe<StringNullableFilter>;
  invoiceFeeDiscountDisabledAt?: InputMaybe<DateTimeNullableFilter>;
  invoiceFeeDiscountEndAt?: InputMaybe<DateTimeNullableFilter>;
  invoiceFeeDiscountReminderSentAt?: InputMaybe<DateTimeNullableFilter>;
  invoiceReminderSentAt?: InputMaybe<DateTimeNullableFilter>;
  invoiceUnpaidPercent?: InputMaybe<DecimalFilter>;
  isApiBlocked?: InputMaybe<BoolFilter>;
  isLimitsExceeded?: InputMaybe<BoolFilter>;
  isPsp?: InputMaybe<BoolFilter>;
  isSuspicious?: InputMaybe<BoolFilter>;
  isVolatilityCoverageEnabled?: InputMaybe<BoolFilter>;
  kycId?: InputMaybe<StringNullableFilter>;
  kycSource?: InputMaybe<EnumCompanyKycSourceFilter>;
  legalName?: InputMaybe<StringNullableFilter>;
  logicalTime?: InputMaybe<BigIntFilter>;
  merchantId?: InputMaybe<StringFilter>;
  payload?: InputMaybe<JsonFilter>;
  referenceCurrency?: InputMaybe<StringFilter>;
  referralExpirationSeconds?: InputMaybe<IntNullableFilter>;
  referralPayoutCurrency?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumStatusOfCompanyFilter>;
  supportInfo?: InputMaybe<StringNullableFilter>;
  title?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumCompanyTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  websiteUrl?: InputMaybe<StringNullableFilter>;
};

/** Allowed to enable confirm entities */
export enum ConfirmationEntities {
  Withdraw = 'withdraw'
}

export type ConfirmationInput = {
  entity: ConfirmationEntities;
  isEnabled: Scalars['Boolean']['input'];
};

export type ConfirmationSetting = {
  withdraw: Scalars['Boolean']['output'];
};

export type ConfirmationSettingNullableRelationFilter = {
  is?: InputMaybe<ConfirmationSettingWhereInput>;
  isNot?: InputMaybe<ConfirmationSettingWhereInput>;
};

export type ConfirmationSettingWhereInput = {
  AND?: InputMaybe<Array<ConfirmationSettingWhereInput>>;
  NOT?: InputMaybe<Array<ConfirmationSettingWhereInput>>;
  OR?: InputMaybe<Array<ConfirmationSettingWhereInput>>;
  Profile?: InputMaybe<ProfileRelationFilter>;
  id?: InputMaybe<StringFilter>;
  withdraw?: InputMaybe<BoolFilter>;
};

export type ConvertListRelationFilter = {
  every?: InputMaybe<ConvertWhereInput>;
  none?: InputMaybe<ConvertWhereInput>;
  some?: InputMaybe<ConvertWhereInput>;
};

export type ConvertNullableRelationFilter = {
  is?: InputMaybe<ConvertWhereInput>;
  isNot?: InputMaybe<ConvertWhereInput>;
};

export type ConvertPublicOutput = {
  FromCurrency: PublicAssetOutput;
  ToCurrency: PublicAssetOutput;
  amount: Scalars['Decimal']['output'];
  companyId: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  fee: Scalars['Decimal']['output'];
  fromAmount: Scalars['Decimal']['output'];
  fromCurrency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  logicalTime: Scalars['BigInt']['output'];
  processedAmount: Scalars['Decimal']['output'];
  status: ConvertStatus;
  toCurrency: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type ConvertPublicWhereInput = {
  createdAt?: InputMaybe<DateTimeFilter>;
  fromCurrency?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumConvertStatusFilter>;
  toCurrency?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum ConvertStatus {
  Canceled = 'Canceled',
  Created = 'Created',
  Processed = 'Processed',
  Processing = 'Processing'
}

export enum ConvertSupportStatus {
  Canceled = 'Canceled',
  Created = 'Created',
  Processed = 'Processed',
  Processing = 'Processing',
  TechTrouble = 'TechTrouble'
}

export type ConvertWhereInput = {
  AND?: InputMaybe<Array<ConvertWhereInput>>;
  Company?: InputMaybe<CompanyRelationFilter>;
  FromCurrency?: InputMaybe<AssetRelationFilter>;
  NOT?: InputMaybe<Array<ConvertWhereInput>>;
  OR?: InputMaybe<Array<ConvertWhereInput>>;
  ToCurrency?: InputMaybe<AssetRelationFilter>;
  Transfer?: InputMaybe<TransferNullableRelationFilter>;
  amount?: InputMaybe<DecimalFilter>;
  companyId?: InputMaybe<StringFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  fee?: InputMaybe<DecimalFilter>;
  fromAmount?: InputMaybe<DecimalFilter>;
  fromCurrency?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  logicalTime?: InputMaybe<BigIntFilter>;
  payload?: InputMaybe<JsonFilter>;
  processedAmount?: InputMaybe<DecimalFilter>;
  reason?: InputMaybe<StringNullableListFilter>;
  status?: InputMaybe<EnumConvertStatusFilter>;
  supportStatus?: InputMaybe<EnumConvertSupportStatusFilter>;
  toCurrency?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type CountOfActiveReferralByCompany = {
  count: Scalars['Int']['output'];
};

export type CoworkerInfo = {
  email: Scalars['String']['output'];
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  username: Scalars['String']['output'];
};

export type CreateBusinessAccountLink = {
  confirmationUrl: Scalars['String']['output'];
};

export type CreateInvoiceCustomizationInput = {
  redirectButtonTitle?: InputMaybe<Scalars['String']['input']>;
  redirectUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreateInvoiceOutput = {
  PaymentLink: InvoicePaymentLinkOutput;
  /** Unique record identifier */
  id: Scalars['String']['output'];
};

export type CreateInvoiceReportWhereInput = {
  assets?: InputMaybe<Array<Scalars['String']['input']>>;
  dateRange: DateRangeInput;
  invoiceId?: InputMaybe<Scalars['String']['input']>;
  statuses?: InputMaybe<Array<InvoiceStatus>>;
};

export type CreateOneCompanyInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  industry?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<CompanyPayloadInput>;
  /** The reference currency of the company */
  referenceCurrency: Scalars['String']['input'];
  title: Scalars['String']['input'];
  type: CompanyType;
  websiteUrl?: InputMaybe<Scalars['String']['input']>;
};

export type CreatePayoutContractorInput = {
  amount: Scalars['String']['input'];
  asset: Scalars['String']['input'];
  comment?: InputMaybe<Scalars['String']['input']>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Scalars['JSON']['input']>;
  /** Contractor name */
  name: Scalars['String']['input'];
  paymentMethodCode: Scalars['String']['input'];
};

export type CreatePayoutOutput = {
  id?: Maybe<Scalars['String']['output']>;
  issues?: Maybe<Array<PayoutIssueOutput>>;
  success: Scalars['Boolean']['output'];
};

export type CreateQuestionnaireFieldInput = {
  name: Scalars['String']['input'];
};

export type CreateQuestionnaireInput = {
  schemaFields: Array<CreateQuestionnaireFieldInput>;
};

export type CreateReportSettings = {
  format?: InputMaybe<ReportFileFormat>;
  /** dayjs timezone */
  timeZone?: InputMaybe<Scalars['String']['input']>;
};

export type CreateReportWhereInput = {
  asset?: InputMaybe<StringFilter>;
  fromDate: Scalars['DateTime']['input'];
  toDate: Scalars['DateTime']['input'];
  type?: InputMaybe<EnumTransferTypeFilter>;
};

export type CreatedReportOutput = {
  url: Scalars['String']['output'];
};

export type CreatedTransactionReportOutput = {
  url: Scalars['String']['output'];
};

export type CustomNotificationSettingsListRelationFilter = {
  every?: InputMaybe<CustomNotificationSettingsWhereInput>;
  none?: InputMaybe<CustomNotificationSettingsWhereInput>;
  some?: InputMaybe<CustomNotificationSettingsWhereInput>;
};

export type CustomNotificationSettingsWhereInput = {
  AND?: InputMaybe<Array<CustomNotificationSettingsWhereInput>>;
  Company?: InputMaybe<CompanyNullableRelationFilter>;
  NOT?: InputMaybe<Array<CustomNotificationSettingsWhereInput>>;
  OR?: InputMaybe<Array<CustomNotificationSettingsWhereInput>>;
  Profile?: InputMaybe<ProfileRelationFilter>;
  companyId?: InputMaybe<StringNullableFilter>;
  email?: InputMaybe<BoolFilter>;
  entity?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  push?: InputMaybe<BoolFilter>;
  userId?: InputMaybe<StringFilter>;
};

export enum DashboardStatisticTimePart {
  Day = 'day',
  Hour = 'hour'
}

export type DataForDashboardOutput = {
  sumAmount: Scalars['String']['output'];
  timestamp: Scalars['DateTime']['output'];
};

export type DateRangeInput = {
  from: Scalars['DateTime']['input'];
  to: Scalars['DateTime']['input'];
};

export type DateRangeOutput = {
  from: Scalars['DateTime']['output'];
  to: Scalars['DateTime']['output'];
};

export type DateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type DecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type DecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type DepositAddress = {
  address: Scalars['String']['output'];
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  expireAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  merchantId: Scalars['String']['output'];
  payload: Scalars['JSON']['output'];
  source: Scalars['String']['output'];
  type: DepositAddressType;
};

export type DepositAddressListRelationFilter = {
  every?: InputMaybe<DepositAddressWhereInput>;
  none?: InputMaybe<DepositAddressWhereInput>;
  some?: InputMaybe<DepositAddressWhereInput>;
};

export type DepositAddressNullableRelationFilter = {
  is?: InputMaybe<DepositAddressWhereInput>;
  isNot?: InputMaybe<DepositAddressWhereInput>;
};

export type DepositAddressPublicOutput = {
  address: Scalars['String']['output'];
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  expireAt?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
  type: DepositAddressType;
};

export enum DepositAddressType {
  Const = 'Const',
  Invoice = 'Invoice',
  OneTime = 'OneTime'
}

export type DepositAddressWhereInput = {
  AND?: InputMaybe<Array<DepositAddressWhereInput>>;
  Company?: InputMaybe<CompanyNullableRelationFilter>;
  Invoice?: InputMaybe<InvoiceNullableRelationFilter>;
  NOT?: InputMaybe<Array<DepositAddressWhereInput>>;
  OR?: InputMaybe<Array<DepositAddressWhereInput>>;
  address?: InputMaybe<StringFilter>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expireAt?: InputMaybe<DateTimeNullableFilter>;
  id?: InputMaybe<StringFilter>;
  memo?: InputMaybe<StringNullableFilter>;
  merchantId?: InputMaybe<StringFilter>;
  payload?: InputMaybe<JsonFilter>;
  source?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumDepositAddressTypeFilter>;
};

export type DeviceDetails = {
  model?: Maybe<Scalars['String']['output']>;
  type?: Maybe<DeviceType>;
  /** Value examples: 'HP', 'Nokia', 'Apple', 'Lenovo'... */
  vendor?: Maybe<Scalars['String']['output']>;
};

export type DeviceInfo = {
  cpu: CpuDetails;
  device: DeviceDetails;
  os: OsDetails;
};

export enum DeviceType {
  Console = 'console',
  Embedded = 'embedded',
  Mobile = 'mobile',
  Smarttv = 'smarttv',
  Tablet = 'tablet',
  Wearable = 'wearable'
}

export type DonationPaymentDetailsForNonAuthOutput = {
  amount: Scalars['String']['output'];
  fee: Scalars['String']['output'];
  processedAmount: Scalars['String']['output'];
};

export enum EndpointPrefix {
  Api = 'api'
}

export type EnumAssetTypeFilter = {
  equals?: InputMaybe<AssetType>;
  in?: InputMaybe<Array<AssetType>>;
  not?: InputMaybe<NestedEnumAssetTypeFilter>;
  notIn?: InputMaybe<Array<AssetType>>;
};

export type EnumCompanyFeeStrategyNullableFilter = {
  equals?: InputMaybe<CompanyFeeStrategy>;
  in?: InputMaybe<Array<CompanyFeeStrategy>>;
  not?: InputMaybe<NestedEnumCompanyFeeStrategyNullableFilter>;
  notIn?: InputMaybe<Array<CompanyFeeStrategy>>;
};

export type EnumCompanyFeeTypeFilter = {
  equals?: InputMaybe<CompanyFeeType>;
  in?: InputMaybe<Array<CompanyFeeType>>;
  not?: InputMaybe<NestedEnumCompanyFeeTypeFilter>;
  notIn?: InputMaybe<Array<CompanyFeeType>>;
};

export type EnumCompanyKycSourceFilter = {
  equals?: InputMaybe<CompanyKycSource>;
  in?: InputMaybe<Array<CompanyKycSource>>;
  not?: InputMaybe<NestedEnumCompanyKycSourceFilter>;
  notIn?: InputMaybe<Array<CompanyKycSource>>;
};

export type EnumCompanyLimitPeriodFilter = {
  equals?: InputMaybe<CompanyLimitPeriod>;
  in?: InputMaybe<Array<CompanyLimitPeriod>>;
  not?: InputMaybe<NestedEnumCompanyLimitPeriodFilter>;
  notIn?: InputMaybe<Array<CompanyLimitPeriod>>;
};

export type EnumCompanyLimitTypeFilter = {
  equals?: InputMaybe<CompanyLimitType>;
  in?: InputMaybe<Array<CompanyLimitType>>;
  not?: InputMaybe<NestedEnumCompanyLimitTypeFilter>;
  notIn?: InputMaybe<Array<CompanyLimitType>>;
};

export type EnumCompanyTypeFilter = {
  equals?: InputMaybe<CompanyType>;
  in?: InputMaybe<Array<CompanyType>>;
  not?: InputMaybe<NestedEnumCompanyTypeFilter>;
  notIn?: InputMaybe<Array<CompanyType>>;
};

export type EnumConvertStatusFilter = {
  equals?: InputMaybe<ConvertStatus>;
  in?: InputMaybe<Array<ConvertStatus>>;
  not?: InputMaybe<NestedEnumConvertStatusFilter>;
  notIn?: InputMaybe<Array<ConvertStatus>>;
};

export type EnumConvertSupportStatusFilter = {
  equals?: InputMaybe<ConvertSupportStatus>;
  in?: InputMaybe<Array<ConvertSupportStatus>>;
  not?: InputMaybe<NestedEnumConvertSupportStatusFilter>;
  notIn?: InputMaybe<Array<ConvertSupportStatus>>;
};

export type EnumDepositAddressTypeFilter = {
  equals?: InputMaybe<DepositAddressType>;
  in?: InputMaybe<Array<DepositAddressType>>;
  not?: InputMaybe<NestedEnumDepositAddressTypeFilter>;
  notIn?: InputMaybe<Array<DepositAddressType>>;
};

export type EnumIdentificationStatusFilter = {
  equals?: InputMaybe<IdentificationStatus>;
  in?: InputMaybe<Array<IdentificationStatus>>;
  not?: InputMaybe<NestedEnumIdentificationStatusFilter>;
  notIn?: InputMaybe<Array<IdentificationStatus>>;
};

export type EnumInvoiceStatusFilter = {
  equals?: InputMaybe<InvoiceStatus>;
  in?: InputMaybe<Array<InvoiceStatus>>;
  not?: InputMaybe<NestedEnumInvoiceStatusFilter>;
  notIn?: InputMaybe<Array<InvoiceStatus>>;
};

export type EnumPayoutOperationStatusFilter = {
  equals?: InputMaybe<PayoutOperationStatus>;
  in?: InputMaybe<Array<PayoutOperationStatus>>;
  not?: InputMaybe<NestedEnumPayoutOperationStatusFilter>;
  notIn?: InputMaybe<Array<PayoutOperationStatus>>;
};

export type EnumPayoutStatusFilter = {
  equals?: InputMaybe<PayoutStatus>;
  in?: InputMaybe<Array<PayoutStatus>>;
  not?: InputMaybe<NestedEnumPayoutStatusFilter>;
  notIn?: InputMaybe<Array<PayoutStatus>>;
};

export type EnumPayoutSupportStatusFilter = {
  equals?: InputMaybe<PayoutSupportStatus>;
  in?: InputMaybe<Array<PayoutSupportStatus>>;
  not?: InputMaybe<NestedEnumPayoutSupportStatusFilter>;
  notIn?: InputMaybe<Array<PayoutSupportStatus>>;
};

export type EnumReportStatusFilter = {
  equals?: InputMaybe<ReportStatus>;
  in?: InputMaybe<Array<ReportStatus>>;
  not?: InputMaybe<NestedEnumReportStatusFilter>;
  notIn?: InputMaybe<Array<ReportStatus>>;
};

export type EnumReportTypeFilter = {
  equals?: InputMaybe<ReportType>;
  in?: InputMaybe<Array<ReportType>>;
  not?: InputMaybe<NestedEnumReportTypeFilter>;
  notIn?: InputMaybe<Array<ReportType>>;
};

export type EnumRequisiteTypeFilter = {
  equals?: InputMaybe<RequisiteType>;
  in?: InputMaybe<Array<RequisiteType>>;
  not?: InputMaybe<NestedEnumRequisiteTypeFilter>;
  notIn?: InputMaybe<Array<RequisiteType>>;
};

export type EnumStatusOfCompanyFilter = {
  equals?: InputMaybe<StatusOfCompany>;
  in?: InputMaybe<Array<StatusOfCompany>>;
  not?: InputMaybe<NestedEnumStatusOfCompanyFilter>;
  notIn?: InputMaybe<Array<StatusOfCompany>>;
};

export type EnumStatusOfUserFilter = {
  equals?: InputMaybe<StatusOfUser>;
  in?: InputMaybe<Array<StatusOfUser>>;
  not?: InputMaybe<NestedEnumStatusOfUserFilter>;
  notIn?: InputMaybe<Array<StatusOfUser>>;
};

export type EnumTransferStatusFilter = {
  equals?: InputMaybe<TransferStatus>;
  in?: InputMaybe<Array<TransferStatus>>;
  not?: InputMaybe<NestedEnumTransferStatusFilter>;
  notIn?: InputMaybe<Array<TransferStatus>>;
};

export type EnumTransferStatusSupportFilter = {
  equals?: InputMaybe<TransferStatusSupport>;
  in?: InputMaybe<Array<TransferStatusSupport>>;
  not?: InputMaybe<NestedEnumTransferStatusSupportFilter>;
  notIn?: InputMaybe<Array<TransferStatusSupport>>;
};

export type EnumTransferTypeFilter = {
  equals?: InputMaybe<TransferType>;
  in?: InputMaybe<Array<TransferType>>;
  not?: InputMaybe<NestedEnumTransferTypeFilter>;
  notIn?: InputMaybe<Array<TransferType>>;
};

export type EnumZenStatusFilter = {
  equals?: InputMaybe<ZenStatus>;
  in?: InputMaybe<Array<ZenStatus>>;
  not?: InputMaybe<NestedEnumZenStatusFilter>;
  notIn?: InputMaybe<Array<ZenStatus>>;
};

export type Equivalent = {
  amount: Scalars['String']['output'];
  currency: Scalars['String']['output'];
};

export type Exception = {
  code: Scalars['String']['output'];
  description?: Maybe<Scalars['String']['output']>;
};

export type ExecuteInvoiceOutput = {
  /** Address for deposit */
  address: Scalars['String']['output'];
  /** Invoice ID */
  id: Scalars['String']['output'];
  /** Memo/destination tag */
  memo?: Maybe<Scalars['String']['output']>;
};

export type Feature = {
  name: Scalars['String']['output'];
  value: Scalars['Boolean']['output'];
};

export type FeedbackMeta = {
  companyId?: InputMaybe<Scalars['String']['input']>;
  userEmail?: InputMaybe<Scalars['String']['input']>;
};

export type FilterForStringInJson = {
  equals?: InputMaybe<Scalars['JSON']['input']>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type FindCompanyPayoutDetailsOutput = {
  Initiators: PayoutInitiatorsOutput;
  PayoutPaymentSummary: Array<PublicPayoutPaymentSummaryOutput>;
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isCreatedByApi: Scalars['Boolean']['output'];
  launchedAt?: Maybe<Scalars['DateTime']['output']>;
  status: PayoutStatus;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FindCompanyRelationWhereInput = {
  isActive?: InputMaybe<BoolFilter>;
  role?: InputMaybe<StringFilter>;
};

export type FindCountNotReadNotificationByGroupOutput = {
  count: Scalars['Int']['output'];
  group: Scalars['String']['output'];
};

export type FindCountNotReadNotificationOutput = {
  count: Scalars['Int']['output'];
};

export type FindDataForDashboardByTransfersOutput = {
  Asset: PublicAssetOutput;
  data: Array<DataForDashboardOutput>;
};

export type FindDetailsForDonationPageNonAuthOutput = {
  Company: NonAuthCompanyDetailsOutput;
  EquivalentAsset: PublicAssetOutput;
  PaymentMethods: Array<AssetPaymentMethod>;
};

export type FindDonationForNonAuthOutput = {
  EquivalentAsset: PublicAssetOutput;
  PaymentDetails: DonationPaymentDetailsForNonAuthOutput;
  PaymentMethod: AssetPaymentMethod;
  address: Scalars['String']['output'];
  id: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
};

export type FindInvitedCoworkerOutput = {
  Company?: Maybe<Company>;
  InvitedCoworkerSecretData?: Maybe<InvitedCoworkerSecretData>;
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  emailHash?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Scalars['String']['output']>;
};

export type FindManyAvailablePaymentMethodOrderByInput = {
  asset?: InputMaybe<SortOrder>;
  code?: InputMaybe<SortOrder>;
  position?: InputMaybe<SortOrder>;
};

export type FindManyAvailablePaymentMethodWhereInput = {
  asset?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  id?: InputMaybe<Scalars['String']['input']>;
  network?: InputMaybe<StringNullableFilter>;
  transferType: TransferType;
};

export type FindManyCompanyLimitWhere = {
  companyType?: InputMaybe<EnumCompanyTypeFilter>;
  period?: InputMaybe<EnumCompanyLimitPeriodFilter>;
  type?: InputMaybe<EnumCompanyLimitTypeFilter>;
};

export type FindManyCompanyPayoutOutput = {
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  createdAt: Scalars['DateTime']['output'];
  description?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isCreatedByApi: Scalars['Boolean']['output'];
  launchedAt?: Maybe<Scalars['DateTime']['output']>;
  status: PayoutStatus;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type FindManyCompanyPayoutWhereInput = {
  AND?: InputMaybe<Array<FindManyCompanyPayoutWhereInput>>;
  NOT?: InputMaybe<Array<FindManyCompanyPayoutWhereInput>>;
  OR?: InputMaybe<Array<FindManyCompanyPayoutWhereInput>>;
  description?: InputMaybe<StringNullableFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumPayoutStatusFilter>;
  supportStatus?: InputMaybe<EnumPayoutSupportStatusFilter>;
  title?: InputMaybe<StringNullableFilter>;
};

export type FindManyCompanyReferralOutput = {
  Referral: ReferralCompanyDetailsForAffiliateOutput;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  referralCompanyId: Scalars['String']['output'];
  updatedAt: Scalars['DateTime']['output'];
};

export type FindManyCompanyTransferWhereInput = {
  AND?: InputMaybe<Array<FindManyCompanyTransferWhereInput>>;
  Convert?: InputMaybe<ConvertPublicWhereInput>;
  NOT?: InputMaybe<Array<FindManyCompanyTransferWhereInput>>;
  OR?: InputMaybe<Array<FindManyCompanyTransferWhereInput>>;
  PayoutOperation?: InputMaybe<PayoutOperationPublicWhereInput>;
  address?: InputMaybe<StringNullableFilter>;
  asset?: InputMaybe<StringFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  paymentCode?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumTransferStatusFilter>;
  /** Transaction id in Blockchain */
  txId?: InputMaybe<FilterForStringInJson>;
  type?: InputMaybe<EnumTransferTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FindManyInvoiceOrderByInput = {
  completedAt?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  invoiceAssetCode?: InputMaybe<SortOrder>;
  paymentAssetCode?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FindManyInvoiceOutput = {
  Address?: Maybe<DepositAddress>;
  AutoConvertSettings?: Maybe<InvoiceAutoConvertSettingsOutput>;
  CompletedByUser?: Maybe<InvoiceCompletedByUserOutput>;
  Creator: InvoiceCreatorOutput;
  InvoiceAsset: PublicAssetOutput;
  PaymentAsset?: Maybe<PublicAssetOutput>;
  PaymentMethod?: Maybe<PaymentMethod>;
  /** The product used to calculate the invoice payment amount */
  Product?: Maybe<PublicProductOutput>;
  Transfers?: Maybe<Array<PublicTransferWithAmlDetailsOutput>>;
  addressId?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedByUserId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  expireAt?: Maybe<Scalars['DateTime']['output']>;
  externalOrderId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoiceAmount: Scalars['Decimal']['output'];
  invoiceAssetCode: Scalars['String']['output'];
  isCreatedByApi: Scalars['Boolean']['output'];
  isPaymentAfterTimeout: Scalars['Boolean']['output'];
  logicalTime: Scalars['BigInt']['output'];
  paidAmount: Scalars['Decimal']['output'];
  paidFee: Scalars['Decimal']['output'];
  paymentAmount?: Maybe<Scalars['Decimal']['output']>;
  paymentAssetCode?: Maybe<Scalars['String']['output']>;
  paymentFee?: Maybe<Scalars['Decimal']['output']>;
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  productCategory?: Maybe<Scalars['String']['output']>;
  productDescription?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['Decimal']['output']>;
  rateFreezeAt?: Maybe<Scalars['DateTime']['output']>;
  status: InvoiceStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type FindManyInvoiceWhereInput = {
  AND?: InputMaybe<Array<FindManyInvoiceWhereInput>>;
  NOT?: InputMaybe<Array<FindManyInvoiceWhereInput>>;
  OR?: InputMaybe<Array<FindManyInvoiceWhereInput>>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expireAt?: InputMaybe<DateTimeNullableFilter>;
  externalOrderId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invoiceAssetCode?: InputMaybe<StringFilter>;
  paymentAssetCode?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumInvoiceStatusFilter>;
};

export type FindManyPayoutOperationByPayoutOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  status?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type FindManyPayoutOperationByPayoutWhereInput = {
  payoutId: Scalars['String']['input'];
  status?: InputMaybe<EnumPayoutOperationStatusFilter>;
};

export type FindManyPayoutOperationOutput = {
  PaymentDetails: PayoutOperationPaymentDetailsOutput;
  PaymentMethod: PublicPaymentMethodWithAssetOutput;
  Transfer?: Maybe<PublicTransferOutput>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  originalAmount: Scalars['Decimal']['output'];
  publicReason: Array<Scalars['String']['output']>;
  status: PayoutOperationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type FindManyPublicAssetWhereInput = {
  AND?: InputMaybe<Array<FindManyPublicAssetWhereInput>>;
  NOT?: InputMaybe<Array<FindManyPublicAssetWhereInput>>;
  OR?: InputMaybe<Array<FindManyPublicAssetWhereInput>>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  description?: InputMaybe<StringFilter>;
  isAutoConvertCurrency?: InputMaybe<BoolFilter>;
  isInvoiceCurrency?: InputMaybe<BoolFilter>;
  isPaymentCurrency?: InputMaybe<BoolFilter>;
  isReferenceCurrency?: InputMaybe<BoolFilter>;
  isReferralPayoutCurrency?: InputMaybe<BoolFilter>;
  minInvoiceAmount?: InputMaybe<DecimalFilter>;
  name?: InputMaybe<StringFilter>;
  position?: InputMaybe<IntFilter>;
  precision?: InputMaybe<IntFilter>;
  rateFreezeSeconds?: InputMaybe<IntFilter>;
  type?: InputMaybe<EnumAssetTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FindManyReferralOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
};

export type FindManyReferralWhereInput = {
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  referralCompanyId?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type FindManyReportOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  expireAt?: InputMaybe<SortOrder>;
  id?: InputMaybe<SortOrder>;
  updatedAt?: InputMaybe<SortOrderInput>;
};

export type FindManyReportWhereInput = {
  AND?: InputMaybe<Array<FindManyReportWhereInput>>;
  NOT?: InputMaybe<Array<FindManyReportWhereInput>>;
  OR?: InputMaybe<Array<FindManyReportWhereInput>>;
  createdAt?: InputMaybe<DateTimeFilter>;
  expireAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumReportStatusFilter>;
  type?: InputMaybe<EnumReportTypeFilter>;
  updatedAt?: InputMaybe<DateTimeNullableFilter>;
};

export type FindNotificationsWhereInput = {
  createdAt?: InputMaybe<DateTimeFilter>;
  entityId?: InputMaybe<StringNullableFilter>;
  group?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isRead?: InputMaybe<BoolFilter>;
};

export type FindOtpSettingsOutput = {
  Details?: Maybe<OtpSettingsDetailsOutput>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  type: OtpType;
};

export type FindRelatedCompaniesOutput = {
  avatar?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  identificationStatus: IdentificationStatus;
  role: Scalars['String']['output'];
  title: Scalars['String']['output'];
  type: CompanyType;
};

export type FindSupportedPhoneCountryCodesOutput = {
  code: Scalars['String']['output'];
};

export type FloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type GenerateKeysOutput = {
  /** Generated api key used for api requests */
  apiKey: Scalars['String']['output'];
  /** Generated privateKey used for sign api request */
  privateKey: Scalars['String']['output'];
  /** Generated publicKey used for verify api request */
  publicKey: Scalars['String']['output'];
};

export type GenerateOtpUriOutput = {
  /** Needed only for type sharing to FE */
  otpExceptionType?: Maybe<OtpExceptionType>;
  /** Secret for OTP */
  secret: Scalars['String']['output'];
  /** Uri for generate OTP QR code */
  uri: Scalars['String']['output'];
};

export type GetAccountLinkStatus = {
  businessAccountConfirmationUrl?: Maybe<Scalars['String']['output']>;
  reason?: Maybe<Scalars['String']['output']>;
  status?: Maybe<ZenStatus>;
};

export type GroupedByAssetAutoConvertPaymentMethodPublicOutput = {
  PaymentMethods: Array<AutoConvertPaymentMethodPublicOutput>;
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  isAutoConvertCurrency: Scalars['Boolean']['output'];
  isInvoiceCurrency: Scalars['Boolean']['output'];
  isPaymentCurrency: Scalars['Boolean']['output'];
  isReferenceCurrency: Scalars['Boolean']['output'];
  isReferralPayoutCurrency: Scalars['Boolean']['output'];
  minInvoiceAmount: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  payload: Scalars['JSON']['output'];
  position: Scalars['Int']['output'];
  precision: Scalars['Int']['output'];
  rateFreezeSeconds: Scalars['Int']['output'];
  type: AssetType;
};

export type GroupedByAssetPaymentMethodWithProductPublicOutput = {
  PaymentMethods: Array<PublicPaymentMethodOutput>;
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  isAutoConvertCurrency: Scalars['Boolean']['output'];
  isInvoiceCurrency: Scalars['Boolean']['output'];
  isPaymentCurrency: Scalars['Boolean']['output'];
  isReferenceCurrency: Scalars['Boolean']['output'];
  isReferralPayoutCurrency: Scalars['Boolean']['output'];
  minInvoiceAmount: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  payload: Scalars['JSON']['output'];
  position: Scalars['Int']['output'];
  precision: Scalars['Int']['output'];
  rateFreezeSeconds: Scalars['Int']['output'];
  type: AssetType;
};

export enum IdentificationStatus {
  Accepted = 'ACCEPTED',
  Created = 'CREATED',
  NotVerified = 'NOT_VERIFIED',
  Pending = 'PENDING',
  Rejected = 'REJECTED'
}

export type InitiatorApiKeyOutput = {
  /** API key ID */
  id: Scalars['String']['output'];
  /** API key name */
  name: Scalars['String']['output'];
};

export type InitiatorProfileDetailsOutput = {
  affiliateId?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  /** Contain FirstName + LastName or username */
  computedFullName: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  /** @deprecated Will be removed */
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  identificationStatus: IdentificationStatus;
  isAgreePrivacyPolicy: Scalars['Boolean']['output'];
  isKUNAEmployee: Scalars['Boolean']['output'];
  isSuspicious: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  logicalTime: Scalars['BigInt']['output'];
  merchantId: Scalars['String']['output'];
  referenceId?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  sn: Scalars['Int']['output'];
  status: StatusOfUser;
  username: Scalars['String']['output'];
  utm: Scalars['String']['output'];
};

export type IntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type InvitedCoworkerListRelationFilter = {
  every?: InputMaybe<InvitedCoworkerWhereInput>;
  none?: InputMaybe<InvitedCoworkerWhereInput>;
  some?: InputMaybe<InvitedCoworkerWhereInput>;
};

export type InvitedCoworkerSecretData = {
  email?: Maybe<Scalars['String']['output']>;
};

export type InvitedCoworkerWhereInput = {
  AND?: InputMaybe<Array<InvitedCoworkerWhereInput>>;
  Company?: InputMaybe<CompanyRelationFilter>;
  NOT?: InputMaybe<Array<InvitedCoworkerWhereInput>>;
  OR?: InputMaybe<Array<InvitedCoworkerWhereInput>>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  emailHash?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  role?: InputMaybe<StringFilter>;
};

export type InvoiceAutoConvertSettingsOutput = {
  currency: Scalars['String']['output'];
  volatilityCoverageEnabled: Scalars['Boolean']['output'];
};

export type InvoiceCalculationDetailsOutput = {
  coefficientApplied: Scalars['Boolean']['output'];
  difference: Scalars['Decimal']['output'];
  raw: InvoiceCalculationRawDetailsOutput;
  withCoefficient: InvoiceCalculationWithCoefficientDetailsOutput;
};

export type InvoiceCalculationRawDetailsOutput = {
  amount: Scalars['Decimal']['output'];
  rate: Scalars['Decimal']['output'];
};

export type InvoiceCalculationWithCoefficientDetailsOutput = {
  amount: Scalars['Decimal']['output'];
  coefficient: Scalars['Decimal']['output'];
  rate: Scalars['Decimal']['output'];
};

export type InvoiceCompletedByUserOutput = {
  id: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type InvoiceCreatorOutput = {
  /** @deprecated Will be removed */
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  /** Username or API key name */
  name?: Maybe<Scalars['String']['output']>;
  type: InvoiceCreatorType;
};

export enum InvoiceCreatorType {
  ApiKey = 'API_KEY',
  User = 'USER'
}

export type InvoiceCustomizationOutput = {
  redirect?: Maybe<InvoiceRedirectCustomizationOutput>;
};

export type InvoiceDetailsOutput = {
  Address?: Maybe<DepositAddress>;
  AutoConvertSettings?: Maybe<InvoiceAutoConvertSettingsOutput>;
  CalculationDetails?: Maybe<InvoiceCalculationDetailsOutput>;
  CompletedByUser?: Maybe<InvoiceCompletedByUserOutput>;
  Creator: InvoiceCreatorOutput;
  Customization?: Maybe<InvoiceCustomizationOutput>;
  InvoiceAsset: PublicAssetOutput;
  PaymentAsset?: Maybe<PublicAssetOutput>;
  PaymentDetails: InvoicePaymentDetailsOutput;
  PaymentMethod?: Maybe<PaymentMethod>;
  /** The product used to calculate the invoice payment amount */
  Product?: Maybe<PublicProductOutput>;
  Transfers?: Maybe<Array<PublicTransferWithAmlDetailsOutput>>;
  addressId?: Maybe<Scalars['String']['output']>;
  companyId: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedByUserId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  expireAt?: Maybe<Scalars['DateTime']['output']>;
  externalOrderId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoiceAmount: Scalars['Decimal']['output'];
  invoiceAssetCode: Scalars['String']['output'];
  isCreatedByApi: Scalars['Boolean']['output'];
  isPaymentAfterTimeout: Scalars['Boolean']['output'];
  logicalTime: Scalars['BigInt']['output'];
  paidAmount: Scalars['Decimal']['output'];
  paidFee: Scalars['Decimal']['output'];
  paymentAmount?: Maybe<Scalars['Decimal']['output']>;
  paymentAssetCode?: Maybe<Scalars['String']['output']>;
  paymentFee?: Maybe<Scalars['Decimal']['output']>;
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  productCategory?: Maybe<Scalars['String']['output']>;
  productDescription?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['Decimal']['output']>;
  rateFreezeAt?: Maybe<Scalars['DateTime']['output']>;
  status: InvoiceStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type InvoiceListRelationFilter = {
  every?: InputMaybe<InvoiceWhereInput>;
  none?: InputMaybe<InvoiceWhereInput>;
  some?: InputMaybe<InvoiceWhereInput>;
};

export type InvoiceNullableRelationFilter = {
  is?: InputMaybe<InvoiceWhereInput>;
  isNot?: InputMaybe<InvoiceWhereInput>;
};

export type InvoicePaymentDetailsOutput = {
  leftAmount: Scalars['Decimal']['output'];
  leftAmountInInvoiceAsset: Scalars['Decimal']['output'];
  paidAmount: Scalars['Decimal']['output'];
  paidAmountInInvoiceAsset: Scalars['Decimal']['output'];
  paidFee: Scalars['Decimal']['output'];
};

export type InvoicePaymentLinkOutput = {
  paymentLink: Scalars['String']['output'];
};

export type InvoiceRedirectCustomizationOutput = {
  buttonTitle?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

export enum InvoiceStatus {
  Arrested = 'ARRESTED',
  ConfirmationAwaiting = 'CONFIRMATION_AWAITING',
  Created = 'CREATED',
  CurrencyMismatch = 'CURRENCY_MISMATCH',
  Deactivated = 'DEACTIVATED',
  Declined = 'DECLINED',
  LimitsOutOfRange = 'LIMITS_OUT_OF_RANGE',
  Paid = 'PAID',
  PartiallyPaid = 'PARTIALLY_PAID',
  PaymentAwaiting = 'PAYMENT_AWAITING',
  Suspended = 'SUSPENDED',
  Timeout = 'TIMEOUT'
}

export type InvoiceWhereInput = {
  AND?: InputMaybe<Array<InvoiceWhereInput>>;
  Address?: InputMaybe<DepositAddressNullableRelationFilter>;
  Company?: InputMaybe<CompanyRelationFilter>;
  CompletedByUser?: InputMaybe<ProfileNullableRelationFilter>;
  InvoiceAsset?: InputMaybe<AssetRelationFilter>;
  NOT?: InputMaybe<Array<InvoiceWhereInput>>;
  OR?: InputMaybe<Array<InvoiceWhereInput>>;
  PaymentAsset?: InputMaybe<AssetNullableRelationFilter>;
  PaymentMethod?: InputMaybe<PaymentMethodNullableRelationFilter>;
  Transfers?: InputMaybe<TransferListRelationFilter>;
  addressId?: InputMaybe<StringNullableFilter>;
  companyId?: InputMaybe<StringFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  completedByUserId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorId?: InputMaybe<StringFilter>;
  expireAt?: InputMaybe<DateTimeNullableFilter>;
  externalOrderId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invoiceAmount?: InputMaybe<DecimalFilter>;
  invoiceAssetCode?: InputMaybe<StringFilter>;
  isCreatedByApi?: InputMaybe<BoolFilter>;
  isPaymentAfterTimeout?: InputMaybe<BoolFilter>;
  logicalTime?: InputMaybe<BigIntFilter>;
  merchantId?: InputMaybe<StringFilter>;
  paidAmount?: InputMaybe<DecimalFilter>;
  paidFee?: InputMaybe<DecimalFilter>;
  payload?: InputMaybe<JsonFilter>;
  paymentAmount?: InputMaybe<DecimalNullableFilter>;
  paymentAssetCode?: InputMaybe<StringNullableFilter>;
  paymentFee?: InputMaybe<DecimalNullableFilter>;
  paymentMethodId?: InputMaybe<StringNullableFilter>;
  productCategory?: InputMaybe<StringNullableFilter>;
  productDescription?: InputMaybe<StringNullableFilter>;
  rate?: InputMaybe<DecimalNullableFilter>;
  rateFreezeAt?: InputMaybe<DateTimeNullableFilter>;
  status?: InputMaybe<EnumInvoiceStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type JsonFilter = {
  array_contains?: InputMaybe<Scalars['JSON']['input']>;
  array_ends_with?: InputMaybe<Scalars['JSON']['input']>;
  array_starts_with?: InputMaybe<Scalars['JSON']['input']>;
  equals?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<Scalars['JSON']['input']>;
  path?: InputMaybe<Array<Scalars['String']['input']>>;
  string_contains?: InputMaybe<Scalars['String']['input']>;
  string_ends_with?: InputMaybe<Scalars['String']['input']>;
  string_starts_with?: InputMaybe<Scalars['String']['input']>;
};

export type Language = {
  code: Scalars['String']['output'];
  label: Scalars['String']['output'];
};

export type LaunchDonationProcessForNonAuthOutput = {
  address: Scalars['String']['output'];
  id: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
};

export type LaunchPayoutOutput = {
  id?: Maybe<Scalars['String']['output']>;
  issues?: Maybe<Array<PayoutIssueOutput>>;
  success: Scalars['Boolean']['output'];
};

export type MaintenanceNotificationOutput = {
  createdAt: Scalars['DateTime']['output'];
  fromTime: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  message: Scalars['String']['output'];
  payload?: Maybe<Scalars['JSON']['output']>;
  tillTime?: Maybe<Scalars['DateTime']['output']>;
  type: MaintenanceNotificationType;
};

export enum MaintenanceNotificationType {
  Actual = 'Actual',
  Prevent = 'Prevent'
}

export type Mutation = {
  /** Add a new API key to the company */
  addApiKey: SuccessOutput;
  /** Add asset settings for the company */
  bulkUpsertCompanyAssetSettings: SuccessOutput;
  calculateCompanyLimitForWithdrawal: CalculatedCompanyLimitOutput;
  changeInvoiceFeeSettingsForCompany: SuccessOutput;
  completeInvoiceManually: SuccessOutput;
  /** Confirm user email */
  confirmEmail: SuccessOutput;
  createAndLaunchPayout: LaunchPayoutOutput;
  /** Create constant deposit address for source(blockchain). Allowed 1 time for 1 source */
  createConstDepositAddress: DepositAddressPublicOutput;
  createInvoice: CreateInvoiceOutput;
  /** Create new company */
  createOneCompany: CompanyIdOutput;
  /** Create payment requisite */
  createOneRequisite: Requisite;
  createOneWithdraw: SuccessOutput;
  /** Create payout to many contractors */
  createPayout: CreatePayoutOutput;
  createWithdrawAll: SuccessOutput;
  deactivateInvoice: SuccessOutput;
  /** Delete all company api keys */
  deleteAllCompanyApiKeys: SuccessOutput;
  /** Delete single company api key */
  deleteCompanyApiKey: SuccessOutput;
  deleteCoworkerInvitation: SuccessOutput;
  /** Delete payment requisite */
  deleteManyRequisite: SuccessOutput;
  /** Delete payment requisite */
  deleteOneRequisite: SuccessOutput;
  /** Delete payout operation before payout launch */
  deletePayoutOperation: SuccessOutput;
  /** Disable active company api key */
  disableApiKey: SuccessOutput;
  disableCompanyRelation: SuccessOutput;
  /** Disable active otp */
  disableOtp: SuccessOutput;
  disableSession: SuccessOutput;
  emitTestDeposit: SuccessOutput;
  /** Add new otp */
  enableOtp: SuccessOutput;
  executePublicInvoice: ExecuteInvoiceOutput;
  freshDeskAuth: AuthenticateFreshDeskOutput;
  generateInvoiceReport: CreatedReportOutput;
  generatePayoutReport: CreatedReportOutput;
  generateReportOnMyOwnInvoices: CreatedTransactionReportOutput;
  generateReportOnMyOwnTransactions: CreatedTransactionReportOutput;
  generateReportOnMyOwnTransactionsByWorker: SuccessOutput;
  generateStatementReport: SuccessOutput;
  generateTransactionReport: CreatedTransactionReportOutput;
  generateTransactionReportByWorker: SuccessOutput;
  /** Register throughout google id */
  googleRegister: AuthTokensOutput;
  /** Invalidates specified session for the user */
  invalidateSessionByKey: SuccessOutput;
  /** Invalidates all user's active sessions. Allows to exclude the current */
  invalidateSessions: SuccessOutput;
  /** Invite new coworker to company */
  inviteCoworkerToCompany: SuccessOutput;
  kunaProMigration: SuccessOutput;
  /** Register throughout kuna id */
  kunaRegister: AuthTokensOutput;
  launchDonationProcessForNonAuth: LaunchDonationProcessForNonAuthOutput;
  launchManualConvert: SuccessOutput;
  /** Launch created Payout */
  launchPayout: LaunchPayoutOutput;
  preRequestDisableOtp: SuccessOutput;
  preRequestEnableOtp: PreRequestEnableOtpOutput;
  preRequestManualConvert: PreRequestManualConvertOutput;
  /** Checks whether it is possible to perform a payout */
  preRequestPayout: PreRequestPayoutOutput;
  preRequestPublicInvoice: PreRequestInvoiceOutput;
  publicCollectGenericFeedback: SuccessOutput;
  publicGetPresignUrlsForFeedbackAttachments: PresignFeedbackAttachmentUrlsOutput;
  publicRateUX: SuccessOutput;
  register: AuthTokensOutput;
  /** Send email with reset password token, use it if forgot or wanna change password */
  requestResetPassword: SuccessOutput;
  /** Send confirmation email again */
  resendConfirmationEmail: SuccessOutput;
  reset: Array<RemoteConfig>;
  /** Reset password(use reset token from email) */
  resetPassword: SuccessOutput;
  /** Retry failed payout operation */
  retryProcessPayoutOperation: SuccessOutput;
  saveAnswersToQuestionnaireForNonAuth: SuccessOutput;
  sendInvoiceReceiptToBuyer: SuccessOutput;
  /** Set new company apiKey ip white list */
  setApiKeyIPWhiteList: SuccessOutput;
  /** Enable/Disable email confirmation for entity(for example withdraw) */
  setConfirmationSetting: SuccessOutput;
  /**
   * Toggle email notifications on a per-company basis, rather than for individual users (for example withdraw/deposit)
   * @deprecated Will be removed
   */
  setNotificationByCompanySettings: SuccessOutput;
  setRabacSchema: RabacSchema;
  setRemoteConfig: RemoteConfig;
  /**
   * Toggling email notification for user and not tied to any specific company (for example login)
   * @deprecated Will be removed
   */
  setUserNotificationSettings: SuccessOutput;
  startConvertManuallyForInvoice: SuccessOutput;
  startSumsubIdentification: StartSumSubIdentificationOutput;
  /** Toggle auto convert for company */
  toggleAutoConvertForCompany: SuccessOutput;
  transferCompanyOwnership: SuccessOutput;
  /** Update current company(CompanyId takes from Jwt token) */
  updateCompany: SuccessOutput;
  updateCompanyFeeSettingsInReferral: SuccessOutput;
  updateCompanyNotificationSettings: SuccessOutput;
  updateCustomQuestionnaireField: SuccessOutput;
  updateOneCompanyRelation: SuccessOutput;
  updateOneMyOwnWithdraw: SuccessOutput;
  /** Update payment requisite */
  updateOneRequisite: Requisite;
  updateOneWithdraw: SuccessOutput;
  /** Update payout */
  updatePayout: SuccessOutput;
  /** Update payout operation before payout launch */
  updatePayoutOperationDetails: SuccessOutput;
  updateProfile: UserProfileOutput;
  updateUserNotificationSettings: SuccessOutput;
  zenCreateAccountLink: SuccessOutput;
  zenCreateAccountRegistrationRequest: SuccessOutput;
  zenCreateBusinessAccountLink: CreateBusinessAccountLink;
  zenResendSms: SuccessOutput;
  zenUnlinkAccount: SuccessOutput;
};


export type MutationAddApiKeyArgs = {
  IPwhiteList?: InputMaybe<Array<Scalars['String']['input']>>;
  allowedOperations?: InputMaybe<Array<CompanyAllowedOperationEnum>>;
  apiKey: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  privateKey: Scalars['String']['input'];
  publicKey: Scalars['String']['input'];
};


export type MutationBulkUpsertCompanyAssetSettingsArgs = {
  data: Array<UpsertOneCompanyAssetSettingsInput>;
};


export type MutationChangeInvoiceFeeSettingsForCompanyArgs = {
  preferredStrategy?: InputMaybe<CompanyFeeStrategy>;
};


export type MutationCompleteInvoiceManuallyArgs = {
  id: Scalars['String']['input'];
  launchConvert?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationConfirmEmailArgs = {
  confirmToken: Scalars['String']['input'];
};


export type MutationCreateAndLaunchPayoutArgs = {
  CallbackSettings?: InputMaybe<PayoutCallbackSettingsInput>;
  contractors: Array<CreatePayoutContractorInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateConstDepositAddressArgs = {
  id: Scalars['String']['input'];
  source: Scalars['String']['input'];
};


export type MutationCreateInvoiceArgs = {
  amount: Scalars['String']['input'];
  asset: Scalars['String']['input'];
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  customization?: InputMaybe<CreateInvoiceCustomizationInput>;
  externalOrderId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
  isCreatedByApi?: InputMaybe<Scalars['Boolean']['input']>;
  productCategory?: InputMaybe<Scalars['String']['input']>;
  productDescription?: InputMaybe<Scalars['String']['input']>;
  questionnaire?: InputMaybe<CreateQuestionnaireInput>;
  shouldSendTransactionalCallback?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationCreateOneCompanyArgs = {
  data: CreateOneCompanyInput;
};


export type MutationCreateOneRequisiteArgs = {
  currency: Scalars['String']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  source: Scalars['String']['input'];
  type: RequisiteType;
  values: Scalars['JSON']['input'];
};


export type MutationCreateOneWithdrawArgs = {
  amount: Scalars['String']['input'];
  asset: Scalars['String']['input'];
  callbackId?: InputMaybe<Scalars['String']['input']>;
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['String']['input'];
  paymentMethod: Scalars['String']['input'];
  requisiteId?: InputMaybe<Scalars['String']['input']>;
  saveRequisiteName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreatePayoutArgs = {
  CallbackSettings?: InputMaybe<PayoutCallbackSettingsInput>;
  contractors: Array<CreatePayoutContractorInput>;
  externalId?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateWithdrawAllArgs = {
  amount: Scalars['String']['input'];
  asset: Scalars['String']['input'];
  callbackId?: InputMaybe<Scalars['String']['input']>;
  callbackUrl?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Scalars['JSON']['input']>;
  id: Scalars['String']['input'];
  paymentMethod: Scalars['String']['input'];
  requisiteId?: InputMaybe<Scalars['String']['input']>;
  saveRequisiteName?: InputMaybe<Scalars['String']['input']>;
};


export type MutationDeactivateInvoiceArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteCompanyApiKeyArgs = {
  apiKeyId: Scalars['String']['input'];
};


export type MutationDeleteCoworkerInvitationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeleteManyRequisiteArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type MutationDeleteOneRequisiteArgs = {
  id: Scalars['String']['input'];
};


export type MutationDeletePayoutOperationArgs = {
  id: Scalars['String']['input'];
};


export type MutationDisableApiKeyArgs = {
  id: Scalars['String']['input'];
};


export type MutationDisableCompanyRelationArgs = {
  where: CompanyUserUniqueInput;
};


export type MutationDisableOtpArgs = {
  otp: Scalars['String']['input'];
};


export type MutationDisableSessionArgs = {
  fingerprint: Scalars['String']['input'];
};


export type MutationEmitTestDepositArgs = {
  amount: Scalars['String']['input'];
  asset: Scalars['String']['input'];
};


export type MutationEnableOtpArgs = {
  otp: Scalars['String']['input'];
};


export type MutationExecutePublicInvoiceArgs = {
  id: Scalars['String']['input'];
};


export type MutationFreshDeskAuthArgs = {
  email: Scalars['String']['input'];
  name?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGenerateInvoiceReportArgs = {
  settings?: InputMaybe<CreateReportSettings>;
  where: CreateInvoiceReportWhereInput;
};


export type MutationGeneratePayoutReportArgs = {
  payoutId: Scalars['String']['input'];
  settings?: InputMaybe<CreateReportSettings>;
};


export type MutationGenerateReportOnMyOwnInvoicesArgs = {
  settings?: InputMaybe<CreateReportSettings>;
  where: CreateInvoiceReportWhereInput;
};


export type MutationGenerateReportOnMyOwnTransactionsArgs = {
  settings?: InputMaybe<CreateReportSettings>;
  where: CreateReportWhereInput;
};


export type MutationGenerateReportOnMyOwnTransactionsByWorkerArgs = {
  settings?: InputMaybe<CreateReportSettings>;
  where: CreateReportWhereInput;
};


export type MutationGenerateStatementReportArgs = {
  currency?: InputMaybe<Scalars['String']['input']>;
  dateRange: DateRangeInput;
  format: ReportFileFormat;
  timeZone?: InputMaybe<Scalars['String']['input']>;
};


export type MutationGenerateTransactionReportArgs = {
  settings?: InputMaybe<CreateReportSettings>;
  where: CreateReportWhereInput;
};


export type MutationGenerateTransactionReportByWorkerArgs = {
  settings?: InputMaybe<CreateReportSettings>;
  where: CreateReportWhereInput;
};


export type MutationGoogleRegisterArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  isAgreePrivacyPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  isSubscribe?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  referrerId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  utm?: InputMaybe<Scalars['String']['input']>;
};


export type MutationInvalidateSessionByKeyArgs = {
  key: Scalars['String']['input'];
};


export type MutationInvalidateSessionsArgs = {
  invalidateCurrent?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationInviteCoworkerToCompanyArgs = {
  email: Scalars['String']['input'];
  role: Scalars['String']['input'];
};


export type MutationKunaRegisterArgs = {
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  isAgreePrivacyPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  isSubscribe?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  referrerId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  utm?: InputMaybe<Scalars['String']['input']>;
};


export type MutationLaunchDonationProcessForNonAuthArgs = {
  companyId: Scalars['String']['input'];
  paymentMethodId: Scalars['String']['input'];
};


export type MutationLaunchManualConvertArgs = {
  id: Scalars['String']['input'];
};


export type MutationLaunchPayoutArgs = {
  id: Scalars['String']['input'];
};


export type MutationPreRequestEnableOtpArgs = {
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  type: OtpType;
};


export type MutationPreRequestManualConvertArgs = {
  amount: Scalars['String']['input'];
  fromCurrency: Scalars['String']['input'];
  toCurrency: Scalars['String']['input'];
};


export type MutationPreRequestPayoutArgs = {
  contractors?: InputMaybe<Array<CreatePayoutContractorInput>>;
  payoutId?: InputMaybe<Scalars['String']['input']>;
};


export type MutationPreRequestPublicInvoiceArgs = {
  id: Scalars['String']['input'];
  paymentAsset: Scalars['String']['input'];
  paymentMethodCode: Scalars['String']['input'];
};


export type MutationPublicCollectGenericFeedbackArgs = {
  attachments?: InputMaybe<Array<Scalars['String']['input']>>;
  feedback: Scalars['String']['input'];
  meta?: InputMaybe<FeedbackMeta>;
};


export type MutationPublicGetPresignUrlsForFeedbackAttachmentsArgs = {
  feedbackId: Scalars['String']['input'];
  previews: Array<PresignFeedbackAttachmentUrlInput>;
};


export type MutationPublicRateUxArgs = {
  feature: Scalars['String']['input'];
  feedback?: InputMaybe<Scalars['String']['input']>;
  meta?: InputMaybe<FeedbackMeta>;
  rating: Scalars['String']['input'];
};


export type MutationRegisterArgs = {
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  firstName: Scalars['String']['input'];
  isAgreePrivacyPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  isSubscribe?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  referrerId?: InputMaybe<Scalars['String']['input']>;
  token: Scalars['String']['input'];
  utm?: InputMaybe<Scalars['String']['input']>;
};


export type MutationRequestResetPasswordArgs = {
  token: Scalars['String']['input'];
};


export type MutationResetPasswordArgs = {
  newPassword: Scalars['String']['input'];
  resetToken: Scalars['String']['input'];
};


export type MutationRetryProcessPayoutOperationArgs = {
  payoutOperationId: Scalars['String']['input'];
};


export type MutationSaveAnswersToQuestionnaireForNonAuthArgs = {
  answers: Scalars['JSON']['input'];
  id: Scalars['String']['input'];
};


export type MutationSendInvoiceReceiptToBuyerArgs = {
  email: Scalars['String']['input'];
  id: Scalars['String']['input'];
  language?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSetApiKeyIpWhiteListArgs = {
  IPwhiteList?: InputMaybe<Array<Scalars['String']['input']>>;
  id: Scalars['String']['input'];
};


export type MutationSetConfirmationSettingArgs = {
  data: Array<ConfirmationInput>;
};


export type MutationSetNotificationByCompanySettingsArgs = {
  data: Array<NotificationByCompanyInput>;
};


export type MutationSetRabacSchemaArgs = {
  data: SetRabacSchemaMutationInput;
};


export type MutationSetRemoteConfigArgs = {
  data: SetRemoteConfigInput;
};


export type MutationSetUserNotificationSettingsArgs = {
  data: Array<NotificationInput>;
};


export type MutationStartConvertManuallyForInvoiceArgs = {
  acceptedPartiallyConvert?: InputMaybe<Scalars['Boolean']['input']>;
  id: Scalars['String']['input'];
  toCurrency?: InputMaybe<Scalars['String']['input']>;
};


export type MutationStartSumsubIdentificationArgs = {
  completedLevel?: InputMaybe<Scalars['String']['input']>;
};


export type MutationToggleAutoConvertForCompanyArgs = {
  asset?: InputMaybe<Scalars['String']['input']>;
  volatilityCoverageEnabled?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationTransferCompanyOwnershipArgs = {
  newOwnerUserId: Scalars['String']['input'];
};


export type MutationUpdateCompanyArgs = {
  data: UpdateCompanyInput;
};


export type MutationUpdateCompanyFeeSettingsInReferralArgs = {
  data: UpdateCompanyFeeSettingsInReferralDataInput;
  where: UpdateCompanyFeeSettingsInReferralWhereInput;
};


export type MutationUpdateCompanyNotificationSettingsArgs = {
  data: Array<UpdateCompanyNotificationSettingsInput>;
};


export type MutationUpdateCustomQuestionnaireFieldArgs = {
  customFieldLabel?: InputMaybe<Scalars['String']['input']>;
};


export type MutationUpdateOneCompanyRelationArgs = {
  data: UpdateCompanyRelationInput;
  where: CompanyUserUniqueInput;
};


export type MutationUpdateOneMyOwnWithdrawArgs = {
  data: UpdateOneWithdrawDataInput;
  where: RecordIdInput;
};


export type MutationUpdateOneRequisiteArgs = {
  fields: Scalars['JSON']['input'];
  id: Scalars['String']['input'];
  name: Scalars['String']['input'];
  paymentMethodAsset: Scalars['String']['input'];
  paymentMethodCode: Scalars['String']['input'];
  paymentMethodTransferType: TransferType;
};


export type MutationUpdateOneWithdrawArgs = {
  data: UpdateOneWithdrawDataInput;
  where: RecordIdInput;
};


export type MutationUpdatePayoutArgs = {
  data: UpdatePayoutDataInput;
  where: UpdatePayoutWhereInput;
};


export type MutationUpdatePayoutOperationDetailsArgs = {
  data: UpdatePayoutOperationDetailsDataInput;
  id: Scalars['String']['input'];
};


export type MutationUpdateProfileArgs = {
  data: UpdateProfileInput;
};


export type MutationUpdateUserNotificationSettingsArgs = {
  data: Array<UpdateUserNotificationSettingsInput>;
};


export type MutationZenCreateAccountLinkArgs = {
  phone: Scalars['String']['input'];
};


export type MutationZenCreateBusinessAccountLinkArgs = {
  phone: Scalars['String']['input'];
};

export type NestedBigIntFilter = {
  equals?: InputMaybe<Scalars['BigInt']['input']>;
  gt?: InputMaybe<Scalars['BigInt']['input']>;
  gte?: InputMaybe<Scalars['BigInt']['input']>;
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  lt?: InputMaybe<Scalars['BigInt']['input']>;
  lte?: InputMaybe<Scalars['BigInt']['input']>;
  not?: InputMaybe<NestedBigIntFilter>;
  notIn?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

export type NestedBoolFilter = {
  equals?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<NestedBoolFilter>;
};

export type NestedDateTimeFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDateTimeNullableFilter = {
  equals?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<Scalars['DateTime']['input']>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<NestedDateTimeNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['DateTime']['input']>>;
};

export type NestedDecimalFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedDecimalNullableFilter = {
  equals?: InputMaybe<Scalars['Decimal']['input']>;
  gt?: InputMaybe<Scalars['Decimal']['input']>;
  gte?: InputMaybe<Scalars['Decimal']['input']>;
  in?: InputMaybe<Array<Scalars['Decimal']['input']>>;
  lt?: InputMaybe<Scalars['Decimal']['input']>;
  lte?: InputMaybe<Scalars['Decimal']['input']>;
  not?: InputMaybe<NestedDecimalNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Decimal']['input']>>;
};

export type NestedEnumAssetTypeFilter = {
  equals?: InputMaybe<AssetType>;
  in?: InputMaybe<Array<AssetType>>;
  not?: InputMaybe<NestedEnumAssetTypeFilter>;
  notIn?: InputMaybe<Array<AssetType>>;
};

export type NestedEnumCompanyFeeStrategyNullableFilter = {
  equals?: InputMaybe<CompanyFeeStrategy>;
  in?: InputMaybe<Array<CompanyFeeStrategy>>;
  not?: InputMaybe<NestedEnumCompanyFeeStrategyNullableFilter>;
  notIn?: InputMaybe<Array<CompanyFeeStrategy>>;
};

export type NestedEnumCompanyFeeTypeFilter = {
  equals?: InputMaybe<CompanyFeeType>;
  in?: InputMaybe<Array<CompanyFeeType>>;
  not?: InputMaybe<NestedEnumCompanyFeeTypeFilter>;
  notIn?: InputMaybe<Array<CompanyFeeType>>;
};

export type NestedEnumCompanyKycSourceFilter = {
  equals?: InputMaybe<CompanyKycSource>;
  in?: InputMaybe<Array<CompanyKycSource>>;
  not?: InputMaybe<NestedEnumCompanyKycSourceFilter>;
  notIn?: InputMaybe<Array<CompanyKycSource>>;
};

export type NestedEnumCompanyLimitPeriodFilter = {
  equals?: InputMaybe<CompanyLimitPeriod>;
  in?: InputMaybe<Array<CompanyLimitPeriod>>;
  not?: InputMaybe<NestedEnumCompanyLimitPeriodFilter>;
  notIn?: InputMaybe<Array<CompanyLimitPeriod>>;
};

export type NestedEnumCompanyLimitTypeFilter = {
  equals?: InputMaybe<CompanyLimitType>;
  in?: InputMaybe<Array<CompanyLimitType>>;
  not?: InputMaybe<NestedEnumCompanyLimitTypeFilter>;
  notIn?: InputMaybe<Array<CompanyLimitType>>;
};

export type NestedEnumCompanyTypeFilter = {
  equals?: InputMaybe<CompanyType>;
  in?: InputMaybe<Array<CompanyType>>;
  not?: InputMaybe<NestedEnumCompanyTypeFilter>;
  notIn?: InputMaybe<Array<CompanyType>>;
};

export type NestedEnumConvertStatusFilter = {
  equals?: InputMaybe<ConvertStatus>;
  in?: InputMaybe<Array<ConvertStatus>>;
  not?: InputMaybe<NestedEnumConvertStatusFilter>;
  notIn?: InputMaybe<Array<ConvertStatus>>;
};

export type NestedEnumConvertSupportStatusFilter = {
  equals?: InputMaybe<ConvertSupportStatus>;
  in?: InputMaybe<Array<ConvertSupportStatus>>;
  not?: InputMaybe<NestedEnumConvertSupportStatusFilter>;
  notIn?: InputMaybe<Array<ConvertSupportStatus>>;
};

export type NestedEnumDepositAddressTypeFilter = {
  equals?: InputMaybe<DepositAddressType>;
  in?: InputMaybe<Array<DepositAddressType>>;
  not?: InputMaybe<NestedEnumDepositAddressTypeFilter>;
  notIn?: InputMaybe<Array<DepositAddressType>>;
};

export type NestedEnumIdentificationStatusFilter = {
  equals?: InputMaybe<IdentificationStatus>;
  in?: InputMaybe<Array<IdentificationStatus>>;
  not?: InputMaybe<NestedEnumIdentificationStatusFilter>;
  notIn?: InputMaybe<Array<IdentificationStatus>>;
};

export type NestedEnumInvoiceStatusFilter = {
  equals?: InputMaybe<InvoiceStatus>;
  in?: InputMaybe<Array<InvoiceStatus>>;
  not?: InputMaybe<NestedEnumInvoiceStatusFilter>;
  notIn?: InputMaybe<Array<InvoiceStatus>>;
};

export type NestedEnumPayoutOperationStatusFilter = {
  equals?: InputMaybe<PayoutOperationStatus>;
  in?: InputMaybe<Array<PayoutOperationStatus>>;
  not?: InputMaybe<NestedEnumPayoutOperationStatusFilter>;
  notIn?: InputMaybe<Array<PayoutOperationStatus>>;
};

export type NestedEnumPayoutStatusFilter = {
  equals?: InputMaybe<PayoutStatus>;
  in?: InputMaybe<Array<PayoutStatus>>;
  not?: InputMaybe<NestedEnumPayoutStatusFilter>;
  notIn?: InputMaybe<Array<PayoutStatus>>;
};

export type NestedEnumPayoutSupportStatusFilter = {
  equals?: InputMaybe<PayoutSupportStatus>;
  in?: InputMaybe<Array<PayoutSupportStatus>>;
  not?: InputMaybe<NestedEnumPayoutSupportStatusFilter>;
  notIn?: InputMaybe<Array<PayoutSupportStatus>>;
};

export type NestedEnumReportStatusFilter = {
  equals?: InputMaybe<ReportStatus>;
  in?: InputMaybe<Array<ReportStatus>>;
  not?: InputMaybe<NestedEnumReportStatusFilter>;
  notIn?: InputMaybe<Array<ReportStatus>>;
};

export type NestedEnumReportTypeFilter = {
  equals?: InputMaybe<ReportType>;
  in?: InputMaybe<Array<ReportType>>;
  not?: InputMaybe<NestedEnumReportTypeFilter>;
  notIn?: InputMaybe<Array<ReportType>>;
};

export type NestedEnumRequisiteTypeFilter = {
  equals?: InputMaybe<RequisiteType>;
  in?: InputMaybe<Array<RequisiteType>>;
  not?: InputMaybe<NestedEnumRequisiteTypeFilter>;
  notIn?: InputMaybe<Array<RequisiteType>>;
};

export type NestedEnumStatusOfCompanyFilter = {
  equals?: InputMaybe<StatusOfCompany>;
  in?: InputMaybe<Array<StatusOfCompany>>;
  not?: InputMaybe<NestedEnumStatusOfCompanyFilter>;
  notIn?: InputMaybe<Array<StatusOfCompany>>;
};

export type NestedEnumStatusOfUserFilter = {
  equals?: InputMaybe<StatusOfUser>;
  in?: InputMaybe<Array<StatusOfUser>>;
  not?: InputMaybe<NestedEnumStatusOfUserFilter>;
  notIn?: InputMaybe<Array<StatusOfUser>>;
};

export type NestedEnumTransferStatusFilter = {
  equals?: InputMaybe<TransferStatus>;
  in?: InputMaybe<Array<TransferStatus>>;
  not?: InputMaybe<NestedEnumTransferStatusFilter>;
  notIn?: InputMaybe<Array<TransferStatus>>;
};

export type NestedEnumTransferStatusSupportFilter = {
  equals?: InputMaybe<TransferStatusSupport>;
  in?: InputMaybe<Array<TransferStatusSupport>>;
  not?: InputMaybe<NestedEnumTransferStatusSupportFilter>;
  notIn?: InputMaybe<Array<TransferStatusSupport>>;
};

export type NestedEnumTransferTypeFilter = {
  equals?: InputMaybe<TransferType>;
  in?: InputMaybe<Array<TransferType>>;
  not?: InputMaybe<NestedEnumTransferTypeFilter>;
  notIn?: InputMaybe<Array<TransferType>>;
};

export type NestedEnumZenStatusFilter = {
  equals?: InputMaybe<ZenStatus>;
  in?: InputMaybe<Array<ZenStatus>>;
  not?: InputMaybe<NestedEnumZenStatusFilter>;
  notIn?: InputMaybe<Array<ZenStatus>>;
};

export type NestedFloatFilter = {
  equals?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<NestedFloatFilter>;
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type NestedIntFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedIntNullableFilter = {
  equals?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<NestedIntNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type NestedStringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NestedStringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type NonAuthAmlDetailsOutput = {
  riskScore: Scalars['String']['output'];
};

export type NonAuthCompanyDetailsOutput = {
  avatar?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  legalName?: Maybe<Scalars['String']['output']>;
  supportInfo?: Maybe<Scalars['String']['output']>;
  title: Scalars['String']['output'];
  websiteUrl?: Maybe<Scalars['String']['output']>;
};

export type NonAuthDepositAddressOutput = {
  address: Scalars['String']['output'];
  id: Scalars['String']['output'];
  memo?: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
};

export type NonAuthDepositPaymentMethodOutput = {
  Asset: PublicAssetOutput;
  asset: Scalars['String']['output'];
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  network?: Maybe<Scalars['String']['output']>;
  payload: Scalars['JSON']['output'];
  position: Scalars['Int']['output'];
  processor: Scalars['String']['output'];
};

export type NonAuthInvoiceDetailsOutput = {
  Address?: Maybe<NonAuthDepositAddressOutput>;
  Company: NonAuthCompanyDetailsOutput;
  Customization?: Maybe<InvoiceCustomizationOutput>;
  InvoiceAsset: PublicAssetOutput;
  PaymentAsset?: Maybe<PublicAssetOutput>;
  PaymentMethod?: Maybe<PaymentMethod>;
  /** The product used to calculate the invoice payment amount */
  Product?: Maybe<PublicProductOutput>;
  Transfers?: Maybe<Array<NonAuthTransferDetailsOutput>>;
  companyId: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  completedByUserId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorId: Scalars['String']['output'];
  expireAt?: Maybe<Scalars['DateTime']['output']>;
  externalOrderId?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  invoiceAmount: Scalars['Decimal']['output'];
  invoiceAssetCode: Scalars['String']['output'];
  isCreatedByApi: Scalars['Boolean']['output'];
  isPaymentAfterTimeout: Scalars['Boolean']['output'];
  logicalTime: Scalars['BigInt']['output'];
  paidAmount: Scalars['Decimal']['output'];
  paidFee: Scalars['Decimal']['output'];
  payload: Scalars['JSON']['output'];
  paymentAmount?: Maybe<Scalars['Decimal']['output']>;
  paymentAssetCode?: Maybe<Scalars['String']['output']>;
  paymentFee?: Maybe<Scalars['Decimal']['output']>;
  paymentMethodId?: Maybe<Scalars['String']['output']>;
  productCategory?: Maybe<Scalars['String']['output']>;
  productDescription?: Maybe<Scalars['String']['output']>;
  rate?: Maybe<Scalars['Decimal']['output']>;
  rateFreezeAt?: Maybe<Scalars['DateTime']['output']>;
  status: InvoiceStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type NonAuthTransferDetailsOutput = {
  AmlDetails?: Maybe<NonAuthAmlDetailsOutput>;
  Asset: PublicAssetOutput;
  Details?: Maybe<TransferDetailsOutput>;
  PaymentMethod?: Maybe<NonAuthDepositPaymentMethodOutput>;
  amount: Scalars['Decimal']['output'];
  createdAt: Scalars['DateTime']['output'];
  fee?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['String']['output'];
  processedAmount?: Maybe<Scalars['Decimal']['output']>;
  status: TransferStatus;
};

export type NoteListRelationFilter = {
  every?: InputMaybe<NoteWhereInput>;
  none?: InputMaybe<NoteWhereInput>;
  some?: InputMaybe<NoteWhereInput>;
};

export type NoteWhereInput = {
  AND?: InputMaybe<Array<NoteWhereInput>>;
  Company?: InputMaybe<CompanyNullableRelationFilter>;
  Creator?: InputMaybe<ProfileRelationFilter>;
  NOT?: InputMaybe<Array<NoteWhereInput>>;
  OR?: InputMaybe<Array<NoteWhereInput>>;
  User?: InputMaybe<ProfileNullableRelationFilter>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorId?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  text?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringNullableFilter>;
};

/** Allowed to enable confirm entities */
export enum NotificationByCompanyEntities {
  AutoConvert = 'autoConvert',
  Deposit = 'deposit',
  Invoice = 'invoice',
  Payout = 'payout',
  ReferralProgram = 'referralProgram',
  Withdraw = 'withdraw'
}

export type NotificationByCompanyInput = {
  entity: NotificationByCompanyEntities;
  isEnabled: Scalars['Boolean']['input'];
};

export type NotificationByCompanySettingsNullableRelationFilter = {
  is?: InputMaybe<NotificationByCompanySettingsWhereInput>;
  isNot?: InputMaybe<NotificationByCompanySettingsWhereInput>;
};

export type NotificationByCompanySettingsWhereInput = {
  AND?: InputMaybe<Array<NotificationByCompanySettingsWhereInput>>;
  NOT?: InputMaybe<Array<NotificationByCompanySettingsWhereInput>>;
  OR?: InputMaybe<Array<NotificationByCompanySettingsWhereInput>>;
  RelationUserToCompany?: InputMaybe<RelationUserToCompanyRelationFilter>;
  autoConvert?: InputMaybe<BoolFilter>;
  companyId?: InputMaybe<StringFilter>;
  deposit?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  invoice?: InputMaybe<BoolFilter>;
  payout?: InputMaybe<BoolFilter>;
  referralProgram?: InputMaybe<BoolFilter>;
  userId?: InputMaybe<StringFilter>;
  withdraw?: InputMaybe<BoolFilter>;
};

/** Allowed to enable confirm entities */
export enum NotificationEntities {
  Advertisement = 'advertisement',
  Login = 'login'
}

export enum NotificationGroup {
  AutoConvert = 'AutoConvert',
  Deposit = 'Deposit',
  Identification = 'Identification',
  Invoice = 'Invoice',
  Login = 'Login',
  ManualConvert = 'ManualConvert',
  Payout = 'Payout',
  Referral = 'Referral',
  Withdrawal = 'Withdrawal'
}

export type NotificationInput = {
  entity: NotificationEntities;
  isEnabled: Scalars['Boolean']['input'];
};

export type NotificationListRelationFilter = {
  every?: InputMaybe<NotificationWhereInput>;
  none?: InputMaybe<NotificationWhereInput>;
  some?: InputMaybe<NotificationWhereInput>;
};

export type NotificationOutput = {
  Asset?: Maybe<PublicAssetOutput>;
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  entityId?: Maybe<Scalars['String']['output']>;
  group: Scalars['String']['output'];
  id: Scalars['String']['output'];
  isRead: Scalars['Boolean']['output'];
  payload: Scalars['JSON']['output'];
  template: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type NotificationSettingsByCompanyOutput = {
  autoConvert: Scalars['Boolean']['output'];
  companyId: Scalars['String']['output'];
  deposit: Scalars['Boolean']['output'];
  invoice: Scalars['Boolean']['output'];
  payout: Scalars['Boolean']['output'];
  referralProgram: Scalars['Boolean']['output'];
  userId: Scalars['String']['output'];
  withdraw: Scalars['Boolean']['output'];
};

export type NotificationSettingsDetailsOutput = {
  email: Scalars['Boolean']['output'];
  push: Scalars['Boolean']['output'];
};

export type NotificationSettingsNullableRelationFilter = {
  is?: InputMaybe<NotificationSettingsWhereInput>;
  isNot?: InputMaybe<NotificationSettingsWhereInput>;
};

export type NotificationSettingsOutput = {
  advertisement: Scalars['Boolean']['output'];
  login: Scalars['Boolean']['output'];
};

export type NotificationSettingsWhereInput = {
  AND?: InputMaybe<Array<NotificationSettingsWhereInput>>;
  NOT?: InputMaybe<Array<NotificationSettingsWhereInput>>;
  OR?: InputMaybe<Array<NotificationSettingsWhereInput>>;
  Profile?: InputMaybe<ProfileRelationFilter>;
  advertisement?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  login?: InputMaybe<BoolFilter>;
};

export enum NotificationTemplate {
  AutoConvertProcessed = 'AutoConvertProcessed',
  CompanyExceededDepositLimits = 'CompanyExceededDepositLimits',
  DepositProcessed = 'DepositProcessed',
  IdentificationAccepted = 'IdentificationAccepted',
  IdentificationDetailsAboutProduct = 'IdentificationDetailsAboutProduct',
  IdentificationNeedHelp = 'IdentificationNeedHelp',
  IdentificationNeedToComplete = 'IdentificationNeedToComplete',
  IdentificationRejected = 'IdentificationRejected',
  InvoiceDepositArrested = 'InvoiceDepositArrested',
  InvoiceDepositCheckSuccessful = 'InvoiceDepositCheckSuccessful',
  InvoiceDepositProcessed = 'InvoiceDepositProcessed',
  InvoiceDepositSuspended = 'InvoiceDepositSuspended',
  InvoiceReminderWithHelp = 'InvoiceReminderWithHelp',
  InvoiceUnPaid = 'InvoiceUnPaid',
  ManualConvertProcessed = 'ManualConvertProcessed',
  PayoutFailed = 'PayoutFailed',
  PayoutProcessed = 'PayoutProcessed',
  ReferralReminderEndDiscount = 'ReferralReminderEndDiscount',
  ReferralRewardProcessed = 'ReferralRewardProcessed',
  SuccessfulLogin = 'SuccessfulLogin',
  WithdrawalProcessed = 'WithdrawalProcessed'
}

export type NotificationWhereInput = {
  AND?: InputMaybe<Array<NotificationWhereInput>>;
  Company?: InputMaybe<CompanyNullableRelationFilter>;
  NOT?: InputMaybe<Array<NotificationWhereInput>>;
  OR?: InputMaybe<Array<NotificationWhereInput>>;
  User?: InputMaybe<ProfileRelationFilter>;
  companyId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entityId?: InputMaybe<StringNullableFilter>;
  group?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  isRead?: InputMaybe<BoolFilter>;
  payload?: InputMaybe<JsonFilter>;
  template?: InputMaybe<StringFilter>;
  userId?: InputMaybe<StringFilter>;
};

export enum NullsOrder {
  First = 'first',
  Last = 'last'
}

export type OsDetails = {
  /** Value examples: 'Android', 'Windows', 'iOS', 'Mac OS'... */
  name?: Maybe<Scalars['String']['output']>;
  version?: Maybe<Scalars['String']['output']>;
};

export enum OtpExceptionType {
  Email = 'email',
  Sms = 'sms',
  Totp = 'totp'
}

export type OtpSettingsDetailsOutput = {
  phoneNumber?: Maybe<Scalars['String']['output']>;
};

export enum OtpType {
  Sms = 'SMS',
  Totp = 'TOTP'
}

export type PaidAmountByReferralProgramToCompanyOutput = {
  Asset: PublicAssetOutput;
  amount: Scalars['String']['output'];
};

export type PaymentMethod = {
  asset: Scalars['String']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  enabled: Scalars['Boolean']['output'];
  id: Scalars['String']['output'];
  logicalTime: Scalars['BigInt']['output'];
  merchantId: Scalars['String']['output'];
  network?: Maybe<Scalars['String']['output']>;
  payload: Scalars['JSON']['output'];
  position: Scalars['Int']['output'];
  processor: Scalars['String']['output'];
  saasOperation: Scalars['String']['output'];
  transferType: TransferType;
  updatedAt: Scalars['DateTime']['output'];
};

export type PaymentMethodField = {
  description?: Maybe<Scalars['String']['output']>;
  isAlwaysFilledUser?: Maybe<Scalars['Boolean']['output']>;
  isMasked?: Maybe<Scalars['Boolean']['output']>;
  isReadOnly?: Maybe<Scalars['Boolean']['output']>;
  isRequired?: Maybe<Scalars['Boolean']['output']>;
  isResultField?: Maybe<Scalars['Boolean']['output']>;
  label: Scalars['String']['output'];
  name: Scalars['String']['output'];
  position: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  validators: Array<Scalars['JSON']['output']>;
  valuesForSelect?: Maybe<Array<PaymentMethodValueForSelect>>;
};

export type PaymentMethodListRelationFilter = {
  every?: InputMaybe<PaymentMethodWhereInput>;
  none?: InputMaybe<PaymentMethodWhereInput>;
  some?: InputMaybe<PaymentMethodWhereInput>;
};

export type PaymentMethodMessage = {
  description?: Maybe<Scalars['String']['output']>;
  header: Scalars['String']['output'];
  type: PaymentMethodPayloadMessageType;
};

export enum PaymentMethodNetwork {
  Corefy = 'Corefy',
  Erc20 = 'ERC20',
  Kuna = 'Kuna',
  Trc20 = 'TRC20',
  Test = 'Test'
}

export type PaymentMethodNullableRelationFilter = {
  is?: InputMaybe<PaymentMethodWhereInput>;
  isNot?: InputMaybe<PaymentMethodWhereInput>;
};

export enum PaymentMethodPayloadMessageType {
  Info = 'info',
  Label = 'label',
  Warning = 'warning'
}

export type PaymentMethodRelationFilter = {
  is?: InputMaybe<PaymentMethodWhereInput>;
  isNot?: InputMaybe<PaymentMethodWhereInput>;
};

export type PaymentMethodStubInput = {
  network: PaymentMethodNetwork;
};

export type PaymentMethodValueForSelect = {
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type PaymentMethodWhereInput = {
  AND?: InputMaybe<Array<PaymentMethodWhereInput>>;
  Asset?: InputMaybe<AssetRelationFilter>;
  Invoice?: InputMaybe<InvoiceListRelationFilter>;
  NOT?: InputMaybe<Array<PaymentMethodWhereInput>>;
  OR?: InputMaybe<Array<PaymentMethodWhereInput>>;
  PayoutOperation?: InputMaybe<PayoutOperationListRelationFilter>;
  Transfer?: InputMaybe<TransferListRelationFilter>;
  asset?: InputMaybe<StringFilter>;
  code?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  enabled?: InputMaybe<BoolFilter>;
  id?: InputMaybe<StringFilter>;
  logicalTime?: InputMaybe<BigIntFilter>;
  merchantId?: InputMaybe<StringFilter>;
  network?: InputMaybe<StringNullableFilter>;
  payload?: InputMaybe<JsonFilter>;
  position?: InputMaybe<IntFilter>;
  processor?: InputMaybe<StringFilter>;
  saasOperation?: InputMaybe<StringFilter>;
  transferType?: InputMaybe<EnumTransferTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PayoutCallbackSettingsInput = {
  shouldSendTransactionalCallback?: InputMaybe<Scalars['Boolean']['input']>;
  url: Scalars['String']['input'];
};

export type PayoutInitiatorsOutput = {
  /** If the payout was created through the API key,then the fields "Create" and "Launch" will be empty  */
  ApiKey?: Maybe<InitiatorApiKeyOutput>;
  Create?: Maybe<InitiatorProfileDetailsOutput>;
  Launch?: Maybe<InitiatorProfileDetailsOutput>;
};

export enum PayoutIssueCode {
  DuplicatePayoutRequisite = 'DUPLICATE_PAYOUT_REQUISITE',
  InsufficientFunds = 'INSUFFICIENT_FUNDS',
  PaymentMethodAmountValidation = 'PAYMENT_METHOD_AMOUNT_VALIDATION',
  PaymentMethodDelisted = 'PAYMENT_METHOD_DELISTED',
  PaymentMethodNotSupported = 'PAYMENT_METHOD_NOT_SUPPORTED',
  PaymentMethodValidation = 'PAYMENT_METHOD_VALIDATION'
}

export type PayoutIssueOutput = {
  asset?: Maybe<Scalars['String']['output']>;
  code: PayoutIssueCode;
  contractorId?: Maybe<Scalars['String']['output']>;
  contractorIndex?: Maybe<Scalars['Float']['output']>;
  isCritical: Scalars['String']['output'];
  message: Scalars['String']['output'];
  paymentMethodField?: Maybe<Scalars['String']['output']>;
  requisiteFields?: Maybe<Scalars['JSON']['output']>;
};

export type PayoutListRelationFilter = {
  every?: InputMaybe<PayoutWhereInput>;
  none?: InputMaybe<PayoutWhereInput>;
  some?: InputMaybe<PayoutWhereInput>;
};

export type PayoutOperationListRelationFilter = {
  every?: InputMaybe<PayoutOperationWhereInput>;
  none?: InputMaybe<PayoutOperationWhereInput>;
  some?: InputMaybe<PayoutOperationWhereInput>;
};

export type PayoutOperationNullableRelationFilter = {
  is?: InputMaybe<PayoutOperationWhereInput>;
  isNot?: InputMaybe<PayoutOperationWhereInput>;
};

export type PayoutOperationPaymentDetailsOutput = {
  address?: Maybe<Scalars['String']['output']>;
  comment?: Maybe<Scalars['String']['output']>;
  memo?: Maybe<Scalars['String']['output']>;
  receiverName?: Maybe<Scalars['String']['output']>;
  toKunaPay?: Maybe<Scalars['String']['output']>;
  toKunaPro?: Maybe<Scalars['String']['output']>;
};

export type PayoutOperationPublicWhereInput = {
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  paymentMethodId?: InputMaybe<StringFilter>;
  payoutId?: InputMaybe<StringFilter>;
  status?: InputMaybe<EnumPayoutOperationStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum PayoutOperationStatus {
  Created = 'Created',
  Declined = 'Declined',
  Failed = 'Failed',
  Processed = 'Processed',
  Processing = 'Processing'
}

export type PayoutOperationWhereInput = {
  AND?: InputMaybe<Array<PayoutOperationWhereInput>>;
  NOT?: InputMaybe<Array<PayoutOperationWhereInput>>;
  OR?: InputMaybe<Array<PayoutOperationWhereInput>>;
  PaymentMethod?: InputMaybe<PaymentMethodRelationFilter>;
  Payout?: InputMaybe<PayoutRelationFilter>;
  Transfer?: InputMaybe<TransferNullableRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  logicalTime?: InputMaybe<BigIntFilter>;
  originalAmount?: InputMaybe<DecimalFilter>;
  payload?: InputMaybe<JsonFilter>;
  paymentMethodId?: InputMaybe<StringFilter>;
  payoutId?: InputMaybe<StringFilter>;
  publicReason?: InputMaybe<StringNullableListFilter>;
  reason?: InputMaybe<StringNullableListFilter>;
  status?: InputMaybe<EnumPayoutOperationStatusFilter>;
  supportStatus?: InputMaybe<EnumPayoutOperationStatusFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PayoutOrderByInput = {
  completedAt?: InputMaybe<SortOrderInput>;
  createdAt?: InputMaybe<SortOrder>;
  creatorId?: InputMaybe<SortOrder>;
  description?: InputMaybe<SortOrderInput>;
  id?: InputMaybe<SortOrder>;
  launchedAt?: InputMaybe<SortOrderInput>;
  status?: InputMaybe<SortOrder>;
  title?: InputMaybe<SortOrderInput>;
  updatedAt?: InputMaybe<SortOrder>;
};

export type PayoutPaymentSummaryListRelationFilter = {
  every?: InputMaybe<PayoutPaymentSummaryWhereInput>;
  none?: InputMaybe<PayoutPaymentSummaryWhereInput>;
  some?: InputMaybe<PayoutPaymentSummaryWhereInput>;
};

export type PayoutPaymentSummaryWhereInput = {
  AND?: InputMaybe<Array<PayoutPaymentSummaryWhereInput>>;
  Asset?: InputMaybe<AssetRelationFilter>;
  NOT?: InputMaybe<Array<PayoutPaymentSummaryWhereInput>>;
  OR?: InputMaybe<Array<PayoutPaymentSummaryWhereInput>>;
  Payout?: InputMaybe<PayoutRelationFilter>;
  asset?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  logicalTime?: InputMaybe<BigIntFilter>;
  paidAmount?: InputMaybe<DecimalFilter>;
  paidFee?: InputMaybe<DecimalFilter>;
  paidTxCount?: InputMaybe<IntFilter>;
  payoutId?: InputMaybe<StringFilter>;
  totalAmount?: InputMaybe<DecimalFilter>;
  totalTxCount?: InputMaybe<IntFilter>;
};

export type PayoutRelationFilter = {
  is?: InputMaybe<PayoutWhereInput>;
  isNot?: InputMaybe<PayoutWhereInput>;
};

export enum PayoutScalarFieldEnum {
  CompanyId = 'companyId',
  CompletedAt = 'completedAt',
  CreatedAt = 'createdAt',
  CreatorId = 'creatorId',
  Description = 'description',
  ExternalId = 'externalId',
  Id = 'id',
  IsCreatedByApi = 'isCreatedByApi',
  LaunchInitiatorId = 'launchInitiatorId',
  LaunchedAt = 'launchedAt',
  LogicalTime = 'logicalTime',
  Payload = 'payload',
  Status = 'status',
  SupportStatus = 'supportStatus',
  Title = 'title',
  UpdatedAt = 'updatedAt'
}

export enum PayoutStatus {
  Created = 'Created',
  Failed = 'Failed',
  Processed = 'Processed',
  Processing = 'Processing'
}

export enum PayoutSupportStatus {
  Created = 'Created',
  Failed = 'Failed',
  Launched = 'Launched',
  Processed = 'Processed',
  Processing = 'Processing'
}

export type PayoutWhereInput = {
  AND?: InputMaybe<Array<PayoutWhereInput>>;
  Company?: InputMaybe<CompanyRelationFilter>;
  NOT?: InputMaybe<Array<PayoutWhereInput>>;
  OR?: InputMaybe<Array<PayoutWhereInput>>;
  PayoutOperation?: InputMaybe<PayoutOperationListRelationFilter>;
  PayoutPaymentSummary?: InputMaybe<PayoutPaymentSummaryListRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorId?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringNullableFilter>;
  externalId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  isCreatedByApi?: InputMaybe<BoolFilter>;
  launchInitiatorId?: InputMaybe<StringNullableFilter>;
  launchedAt?: InputMaybe<DateTimeNullableFilter>;
  logicalTime?: InputMaybe<BigIntFilter>;
  payload?: InputMaybe<JsonFilter>;
  status?: InputMaybe<EnumPayoutStatusFilter>;
  supportStatus?: InputMaybe<EnumPayoutSupportStatusFilter>;
  title?: InputMaybe<StringNullableFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export type PreRequestEnableOtpOutput = {
  Totp?: Maybe<GenerateOtpUriOutput>;
  success: Scalars['Boolean']['output'];
};

export type PreRequestInvoiceOutput = {
  /** Invoice amount without fee */
  amount: Scalars['Decimal']['output'];
  /** Fee amount */
  fee: Scalars['Decimal']['output'];
  id: Scalars['String']['output'];
  isCanBePaid: Scalars['Boolean']['output'];
  issues?: Maybe<Array<Scalars['String']['output']>>;
  /** Invoice amount with fee */
  processedAmount: Scalars['Decimal']['output'];
  rate: Scalars['Decimal']['output'];
  rateFreezeAt: Scalars['DateTime']['output'];
};

export type PreRequestManualConvertOutput = {
  amount: Scalars['Decimal']['output'];
  expireAt: Scalars['DateTime']['output'];
  fee: Scalars['Decimal']['output'];
  fromAmount: Scalars['Decimal']['output'];
  fromCurrency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  processedAmount: Scalars['Decimal']['output'];
  rate: Scalars['Decimal']['output'];
  toCurrency: Scalars['String']['output'];
};

export type PreRequestPayoutAmountOutput = {
  /** Total amount with fee */
  amountWithFee: Scalars['String']['output'];
  asset: Scalars['String']['output'];
  /** Total fee */
  fee: Scalars['String']['output'];
};

export type PreRequestPayoutOutput = {
  issues?: Maybe<Array<PayoutIssueOutput>>;
  success: Scalars['Boolean']['output'];
  totalAmounts: Array<PreRequestPayoutAmountOutput>;
};

export type PresignFeedbackAttachmentUrlInput = {
  contentType: Scalars['String']['input'];
  filename: Scalars['String']['input'];
};

export type PresignFeedbackAttachmentUrlsOutput = {
  urls: Array<Scalars['String']['output']>;
};

export type ProductDiscountOutput = {
  discountFeeRate?: Maybe<Scalars['String']['output']>;
  originalFeeRate?: Maybe<Scalars['String']['output']>;
};

export enum ProductFeeStrategy {
  External = 'external',
  Internal = 'internal'
}

export type ProductMeta = {
  by: Scalars['String']['output'];
  byValues: Scalars['String']['output'];
  id?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
};

export type ProductOutput = {
  disabled?: Maybe<Scalars['Boolean']['output']>;
  feeFixed?: Maybe<Scalars['String']['output']>;
  feeFixedNonNative?: Maybe<Scalars['String']['output']>;
  feeMin?: Maybe<Scalars['String']['output']>;
  feeMinNonNative?: Maybe<Scalars['String']['output']>;
  feeNonNativeCurrency?: Maybe<Scalars['String']['output']>;
  feeRate?: Maybe<Scalars['String']['output']>;
  feeRateIncreasedBy?: Maybe<Scalars['String']['output']>;
  feeStrategy?: Maybe<ProductFeeStrategy>;
  isNonNativeFee?: Maybe<Scalars['Boolean']['output']>;
  maxAmount?: Maybe<Scalars['String']['output']>;
  meta: ProductMeta;
  minAmount?: Maybe<Scalars['String']['output']>;
  referralPayoutPercent?: Maybe<Scalars['String']['output']>;
};

export type ProfileListRelationFilter = {
  every?: InputMaybe<ProfileWhereInput>;
  none?: InputMaybe<ProfileWhereInput>;
  some?: InputMaybe<ProfileWhereInput>;
};

export enum ProfileNotificationEntities {
  Advertisement = 'advertisement',
  Login = 'login'
}

export type ProfileNotificationSettingsOutput = {
  advertisement: NotificationSettingsDetailsOutput;
  login: NotificationSettingsDetailsOutput;
};

export type ProfileNullableRelationFilter = {
  is?: InputMaybe<ProfileWhereInput>;
  isNot?: InputMaybe<ProfileWhereInput>;
};

export type ProfileRelationFilter = {
  is?: InputMaybe<ProfileWhereInput>;
  isNot?: InputMaybe<ProfileWhereInput>;
};

export type ProfileWhereInput = {
  AND?: InputMaybe<Array<ProfileWhereInput>>;
  Affiliate?: InputMaybe<CompanyNullableRelationFilter>;
  CompanyRelation?: InputMaybe<RelationUserToCompanyListRelationFilter>;
  CompleteInvoice?: InputMaybe<InvoiceListRelationFilter>;
  ConfirmationSetting?: InputMaybe<ConfirmationSettingNullableRelationFilter>;
  CreatedNote?: InputMaybe<NoteListRelationFilter>;
  NOT?: InputMaybe<Array<ProfileWhereInput>>;
  Note?: InputMaybe<NoteListRelationFilter>;
  Notification?: InputMaybe<NotificationListRelationFilter>;
  NotificationSetting?: InputMaybe<NotificationSettingsNullableRelationFilter>;
  NotificationSettings?: InputMaybe<CustomNotificationSettingsListRelationFilter>;
  OR?: InputMaybe<Array<ProfileWhereInput>>;
  Requisite?: InputMaybe<RequisiteListRelationFilter>;
  affiliateId?: InputMaybe<StringNullableFilter>;
  avatar?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  firstName?: InputMaybe<StringNullableFilter>;
  fullName?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  identificationStatus?: InputMaybe<EnumIdentificationStatusFilter>;
  isAgreePrivacyPolicy?: InputMaybe<BoolFilter>;
  isKUNAEmployee?: InputMaybe<BoolFilter>;
  isSuspicious?: InputMaybe<BoolFilter>;
  language?: InputMaybe<StringFilter>;
  lastName?: InputMaybe<StringNullableFilter>;
  logicalTime?: InputMaybe<BigIntFilter>;
  merchantId?: InputMaybe<StringFilter>;
  referenceId?: InputMaybe<StringNullableFilter>;
  role?: InputMaybe<StringFilter>;
  sn?: InputMaybe<IntFilter>;
  status?: InputMaybe<EnumStatusOfUserFilter>;
  username?: InputMaybe<StringFilter>;
  utm?: InputMaybe<StringFilter>;
};

export type PublicAssetOutput = {
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  isAutoConvertCurrency: Scalars['Boolean']['output'];
  isInvoiceCurrency: Scalars['Boolean']['output'];
  isPaymentCurrency: Scalars['Boolean']['output'];
  isReferenceCurrency: Scalars['Boolean']['output'];
  isReferralPayoutCurrency: Scalars['Boolean']['output'];
  minInvoiceAmount: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  payload: Scalars['JSON']['output'];
  position: Scalars['Int']['output'];
  precision: Scalars['Int']['output'];
  rateFreezeSeconds: Scalars['Int']['output'];
  type: AssetType;
};

export type PublicAssetWithSettingsOutput = {
  AssetSettings?: Maybe<AssetSettingsOutput>;
  code: Scalars['String']['output'];
  description: Scalars['String']['output'];
  isAutoConvertCurrency: Scalars['Boolean']['output'];
  isInvoiceCurrency: Scalars['Boolean']['output'];
  isPaymentCurrency: Scalars['Boolean']['output'];
  isReferenceCurrency: Scalars['Boolean']['output'];
  isReferralPayoutCurrency: Scalars['Boolean']['output'];
  minInvoiceAmount: Scalars['Decimal']['output'];
  name: Scalars['String']['output'];
  payload: Scalars['JSON']['output'];
  position: Scalars['Int']['output'];
  precision: Scalars['Int']['output'];
  rateFreezeSeconds: Scalars['Int']['output'];
  type: AssetType;
};

export type PublicPaymentMethodOutput = {
  Asset?: Maybe<PublicAssetOutput>;
  Product: PublicProductOutput;
  asset: Scalars['String']['output'];
  code: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  customTitle?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enabled: Scalars['Boolean']['output'];
  fields: Array<PaymentMethodField>;
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  isPrefill?: Maybe<Scalars['Boolean']['output']>;
  isRequisiteDeleteDisabled?: Maybe<Scalars['Boolean']['output']>;
  isRequisiteUpdateDisabled?: Maybe<Scalars['Boolean']['output']>;
  logicalTime: Scalars['BigInt']['output'];
  maxRequisiteCount?: Maybe<Scalars['Int']['output']>;
  merchantId: Scalars['String']['output'];
  messages: Array<PaymentMethodMessage>;
  name: Scalars['String']['output'];
  network?: Maybe<Scalars['String']['output']>;
  payload: Scalars['JSON']['output'];
  position: Scalars['Int']['output'];
  processor: Scalars['String']['output'];
  purposeField?: Maybe<Scalars['String']['output']>;
  saasOperation: Scalars['String']['output'];
  transferType: TransferType;
  trustWalletCoinId?: Maybe<Scalars['String']['output']>;
  updatedAt: Scalars['DateTime']['output'];
};

export type PublicPaymentMethodWithAssetOutput = {
  Asset: PublicAssetOutput;
  asset: Scalars['String']['output'];
  code: Scalars['String']['output'];
  id: Scalars['String']['output'];
  network?: Maybe<Scalars['String']['output']>;
  payload: Scalars['JSON']['output'];
  position: Scalars['Int']['output'];
  processor: Scalars['String']['output'];
};

export type PublicPayoutOperationOutput = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  originalAmount: Scalars['Decimal']['output'];
  publicReason: Array<Scalars['String']['output']>;
  status: PayoutOperationStatus;
  updatedAt: Scalars['DateTime']['output'];
};

export type PublicPayoutPaymentSummaryOutput = {
  Asset: PublicAssetOutput;
  asset: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  logicalTime: Scalars['BigInt']['output'];
  paidAmount: Scalars['Decimal']['output'];
  paidFee: Scalars['Decimal']['output'];
  paidTxCount: Scalars['Int']['output'];
  payoutId: Scalars['String']['output'];
  totalAmount: Scalars['Decimal']['output'];
  totalTxCount: Scalars['Int']['output'];
};

export type PublicProductOutput = {
  feeFixed?: Maybe<Scalars['String']['output']>;
  feeMin?: Maybe<Scalars['String']['output']>;
  feeRate?: Maybe<Scalars['String']['output']>;
  feeStrategy?: Maybe<ProductFeeStrategy>;
  maxAmount?: Maybe<Scalars['String']['output']>;
  meta: ProductMeta;
  minAmount?: Maybe<Scalars['String']['output']>;
  referralPayoutPercent?: Maybe<Scalars['String']['output']>;
};

export type PublicTransferOutput = {
  Asset: PublicAssetOutput;
  PaymentMethod: PaymentMethod;
  address?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Decimal']['output'];
  asset: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  convertId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorComment?: Maybe<Scalars['String']['output']>;
  creatorId?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['String']['output'];
  invoiceId?: Maybe<Scalars['String']['output']>;
  isCreatedByApi: Scalars['Boolean']['output'];
  logicalTime: Scalars['BigInt']['output'];
  merchantId: Scalars['String']['output'];
  paymentCode: Scalars['String']['output'];
  payoutOperationId?: Maybe<Scalars['String']['output']>;
  processedAmount?: Maybe<Scalars['Decimal']['output']>;
  reason: Array<Scalars['String']['output']>;
  status: TransferStatus;
  type: TransferType;
  updatedAt: Scalars['DateTime']['output'];
};

export type PublicTransferWithAmlDetailsOutput = {
  AmlDetails?: Maybe<TransferAmlDetailsOutput>;
  Asset: PublicAssetOutput;
  Convert?: Maybe<ConvertPublicOutput>;
  Details?: Maybe<TransferDetailsOutput>;
  PaymentMethod: PaymentMethod;
  address?: Maybe<Scalars['String']['output']>;
  amount: Scalars['Decimal']['output'];
  asset: Scalars['String']['output'];
  companyId: Scalars['String']['output'];
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  convertId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  creatorComment?: Maybe<Scalars['String']['output']>;
  creatorId?: Maybe<Scalars['String']['output']>;
  fee?: Maybe<Scalars['Decimal']['output']>;
  id: Scalars['String']['output'];
  invoiceId?: Maybe<Scalars['String']['output']>;
  isCreatedByApi: Scalars['Boolean']['output'];
  logicalTime: Scalars['BigInt']['output'];
  merchantId: Scalars['String']['output'];
  paymentCode: Scalars['String']['output'];
  payoutOperationId?: Maybe<Scalars['String']['output']>;
  processedAmount?: Maybe<Scalars['Decimal']['output']>;
  reason: Array<Scalars['String']['output']>;
  status: TransferStatus;
  type: TransferType;
  updatedAt: Scalars['DateTime']['output'];
};

export type PublicUserLog = {
  action: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  details: Scalars['JSON']['output'];
  group: Scalars['String']['output'];
  id: Scalars['String']['output'];
};

export type PushNotificationEventOutput = {
  companyId?: Maybe<Scalars['String']['output']>;
  userId: Scalars['String']['output'];
};

export type PushNotificationStubInput = {
  group: NotificationGroup;
  template: NotificationTemplate;
};

export type Query = {
  calculatePaidAmountByReferralProgramToCompany: Array<PaidAmountByReferralProgramToCompanyOutput>;
  checkAll: Array<CheckResult>;
  checkCompanyExists: CheckCompanyExistOutput;
  checkMany: Array<CheckResult>;
  checkOne: CheckResult;
  checkUsernameExists: CheckUsernameOutput;
  checkUsernameExistsOnKunaPro: CheckUsernameOutput;
  clearApiKeyCache: SuccessOutput;
  features: Array<Feature>;
  findActualMaintenance?: Maybe<MaintenanceNotificationOutput>;
  findAnswersToQuestionnaireByEntity?: Maybe<QuestionnaireForPublicOutput>;
  findAssetForConvertInvoice: Array<PublicAssetOutput>;
  findAvailableCoworkerRoles: AvailableCoworkerRolesOutput;
  findAvailableFieldsForQuestionnaire: Array<QuestionnaireFieldOutput>;
  /** Find available types to create company */
  findAvailableTypesForCreateCompany: AvailableTypesForCreateCompanyOutput;
  findCocktailPromoCustomPriceDetails?: Maybe<CocktailPromoOutput>;
  findCocktailPromoDetails?: Maybe<CocktailPromoOutput>;
  /** Find current company details(CompanyId takes from Jwt token) */
  findCompanyDetails: CompanyDetailsOutput;
  findCompanyNotificationSettings: CompanyNotificationSettingsOutput;
  /** Find payout details */
  findCompanyPayoutDetails: FindCompanyPayoutDetailsOutput;
  findCompanyStatisticByInvoices: CompanyStatisticByInvoicesOutput;
  /** Use for get constant deposit address, can be empty if address not created for source */
  findConstDepositAddress?: Maybe<DepositAddressPublicOutput>;
  findCountNotReadNotification: FindCountNotReadNotificationOutput;
  findCountNotReadNotificationByGroup: Array<FindCountNotReadNotificationByGroupOutput>;
  findCountOfActiveReferralByCompany: CountOfActiveReferralByCompany;
  findDataForDashboardByMyOwnTransfers: FindDataForDashboardByTransfersOutput;
  findDataForDashboardByTransfers: FindDataForDashboardByTransfersOutput;
  findDetailsForDonationPageNonAuth: FindDetailsForDonationPageNonAuthOutput;
  findDonationDetailsForNonAuth?: Maybe<FindDonationForNonAuthOutput>;
  findGroupedByAssetAutoConvertPaymentMethod: Array<GroupedByAssetAutoConvertPaymentMethodPublicOutput>;
  findGroupedByAssetPaymentMethod?: Maybe<Array<GroupedByAssetPaymentMethodWithProductPublicOutput>>;
  findInvoiceDetails: InvoiceDetailsOutput;
  findManyActiveSession: Array<SessionDetailsOutput>;
  /** Get list active api keys */
  findManyApiKeys: Array<ApiKeyOutput>;
  /** Find many available Assets in System */
  findManyAsset: Array<PublicAssetWithSettingsOutput>;
  findManyAvailablePaymentMethods: Array<PublicPaymentMethodOutput>;
  /** Get the current balance of the company */
  findManyCompanyAssets: Array<CompanyAsset>;
  findManyCompanyFeeSettings: Array<CompanyFeeSettingsOutput>;
  findManyCompanyLimit: Array<CompanyLimitOutput>;
  /** Find many payout */
  findManyCompanyPayout: Array<FindManyCompanyPayoutOutput>;
  findManyCompanyReferrals: Array<FindManyCompanyReferralOutput>;
  findManyCompanyRelations: Array<CompanyRelationOutput>;
  findManyCompanyTransfers: Array<CompanyTransferOutput>;
  findManyCoworkerInvites: Array<FindInvitedCoworkerOutput>;
  findManyCurrentCompanyActionHistory: Array<PublicUserLog>;
  findManyCurrentUserActionHistory: Array<PublicUserLog>;
  findManyExpiredSession: Array<SessionDetailsOutput>;
  findManyInvoiceRelatedToCompany: Array<FindManyInvoiceOutput>;
  findManyLanguages: Array<Language>;
  findManyMyOwnCompanyTransfers: Array<CompanyTransferOutput>;
  findManyMyOwnInvoiceInCompany: Array<FindManyInvoiceOutput>;
  findManyNotifications: Array<NotificationOutput>;
  /** Find many payout operations and Transfer */
  findManyPayoutOperationByPayout: Array<FindManyPayoutOperationOutput>;
  findManyPreventMaintenance?: Maybe<Array<MaintenanceNotificationOutput>>;
  findManyRate: Array<Rate>;
  findManyRateForNonAuth: Array<Rate>;
  findManyReport: Array<ReportOutput>;
  /** Get user saved withdraw/deposit requisites */
  findManyRequisite: Array<Requisite>;
  findOneQuestionnaireByEntity?: Maybe<QuestionnaireForPublicOutput>;
  findOneQuestionnaireToFill?: Maybe<QuestionnaireForPublicOutput>;
  findOneQuestionnaireToFillForNonAuth?: Maybe<QuestionnaireForNonAuthOutput>;
  findOtpSettings?: Maybe<FindOtpSettingsOutput>;
  findPaymentMethodsForManualConvert: Array<PublicPaymentMethodOutput>;
  findPublicInvoiceById: NonAuthInvoiceDetailsOutput;
  findPublicPaymentMethodsToPayInvoice: Array<PublicPaymentMethodOutput>;
  findReferralInvoicePaymentDiscountForCompany?: Maybe<ProductDiscountOutput>;
  /** Find all companies where user is coworker */
  findRelatedCompanies: Array<FindRelatedCompaniesOutput>;
  findSmsSupportedPhoneCountryCodes: Array<FindSupportedPhoneCountryCodesOutput>;
  findUniqueCompanyTransfer: CompanyTransferOutput;
  /** Get status of user confirmation settings */
  findUniqueConfirmationSetting: ConfirmationSetting;
  findUniqueMyOwnCompanyTransfer: CompanyTransferOutput;
  /**
   * Get status of user notification settings
   * @deprecated Will be removed
   */
  findUniqueNotificationByCompanySettings: NotificationSettingsByCompanyOutput;
  /**
   * Get status of user notification settings
   * @deprecated Will be removed
   */
  findUniqueUserNotificationSettings: NotificationSettingsOutput;
  findUserNotificationSettings: ProfileNotificationSettingsOutput;
  /** Generate API key pair */
  generateKeys: GenerateKeysOutput;
  /** Generate otp(2fa) Uri */
  generateOtpUri: GenerateOtpUriOutput;
  getAssetPaymentMethodPrefill: Scalars['JSON']['output'];
  getErrors: Array<Exception>;
  getRabacRoles: AvailableRabacRolesOutput;
  /** Get requisite values */
  getRequisiteValues: RequisiteValuesOutput;
  getServiceInfo: ServiceInfo;
  /** Login throughout google */
  googleLogin: AuthTokensOutput;
  /** Check exists by kuna token */
  kunaCheckExistsByToken: Scalars['Boolean']['output'];
  /** Login throughout kuna */
  kunaLogin: AuthTokensOutput;
  login: AuthTokensOutput;
  /** Login to company as coworker */
  loginToCompany: AuthTokensOutput;
  markAllNotificationAsRead: SuccessOutput;
  markManyNotificationAsRead: SuccessOutput;
  me: UserProfileInformationOutput;
  publicFeatures: Array<Feature>;
  rabacSchema: RabacSchema;
  /** Endpoint for refreshing access token */
  refreshToken: AuthTokensOutput;
  remoteConfigs: Array<RemoteConfig>;
  /** Request to change the commission strategy for company */
  requestToChangeCommissionStrategyForCompany: SuccessOutput;
  resendLoginOtpNonAuth: SuccessOutput;
  /** @deprecated Will be removed, need to use resendLoginOtpNonAuth */
  resendLoginOtpToEmail: SuccessOutput;
  resendOtp: SuccessOutput;
  /** @deprecated Will be removed, need to use resendOtp */
  resendOtpToEmail: SuccessOutput;
  shareTypePushNotificationEventWsEvent?: Maybe<PushNotificationEventOutput>;
  shareTypeTransferWsEvent?: Maybe<TransferEventOutput>;
  stubForSharingTypingInGraphQL: SuccessOutput;
  validateCryptoAddressBlockListByPaymentMethod: ValidateCryptoAddressBlockListByPaymentMethodOutput;
  zenGetAccountLinkStatus: GetAccountLinkStatus;
};


export type QueryCheckCompanyExistsArgs = {
  companyId: Scalars['String']['input'];
};


export type QueryCheckManyArgs = {
  modules: Array<Scalars['String']['input']>;
};


export type QueryCheckOneArgs = {
  module: Scalars['String']['input'];
};


export type QueryCheckUsernameExistsArgs = {
  username: Scalars['String']['input'];
};


export type QueryCheckUsernameExistsOnKunaProArgs = {
  username: Scalars['String']['input'];
};


export type QueryClearApiKeyCacheArgs = {
  apiKey: Scalars['String']['input'];
};


export type QueryFindAnswersToQuestionnaireByEntityArgs = {
  entityId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFindAssetForConvertInvoiceArgs = {
  id: Scalars['String']['input'];
};


export type QueryFindCocktailPromoCustomPriceDetailsArgs = {
  companyId: Scalars['String']['input'];
  usdtAmount: Scalars['String']['input'];
};


export type QueryFindCocktailPromoDetailsArgs = {
  companyId: Scalars['String']['input'];
};


export type QueryFindCompanyPayoutDetailsArgs = {
  id: Scalars['String']['input'];
};


export type QueryFindCompanyStatisticByInvoicesArgs = {
  dateRange?: InputMaybe<DateRangeInput>;
};


export type QueryFindConstDepositAddressArgs = {
  source: Scalars['String']['input'];
};


export type QueryFindDataForDashboardByMyOwnTransfersArgs = {
  dateRange: DateRangeInput;
  timePart: DashboardStatisticTimePart;
  timeZone: Scalars['String']['input'];
};


export type QueryFindDataForDashboardByTransfersArgs = {
  dateRange: DateRangeInput;
  timePart: DashboardStatisticTimePart;
  timeZone: Scalars['String']['input'];
};


export type QueryFindDetailsForDonationPageNonAuthArgs = {
  companyId: Scalars['String']['input'];
};


export type QueryFindDonationDetailsForNonAuthArgs = {
  addressId: Scalars['String']['input'];
  companyId: Scalars['String']['input'];
};


export type QueryFindGroupedByAssetPaymentMethodArgs = {
  counterpartyGroupType: TypeOfCounterpartyGroup;
  where: PaymentMethodWhereInput;
};


export type QueryFindInvoiceDetailsArgs = {
  id: Scalars['String']['input'];
};


export type QueryFindManyActiveSessionArgs = {
  orderBy?: InputMaybe<Array<SessionOrderByInput>>;
};


export type QueryFindManyApiKeysArgs = {
  orderBy?: InputMaybe<Array<ApiKeyOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindManyAssetArgs = {
  orderBy?: InputMaybe<Array<AssetOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindManyPublicAssetWhereInput>;
};


export type QueryFindManyAvailablePaymentMethodsArgs = {
  orderBy?: InputMaybe<Array<FindManyAvailablePaymentMethodOrderByInput>>;
  where: FindManyAvailablePaymentMethodWhereInput;
};


export type QueryFindManyCompanyAssetsArgs = {
  assetCodes?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type QueryFindManyCompanyLimitArgs = {
  where: FindManyCompanyLimitWhere;
};


export type QueryFindManyCompanyPayoutArgs = {
  distinct?: InputMaybe<Array<PayoutScalarFieldEnum>>;
  orderBy?: InputMaybe<Array<PayoutOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindManyCompanyPayoutWhereInput>;
};


export type QueryFindManyCompanyReferralsArgs = {
  orderBy?: InputMaybe<Array<FindManyReferralOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindManyReferralWhereInput>;
};


export type QueryFindManyCompanyRelationsArgs = {
  orderBy?: InputMaybe<Array<CompanyRelationDetailsOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindCompanyRelationWhereInput>;
};


export type QueryFindManyCompanyTransfersArgs = {
  orderBy?: InputMaybe<CompanyTransferOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindManyCompanyTransferWhereInput>;
};


export type QueryFindManyCoworkerInvitesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindManyCurrentCompanyActionHistoryArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindManyCurrentUserActionHistoryArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindManyExpiredSessionArgs = {
  orderBy?: InputMaybe<Array<SessionOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindManyInvoiceRelatedToCompanyArgs = {
  orderBy?: InputMaybe<Array<FindManyInvoiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindManyInvoiceWhereInput>;
};


export type QueryFindManyMyOwnCompanyTransfersArgs = {
  orderBy?: InputMaybe<CompanyTransferOrderInput>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindManyCompanyTransferWhereInput>;
};


export type QueryFindManyMyOwnInvoiceInCompanyArgs = {
  orderBy?: InputMaybe<Array<FindManyInvoiceOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindManyInvoiceWhereInput>;
};


export type QueryFindManyNotificationsArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindNotificationsWhereInput>;
};


export type QueryFindManyPayoutOperationByPayoutArgs = {
  orderBy?: InputMaybe<Array<FindManyPayoutOperationByPayoutOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where: FindManyPayoutOperationByPayoutWhereInput;
};


export type QueryFindManyReportArgs = {
  orderBy?: InputMaybe<Array<FindManyReportOrderByInput>>;
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
  where?: InputMaybe<FindManyReportWhereInput>;
};


export type QueryFindManyRequisiteArgs = {
  currency: Scalars['String']['input'];
  source: Scalars['String']['input'];
  type: RequisiteType;
};


export type QueryFindOneQuestionnaireByEntityArgs = {
  entityId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFindOneQuestionnaireToFillArgs = {
  entityId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFindOneQuestionnaireToFillForNonAuthArgs = {
  entityId?: InputMaybe<Scalars['String']['input']>;
};


export type QueryFindPaymentMethodsForManualConvertArgs = {
  fromCurrency: Scalars['String']['input'];
};


export type QueryFindPublicInvoiceByIdArgs = {
  id: Scalars['String']['input'];
};


export type QueryFindPublicPaymentMethodsToPayInvoiceArgs = {
  id: Scalars['String']['input'];
};


export type QueryFindRelatedCompaniesArgs = {
  skip?: InputMaybe<Scalars['Int']['input']>;
  take?: InputMaybe<Scalars['Int']['input']>;
};


export type QueryFindUniqueCompanyTransferArgs = {
  where: RecordIdInput;
};


export type QueryFindUniqueMyOwnCompanyTransferArgs = {
  where: RecordIdInput;
};


export type QueryGetAssetPaymentMethodPrefillArgs = {
  asset: Scalars['String']['input'];
  code: Scalars['String']['input'];
  type: TransferType;
};


export type QueryGetRequisiteValuesArgs = {
  id: Scalars['String']['input'];
};


export type QueryGoogleLoginArgs = {
  accessToken?: InputMaybe<Scalars['String']['input']>;
  affiliateId?: InputMaybe<Scalars['String']['input']>;
  isAgreePrivacyPolicy?: InputMaybe<Scalars['Boolean']['input']>;
  isSubscribe?: InputMaybe<Scalars['Boolean']['input']>;
  isTrustedDevice?: InputMaybe<Scalars['Boolean']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  referrerId?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  utm?: InputMaybe<Scalars['String']['input']>;
};


export type QueryKunaCheckExistsByTokenArgs = {
  token: Scalars['String']['input'];
};


export type QueryKunaLoginArgs = {
  isTrustedDevice?: InputMaybe<Scalars['Boolean']['input']>;
  token: Scalars['String']['input'];
};


export type QueryLoginArgs = {
  isTrustedDevice?: InputMaybe<Scalars['Boolean']['input']>;
  password: Scalars['String']['input'];
  token: Scalars['String']['input'];
};


export type QueryLoginToCompanyArgs = {
  companyId: Scalars['String']['input'];
};


export type QueryMarkManyNotificationAsReadArgs = {
  ids: Array<Scalars['String']['input']>;
};


export type QueryRefreshTokenArgs = {
  refreshToken: Scalars['String']['input'];
};


export type QueryRemoteConfigsArgs = {
  group?: InputMaybe<Scalars['String']['input']>;
};


export type QueryRequestToChangeCommissionStrategyForCompanyArgs = {
  preferredStrategy?: InputMaybe<ProductFeeStrategy>;
};


export type QueryResendLoginOtpNonAuthArgs = {
  email: Scalars['String']['input'];
};


export type QueryResendLoginOtpToEmailArgs = {
  email: Scalars['String']['input'];
};


export type QueryStubForSharingTypingInGraphQlArgs = {
  PaymentMethod?: InputMaybe<PaymentMethodStubInput>;
  PushNotification?: InputMaybe<PushNotificationStubInput>;
};


export type QueryValidateCryptoAddressBlockListByPaymentMethodArgs = {
  blockchain: Scalars['String']['input'];
  cryptoAddress: Scalars['String']['input'];
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export type QuestionnaireDetailsForNonAuthOutput = {
  isFilled: Scalars['Boolean']['output'];
  schema?: Maybe<Array<QuestionnaireFieldOutput>>;
};

export type QuestionnaireDetailsOutput = {
  answers?: Maybe<Scalars['JSON']['output']>;
  isFilled: Scalars['Boolean']['output'];
  schema?: Maybe<Array<QuestionnaireFieldOutput>>;
};

export enum QuestionnaireFieldName {
  BillingAddress = 'billing_address',
  Custom = 'custom',
  Email = 'email',
  FirstName = 'first_name',
  LastName = 'last_name'
}

export type QuestionnaireFieldOutput = {
  /** Needed only for types sharing */
  defaultQuestionnaireFieldNames?: Maybe<QuestionnaireFieldName>;
  description?: Maybe<Scalars['String']['output']>;
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  position: Scalars['Float']['output'];
  type: QuestionnaireFieldType;
  validators: Array<QuestionnaireValidatorOutput>;
};

export enum QuestionnaireFieldType {
  BillingAddress = 'BILLING_ADDRESS',
  Email = 'EMAIL',
  FirstName = 'FIRST_NAME',
  LastName = 'LAST_NAME',
  Text = 'TEXT'
}

export enum QuestionnaireFieldValidatorType {
  EmaIl = 'EMAIl',
  Max = 'MAX',
  Min = 'MIN'
}

export type QuestionnaireForNonAuthOutput = {
  Details: QuestionnaireDetailsForNonAuthOutput;
  id: Scalars['String']['output'];
};

export type QuestionnaireForPublicOutput = {
  Details: QuestionnaireDetailsOutput;
  id: Scalars['String']['output'];
};

export type QuestionnaireListRelationFilter = {
  every?: InputMaybe<QuestionnaireWhereInput>;
  none?: InputMaybe<QuestionnaireWhereInput>;
  some?: InputMaybe<QuestionnaireWhereInput>;
};

export type QuestionnaireValidatorOutput = {
  type: QuestionnaireFieldValidatorType;
  value?: Maybe<Scalars['Float']['output']>;
};

export type QuestionnaireWhereInput = {
  AND?: InputMaybe<Array<QuestionnaireWhereInput>>;
  Company?: InputMaybe<CompanyRelationFilter>;
  NOT?: InputMaybe<Array<QuestionnaireWhereInput>>;
  OR?: InputMaybe<Array<QuestionnaireWhereInput>>;
  companyId?: InputMaybe<StringFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  entityId?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  payload?: InputMaybe<JsonFilter>;
  title?: InputMaybe<StringNullableFilter>;
};

export type RabacSchema = {
  schema: Scalars['String']['output'];
};

export type Rate = {
  currency: Scalars['String']['output'];
  equivalent: Array<Equivalent>;
};

export type RecordIdInput = {
  id: Scalars['String']['input'];
};

export type ReferralCompanyDetailsForAffiliateOutput = {
  CompanyFeeSettings: Array<ReferralCompanyFeeSettingsForAffiliateOutput>;
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
};

export type ReferralCompanyFeeSettingsForAffiliateOutput = {
  increaseFeeRate: Scalars['Decimal']['output'];
};

export type RelationUserToCompanyListRelationFilter = {
  every?: InputMaybe<RelationUserToCompanyWhereInput>;
  none?: InputMaybe<RelationUserToCompanyWhereInput>;
  some?: InputMaybe<RelationUserToCompanyWhereInput>;
};

export type RelationUserToCompanyRelationFilter = {
  is?: InputMaybe<RelationUserToCompanyWhereInput>;
  isNot?: InputMaybe<RelationUserToCompanyWhereInput>;
};

export type RelationUserToCompanyWhereInput = {
  AND?: InputMaybe<Array<RelationUserToCompanyWhereInput>>;
  Company?: InputMaybe<CompanyRelationFilter>;
  NOT?: InputMaybe<Array<RelationUserToCompanyWhereInput>>;
  NotificationByCompanySettings?: InputMaybe<NotificationByCompanySettingsNullableRelationFilter>;
  OR?: InputMaybe<Array<RelationUserToCompanyWhereInput>>;
  Profile?: InputMaybe<ProfileRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  isActive?: InputMaybe<BoolFilter>;
  logicalTime?: InputMaybe<BigIntFilter>;
  role?: InputMaybe<StringFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type RemoteConfig = {
  description?: Maybe<Scalars['String']['output']>;
  group: Scalars['String']['output'];
  key: Scalars['String']['output'];
  mod: Scalars['Float']['output'];
  type: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export type ReportDetailsOutput = {
  dateRange: DateRangeOutput;
  format: ReportFileFormat;
  url?: Maybe<Scalars['String']['output']>;
};

export enum ReportFileFormat {
  Csv = 'CSV',
  Pdf = 'PDF'
}

export type ReportOutput = {
  Details: ReportDetailsOutput;
  companyId?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  expireAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  status: ReportStatus;
  type: ReportType;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};

export enum ReportStatus {
  Completed = 'Completed',
  Expired = 'Expired',
  InProgress = 'InProgress'
}

export enum ReportType {
  Internal = 'Internal',
  Invoice = 'Invoice',
  Settlement = 'Settlement',
  Statement = 'Statement'
}

export type Requisite = {
  companyId: Scalars['String']['output'];
  createdAt: Scalars['DateTime']['output'];
  currency: Scalars['String']['output'];
  id: Scalars['String']['output'];
  mask: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  source: Scalars['String']['output'];
  type: RequisiteType;
  userId: Scalars['String']['output'];
};

export type RequisiteListRelationFilter = {
  every?: InputMaybe<RequisiteWhereInput>;
  none?: InputMaybe<RequisiteWhereInput>;
  some?: InputMaybe<RequisiteWhereInput>;
};

export enum RequisiteType {
  Deposit = 'Deposit',
  Withdraw = 'Withdraw'
}

export type RequisiteValuesOutput = {
  name: Scalars['String']['output'];
  values: Scalars['JSON']['output'];
};

export type RequisiteWhereInput = {
  AND?: InputMaybe<Array<RequisiteWhereInput>>;
  Company?: InputMaybe<CompanyRelationFilter>;
  Currency?: InputMaybe<AssetRelationFilter>;
  NOT?: InputMaybe<Array<RequisiteWhereInput>>;
  OR?: InputMaybe<Array<RequisiteWhereInput>>;
  Profile?: InputMaybe<ProfileRelationFilter>;
  companyId?: InputMaybe<StringFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  currency?: InputMaybe<StringFilter>;
  id?: InputMaybe<StringFilter>;
  mask?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringNullableFilter>;
  source?: InputMaybe<StringFilter>;
  type?: InputMaybe<EnumRequisiteTypeFilter>;
  userId?: InputMaybe<StringFilter>;
};

export type ServiceInfo = {
  monitorConfig: Array<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  schemaDependency: Scalars['JSON']['output'];
  schemaVersion: Scalars['String']['output'];
  uptime: Scalars['Float']['output'];
  version: Scalars['String']['output'];
};

export enum ServiceName {
  KunaPayAuth = 'KUNA_PAY_AUTH',
  KunaPayCore = 'KUNA_PAY_CORE',
  KunaPayGateway = 'KUNA_PAY_GATEWAY',
  KunaPayUserLog = 'KUNA_PAY_USER_LOG'
}

export type SessionDetailsOutput = {
  createdAt: Scalars['DateTime']['output'];
  device: Scalars['String']['output'];
  deviceInfo: DeviceInfo;
  endAt?: Maybe<Scalars['DateTime']['output']>;
  fingerprint: Scalars['String']['output'];
  ip: Scalars['String']['output'];
  jwtId: Scalars['String']['output'];
  key: Scalars['String']['output'];
  location: Scalars['String']['output'];
  logicalTime: Scalars['BigInt']['output'];
  userAgent: Scalars['String']['output'];
  userId: Scalars['String']['output'];
};

export type SessionOrderByInput = {
  createdAt?: InputMaybe<SortOrder>;
  endAt?: InputMaybe<SortOrder>;
};

export type SetRabacSchemaMutationInput = {
  schema: Scalars['String']['input'];
};

export type SetRemoteConfigInput = {
  key: Scalars['String']['input'];
  value: Scalars['String']['input'];
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type SortOrderInput = {
  nulls?: InputMaybe<NullsOrder>;
  sort: SortOrder;
};

export type StartSumSubIdentificationOutput = {
  isFullyFilled: Scalars['Boolean']['output'];
  token: Scalars['String']['output'];
};

export enum StatusOfCompany {
  Approved = 'APPROVED',
  Deleted = 'DELETED',
  PermanentBlocked = 'PERMANENT_BLOCKED',
  Unapproved = 'UNAPPROVED'
}

export enum StatusOfUser {
  Blocked = 'BLOCKED',
  Confirmed = 'CONFIRMED',
  Deleted = 'DELETED',
  Invited = 'INVITED',
  PermanentBlocked = 'PERMANENT_BLOCKED',
  Unconfirmed = 'UNCONFIRMED'
}

export type StringFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableFilter = {
  contains?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  equals?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  mode?: InputMaybe<QueryMode>;
  not?: InputMaybe<NestedStringNullableFilter>;
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
};

export type StringNullableListFilter = {
  equals?: InputMaybe<Array<Scalars['String']['input']>>;
  has?: InputMaybe<Scalars['String']['input']>;
  hasEvery?: InputMaybe<Array<Scalars['String']['input']>>;
  hasSome?: InputMaybe<Array<Scalars['String']['input']>>;
  isEmpty?: InputMaybe<Scalars['Boolean']['input']>;
};

export type SuccessOutput = {
  success: Scalars['Boolean']['output'];
};

export type TransferApiKeyCreatorOutput = {
  id: Scalars['String']['output'];
  /** API key name */
  name?: Maybe<Scalars['String']['output']>;
};

export type TransferAdditionalFieldsOutput = {
  /** Link to external resource for example transaction in blockchain */
  externalLink?: Maybe<Scalars['String']['output']>;
  /** Is it possible to copy the value on the front */
  isCopyable?: Maybe<Scalars['Boolean']['output']>;
  /** Title of field for show on Frontend */
  label: Scalars['String']['output'];
  /** Name of field, example: txId, address etc. */
  name: Scalars['String']['output'];
  /** Value of field */
  value: Scalars['String']['output'];
};

export type TransferAmlAlertsOutput = {
  alertAmount: Scalars['Float']['output'];
  alertLevel: Scalars['String']['output'];
  category: Scalars['String']['output'];
  exposureType: Scalars['String']['output'];
  riskScore: Scalars['String']['output'];
  service: Scalars['String']['output'];
};

export type TransferAmlDetailsForCompanyOutput = {
  riskScore: Scalars['String']['output'];
};

export type TransferAmlDetailsOutput = {
  alerts: Array<TransferAmlAlertsOutput>;
  riskScore: Scalars['String']['output'];
};

export type TransferDetailsEventOutput = {
  id: Scalars['String']['output'];
  status: TransferStatus;
  type: TransferType;
};

export type TransferDetailsOutput = {
  autoConvertCurrency?: Maybe<Scalars['String']['output']>;
  linkToBlockchain?: Maybe<Scalars['String']['output']>;
  payoutContractorName?: Maybe<Scalars['String']['output']>;
  travelRuleVerificationLink?: Maybe<Scalars['String']['output']>;
  txId?: Maybe<Scalars['String']['output']>;
};

export type TransferEventOutput = {
  companyId: Scalars['String']['output'];
  details: TransferDetailsEventOutput;
  userId: Scalars['String']['output'];
};

export type TransferLimitListRelationFilter = {
  every?: InputMaybe<TransferLimitWhereInput>;
  none?: InputMaybe<TransferLimitWhereInput>;
  some?: InputMaybe<TransferLimitWhereInput>;
};

export type TransferLimitWhereInput = {
  AND?: InputMaybe<Array<TransferLimitWhereInput>>;
  Asset?: InputMaybe<AssetRelationFilter>;
  Company?: InputMaybe<CompanyNullableRelationFilter>;
  NOT?: InputMaybe<Array<TransferLimitWhereInput>>;
  OR?: InputMaybe<Array<TransferLimitWhereInput>>;
  asset?: InputMaybe<StringFilter>;
  companyId?: InputMaybe<StringNullableFilter>;
  countByDay?: InputMaybe<IntFilter>;
  countByMonth?: InputMaybe<IntFilter>;
  id?: InputMaybe<StringFilter>;
  maxAmount?: InputMaybe<DecimalFilter>;
  riskScore?: InputMaybe<FloatFilter>;
  sumByDay?: InputMaybe<DecimalFilter>;
  sumByMonth?: InputMaybe<DecimalFilter>;
  transferType?: InputMaybe<EnumTransferTypeFilter>;
};

export type TransferListRelationFilter = {
  every?: InputMaybe<TransferWhereInput>;
  none?: InputMaybe<TransferWhereInput>;
  some?: InputMaybe<TransferWhereInput>;
};

export type TransferNullableRelationFilter = {
  is?: InputMaybe<TransferWhereInput>;
  isNot?: InputMaybe<TransferWhereInput>;
};

export type TransferRelationFilter = {
  is?: InputMaybe<TransferWhereInput>;
  isNot?: InputMaybe<TransferWhereInput>;
};

export enum TransferStatus {
  ActionRequired = 'ActionRequired',
  Arrested = 'Arrested',
  Canceled = 'Canceled',
  Created = 'Created',
  PartiallyProcessed = 'PartiallyProcessed',
  Processed = 'Processed',
  Processing = 'Processing',
  Suspended = 'Suspended'
}

export type TransferStatusHistory = {
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  reason: Array<Scalars['String']['output']>;
  supportEmail?: Maybe<Scalars['String']['output']>;
  supportStatus: TransferStatusSupport;
  supportUserId?: Maybe<Scalars['String']['output']>;
  transferId: Scalars['String']['output'];
};

export type TransferStatusHistoryListRelationFilter = {
  every?: InputMaybe<TransferStatusHistoryWhereInput>;
  none?: InputMaybe<TransferStatusHistoryWhereInput>;
  some?: InputMaybe<TransferStatusHistoryWhereInput>;
};

export type TransferStatusHistoryWhereInput = {
  AND?: InputMaybe<Array<TransferStatusHistoryWhereInput>>;
  NOT?: InputMaybe<Array<TransferStatusHistoryWhereInput>>;
  OR?: InputMaybe<Array<TransferStatusHistoryWhereInput>>;
  Transfer?: InputMaybe<TransferRelationFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  id?: InputMaybe<StringFilter>;
  reason?: InputMaybe<StringNullableListFilter>;
  supportEmail?: InputMaybe<StringNullableFilter>;
  supportStatus?: InputMaybe<EnumTransferStatusSupportFilter>;
  supportUserId?: InputMaybe<StringNullableFilter>;
  transferId?: InputMaybe<StringFilter>;
};

export enum TransferStatusSupport {
  Arrested = 'Arrested',
  Canceled = 'Canceled',
  Created = 'Created',
  Declined = 'Declined',
  Manual = 'Manual',
  PartiallyProcessed = 'PartiallyProcessed',
  Processed = 'Processed',
  Processing = 'Processing',
  Rejected = 'Rejected',
  TechTrouble = 'TechTrouble'
}

export enum TransferType {
  Convert = 'Convert',
  Deposit = 'Deposit',
  InvoiceDeposit = 'InvoiceDeposit',
  ManualConvert = 'ManualConvert',
  PayoutWithdraw = 'PayoutWithdraw',
  ReferralReward = 'ReferralReward',
  Refund = 'Refund',
  Withdraw = 'Withdraw'
}

export type TransferWhereInput = {
  AND?: InputMaybe<Array<TransferWhereInput>>;
  Asset?: InputMaybe<AssetRelationFilter>;
  Company?: InputMaybe<CompanyRelationFilter>;
  Convert?: InputMaybe<ConvertNullableRelationFilter>;
  Invoice?: InputMaybe<InvoiceNullableRelationFilter>;
  NOT?: InputMaybe<Array<TransferWhereInput>>;
  OR?: InputMaybe<Array<TransferWhereInput>>;
  PaymentMethod?: InputMaybe<PaymentMethodRelationFilter>;
  PayoutOperation?: InputMaybe<PayoutOperationNullableRelationFilter>;
  TransferStatusHistory?: InputMaybe<TransferStatusHistoryListRelationFilter>;
  address?: InputMaybe<StringNullableFilter>;
  amount?: InputMaybe<DecimalFilter>;
  asset?: InputMaybe<StringFilter>;
  companyId?: InputMaybe<StringFilter>;
  completedAt?: InputMaybe<DateTimeNullableFilter>;
  convertId?: InputMaybe<StringNullableFilter>;
  createdAt?: InputMaybe<DateTimeFilter>;
  creatorComment?: InputMaybe<StringNullableFilter>;
  creatorId?: InputMaybe<StringNullableFilter>;
  fee?: InputMaybe<DecimalNullableFilter>;
  id?: InputMaybe<StringFilter>;
  invoiceId?: InputMaybe<StringNullableFilter>;
  isCreatedByApi?: InputMaybe<BoolFilter>;
  isHidden?: InputMaybe<BoolFilter>;
  logicalTime?: InputMaybe<BigIntFilter>;
  merchantId?: InputMaybe<StringFilter>;
  payload?: InputMaybe<JsonFilter>;
  paymentCode?: InputMaybe<StringFilter>;
  payoutOperationId?: InputMaybe<StringNullableFilter>;
  processedAmount?: InputMaybe<DecimalNullableFilter>;
  pspFee?: InputMaybe<DecimalNullableFilter>;
  reason?: InputMaybe<StringNullableListFilter>;
  status?: InputMaybe<EnumTransferStatusFilter>;
  supportStatus?: InputMaybe<EnumTransferStatusSupportFilter>;
  type?: InputMaybe<EnumTransferTypeFilter>;
  updatedAt?: InputMaybe<DateTimeFilter>;
};

export enum TypeOfCounterpartyGroup {
  AutoConvert = 'AutoConvert',
  Deposit = 'Deposit',
  Invoice = 'Invoice',
  ManualConvert = 'ManualConvert',
  PayoutWithdraw = 'PayoutWithdraw',
  RateLimit = 'RateLimit',
  ReferralReward = 'ReferralReward',
  Withdraw = 'Withdraw'
}

export enum TypeOfSignature {
  Hmac = 'HMAC',
  Rsa = 'RSA'
}

export type UpdateCompanyFeeSettingsInReferralDataInput = {
  increaseFeeRate: Scalars['Float']['input'];
};

export type UpdateCompanyFeeSettingsInReferralWhereInput = {
  referralCompanyId: Scalars['String']['input'];
};

export type UpdateCompanyInput = {
  avatar?: InputMaybe<Scalars['String']['input']>;
  invoiceUnpaidPercent?: InputMaybe<Scalars['String']['input']>;
  pspSettings?: InputMaybe<UpdateCompanyPspSettingsInput>;
  /** Reference/Payment currency */
  referenceCurrency?: InputMaybe<Scalars['String']['input']>;
  referralPayoutCurrency?: InputMaybe<Scalars['String']['input']>;
  supportInfo?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateCompanyNotificationSettingsInput = {
  email: Scalars['Boolean']['input'];
  entity: CompanyNotificationEntities;
  push: Scalars['Boolean']['input'];
};

export type UpdateCompanyPspSettingsInput = {
  increaseInvoiceRateForReferrals: Scalars['Float']['input'];
};

export type UpdateCompanyRelationInput = {
  /** User Role */
  role: Scalars['String']['input'];
};

export type UpdateOneCompanyAssetSettingsDataInput = {
  /** Precision can be no more than 2 characters. Correct example: 2.12; Incorrect: 2.1234 */
  invoiceCoefficient: Scalars['String']['input'];
};

export type UpdateOneWithdrawDataInput = {
  comment: Scalars['String']['input'];
};

export type UpdatePayoutDataInput = {
  title: Scalars['String']['input'];
};

export type UpdatePayoutOperationDetailsDataInput = {
  amount?: InputMaybe<Scalars['String']['input']>;
  comment?: InputMaybe<Scalars['String']['input']>;
};

export type UpdatePayoutWhereInput = {
  payoutId: Scalars['String']['input'];
};

export type UpdateProfileInput = {
  firstName?: InputMaybe<Scalars['String']['input']>;
  /** User's fullName */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** Selected interface language */
  language?: InputMaybe<AvailableLanguages>;
  lastName?: InputMaybe<Scalars['String']['input']>;
};

export type UpdateUniqueAssetWhereInput = {
  code: Scalars['String']['input'];
};

export type UpdateUserNotificationSettingsInput = {
  email: Scalars['Boolean']['input'];
  entity: ProfileNotificationEntities;
  push: Scalars['Boolean']['input'];
};

export type UpsertOneCompanyAssetSettingsInput = {
  data: UpdateOneCompanyAssetSettingsDataInput;
  where: UpdateUniqueAssetWhereInput;
};

export type UserProfileInformationOutput = {
  affiliateId?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  email: Scalars['String']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  identificationStatus: IdentificationStatus;
  isAgreePrivacyPolicy: Scalars['Boolean']['output'];
  isEnabledOtp: Scalars['Boolean']['output'];
  isKUNAEmployee: Scalars['Boolean']['output'];
  isSuspicious: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  logicalTime: Scalars['BigInt']['output'];
  merchantId: Scalars['String']['output'];
  referenceId?: Maybe<Scalars['String']['output']>;
  role: Scalars['String']['output'];
  sn: Scalars['Int']['output'];
  status: StatusOfUser;
  username: Scalars['String']['output'];
  utm: Scalars['String']['output'];
};

export type UserProfileOutput = {
  ConfirmationSetting?: Maybe<ConfirmationSetting>;
  NotificationSetting?: Maybe<NotificationSettingsOutput>;
  affiliateId?: Maybe<Scalars['String']['output']>;
  avatar?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  firstName?: Maybe<Scalars['String']['output']>;
  fullName: Scalars['String']['output'];
  id: Scalars['String']['output'];
  identificationStatus: IdentificationStatus;
  isAgreePrivacyPolicy: Scalars['Boolean']['output'];
  isKUNAEmployee: Scalars['Boolean']['output'];
  isSuspicious: Scalars['Boolean']['output'];
  language: Scalars['String']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  logicalTime: Scalars['BigInt']['output'];
  merchantId: Scalars['String']['output'];
  referenceId?: Maybe<Scalars['String']['output']>;
  relatedCompanies?: Maybe<Array<UserProfileRelatedCompanies>>;
  role: Scalars['String']['output'];
  sn: Scalars['Int']['output'];
  status: StatusOfUser;
  username: Scalars['String']['output'];
  utm: Scalars['String']['output'];
};

export type UserProfileRelatedCompanies = {
  avatar?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ValidateCryptoAddressBlockListByPaymentMethodOutput = {
  isValid: Scalars['Boolean']['output'];
};

export type ZenNullableRelationFilter = {
  is?: InputMaybe<ZenWhereInput>;
  isNot?: InputMaybe<ZenWhereInput>;
};

export enum ZenStatus {
  ChallengeCancelled = 'CHALLENGE_CANCELLED',
  Connected = 'CONNECTED',
  Create = 'CREATE',
  Declined = 'DECLINED',
  Empty = 'EMPTY',
  IntegrationDeleted = 'INTEGRATION_DELETED',
  Processing = 'PROCESSING'
}

export type ZenWhereInput = {
  AND?: InputMaybe<Array<ZenWhereInput>>;
  Company?: InputMaybe<CompanyRelationFilter>;
  NOT?: InputMaybe<Array<ZenWhereInput>>;
  OR?: InputMaybe<Array<ZenWhereInput>>;
  accountId?: InputMaybe<StringNullableFilter>;
  confirmationUrl?: InputMaybe<StringNullableFilter>;
  id?: InputMaybe<StringFilter>;
  linkId?: InputMaybe<StringFilter>;
  phone?: InputMaybe<StringFilter>;
  reason?: InputMaybe<StringNullableFilter>;
  status?: InputMaybe<EnumZenStatusFilter>;
  userId?: InputMaybe<StringFilter>;
};
