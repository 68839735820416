import {
  PublicPaymentMethodOutput,
  QueryFindManyAvailablePaymentMethodsArgs,
} from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const findManyAvailablePaymentMethodsRequest = (
  select: Select<PublicPaymentMethodOutput> | string
) => `query findManyAvailablePaymentMethods($orderBy: [FindManyAvailablePaymentMethodOrderByInput!], $where: FindManyAvailablePaymentMethodWhereInput!) {
  data: findManyAvailablePaymentMethods(orderBy: $orderBy, where: $where) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'PublicPaymentMethodOutput')}`;
export const findManyAvailablePaymentMethods =
  (select: Select<PublicPaymentMethodOutput> | string) =>
  (args: QueryFindManyAvailablePaymentMethodsArgs) =>
    query<PublicPaymentMethodOutput[]>(
      findManyAvailablePaymentMethodsRequest(select),
      args
    );
