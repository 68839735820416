import {
  PublicAssetWithSettingsOutput,
  QueryFindManyAssetArgs,
} from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const findManyAssetRequest = (
  select: Select<PublicAssetWithSettingsOutput> | string
) => `query findManyAsset($orderBy: [AssetOrderByInput!], $skip: Int, $take: Int, $where: FindManyPublicAssetWhereInput) {
  data: findManyAsset(orderBy: $orderBy, skip: $skip, take: $take, where: $where) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'PublicAssetWithSettingsOutput')}`;
export const findManyAsset =
  (select: Select<PublicAssetWithSettingsOutput> | string) =>
  (args: QueryFindManyAssetArgs) =>
    query<PublicAssetWithSettingsOutput[]>(findManyAssetRequest(select), args);
