import {
  AuthenticateFreshDeskOutput,
  MutationFreshDeskAuthArgs,
} from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const freshDeskAuthRequest = (
  select: Select<AuthenticateFreshDeskOutput> | string
) => `mutation freshDeskAuth($email: String!, $name: String) {
  data: freshDeskAuth(email: $email, name: $name) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'AuthenticateFreshDeskOutput')}`;
export const freshDeskAuth =
  (select: Select<AuthenticateFreshDeskOutput> | string) =>
  (args: MutationFreshDeskAuthArgs) =>
    query<AuthenticateFreshDeskOutput>(freshDeskAuthRequest(select), args);
