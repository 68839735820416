import { setState } from '@kuna-pay/utils/effector';
import type { StoreProperty } from '@kuna-pay/utils/effector/property';

import type { IKunaPayAuthService } from './interface.auth-service';
import { NoOpKunaPayAuthService } from './interface.auth-service';

class ApiOTPService {
  public constructor(public readonly $otp: StoreProperty<string | null>) {}

  public setOtp = (otp: string) => {
    setState(this.$otp.$store, otp);
  };

  public clearOtp = () => {
    setState(this.$otp.$store, null);
  };

  public createAuthServiceForGQLClient = (): IKunaPayAuthService => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;

    return new (class ApiTokensAuthService extends NoOpKunaPayAuthService {
      public override addHeaders(headers: Record<string, string>) {
        if (self.$otp.value) {
          headers.otp = self.$otp.value;
        }
      }
    })();
  };
}

export { ApiOTPService };
