import { useGate } from 'effector-react';
import { type ReactNode } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { useLocalModel } from '@kuna-pay/utils/react/use-local-model';
import { Link } from '@kuna-pay/ui/router';
import { Modal } from '@kuna-pay/ui/ui/modal';
import { Typography } from '@kuna-pay/ui/ui/typography';

import { ReactComponent as WarningLgIcon } from '../assets/warning-lg.svg';
import { StopWorkingWarningModel } from './stop-working-warning.model';
import styles from './stop-working-warning.modal.module.scss';

type Props = {
  children: ReactNode;
};

export const StopWorkingWarningModal = (props: Props) => {
  const { children } = props;

  const $$model = useLocalModel(() => StopWorkingWarningModel.createModel());

  useGate($$model.$$ui.Gate);

  const { t } = useTranslation('core');

  return (
    <>
      {children}
      <Modal
        id={$$model.$$ui.$$modal.ID}
        model={$$model.$$ui.$$modal.$$modal}
        content={
          <Modal.Content size='xxl' className={styles.root}>
            <div className={styles.head}>
              <span className={styles.iconWrapper}>
                <WarningLgIcon />
              </span>
              <Modal.Title variant='h7'>
                {t('features.close-warning.modal.title')}
              </Modal.Title>
            </div>
            <div className={styles.content}>
              <div className={styles.description}>
                <Typography variant='numbers2'>
                  {t('features.close-warning.modal.description.text-1')}
                </Typography>
                <ul className={styles.list}>
                  <li>
                    <Typography variant='numbers2'>
                      <Trans
                        t={t}
                        i18nKey='features.close-warning.modal.description.point-1'
                        components={{
                          highlight: (
                            <Typography variant='numbers1' as='span' />
                          ),
                        }}
                      />
                    </Typography>
                  </li>
                  <li>
                    <Typography variant='numbers2'>
                      <Trans
                        t={t}
                        i18nKey='features.close-warning.modal.description.point-2'
                        components={{
                          highlight: (
                            <Typography variant='numbers1' as='span' />
                          ),
                        }}
                      />
                    </Typography>
                  </li>
                  <li>
                    <Typography variant='numbers2'>
                      <Trans
                        t={t}
                        i18nKey='features.close-warning.modal.description.point-3'
                        components={{
                          highlight: (
                            <Typography variant='numbers1' as='span' />
                          ),
                        }}
                      />
                    </Typography>
                  </li>
                  <li>
                    <Typography variant='numbers2'>
                      <Trans
                        t={t}
                        i18nKey='features.close-warning.modal.description.point-4'
                        components={{
                          highlight: (
                            <Typography variant='numbers1' as='span' />
                          ),
                        }}
                      />
                    </Typography>
                  </li>
                </ul>
                <Typography variant='numbers2'>
                  <Trans
                    t={t}
                    i18nKey='features.close-warning.modal.description.text-2'
                    components={{
                      highlight: <Typography variant='numbers1' as='span' />,
                    }}
                  />
                </Typography>
                <Typography variant='numbers2'>
                  {t('features.close-warning.modal.description.text-3')}
                </Typography>
              </div>
              <div className={styles.contact}>
                <Link to='mailito:support@kunapay.io' target='_blank'>
                  <Typography variant='subtitle5_1'>
                    support@kunapay.io
                  </Typography>
                </Link>
              </div>
            </div>
          </Modal.Content>
        }
      />
    </>
  );
};
