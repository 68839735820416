import type { ComponentType, FC, HTMLAttributes, ReactNode } from 'react';

import type { PartialField } from '@kuna-pay/utils/typescript';
import type { TypographyProps } from '@kuna-pay/ui/ui/typography';

type GridColumnDimensions =
  | {
      /**
       * Column fraction of `non-fixed width`
       *
       * `Non-fixed width` is non-scrollable rendered table width subsctracted by fixed columns width.
       *
       *  If non-scrollable screen width is 1000px
       *  and there are 2 fixed columns with width 100px and 200px,
       *  then non-fixed width is 700px.
       */
      flex: number;

      /**
       * Column min width in pixels
       *
       * min value is 50px
       */
      minWidth: number;

      /**
       * Column min width in pixels
       */
      maxWidth?: number;
    }
  | {
      /**
       * Column width in pixels
       */

      width: number;
    };

type GridColumn<Row extends Record<string, unknown>> =
  | GridValueColumn<Row>
  | GridActionColumn<Row>;

type GridValueColumn<Row extends Record<string, unknown>> =
  GridColumnDimensions & {
    /**
     * Column field name
     *
     * data will be taken from row[field]
     */
    field: (string & keyof Row) | string;

    /**
     *  Column label
     */
    label?: string;

    //behavior
    sortable?: boolean;

    //styles

    className?: string;
    classes?: {
      cell?: string;
      text?: string;
    };

    props?: {
      cell?: HTMLAttributes<HTMLDivElement>;
      text?: PartialField<TypographyProps, 'variant'>;
    };

    //render
    /**
     * If falsy, then the column will be hidden by default.
     */
    fallbackValue?: ReactNode;

    /**
     * Render editable state when data grid editor's used and editing is active
     */
    renderEdit?: FC<GridCustomValueColumnProps<Row>>;

    /**
     * Custom render function for the entire column
     */
    renderCell?: FC<GridCustomValueColumnProps<Row>>;

    /**
     * Custom render function for the header cell
     */
    renderHead?: FC<GridCustomHeadColumnProps<Row>>;
  };

type GridCustomValueColumnProps<Row extends Record<string, unknown>> = {
  row: Row;
  column: Omit<GridValueColumn<Row>, 'renderCell'>;
};

type GridActionColumn<Row extends Record<string, unknown>> =
  GridColumnDimensions & {
    /**
     * Column field name
     */
    field: 'actions';

    /**
     * Column label
     */
    label?: string;

    /**
     * ActionColumn always has custom render function
     */
    render: FC<GridCustomActionColumnProps<Row>>;

    /**
     * Custom render function for the header cell
     */
    renderHead?: FC<GridCustomHeadColumnProps<Row>>;
  };

type GridCustomActionColumnProps<Row extends Record<string, unknown>> = {
  row: Row;
  column: Omit<GridActionColumn<Row>, 'renderCell'>;
};

type GridCustomHeadColumnProps<Row extends Record<string, unknown>> = {
  column: Omit<GridValueColumn<Row>, 'renderCell'>;
};

type InsertConfig<D> = {
  view: ComponentType<{ row: D; gridTemplateColumns: string }>;

  after?: string | number | null;
};

export type {
  GridActionColumn,
  GridColumn,
  GridCustomActionColumnProps,
  GridCustomHeadColumnProps,
  GridCustomValueColumnProps,
  GridValueColumn,
  InsertConfig,
};
export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc',
}
