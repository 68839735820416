import { captureException } from '@sentry/react';
import { createEvent, createStore, merge } from 'effector';

import { listen, setState } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';

import { $$session } from '@kuna-pay/merchant/entities/session';
import { $$featureFlags } from '@kuna-pay/merchant/shared/feature-flags';
import { $$i18n } from '@kuna-pay/merchant/shared/i18n';
import { $$monitoring } from '@kuna-pay/merchant/shared/monitoring';
import { $$recaptcha } from '@kuna-pay/merchant/shared/recaptcha';
import { $$cookieConsent } from '@kuna-pay/merchant/widgets/layout';

const $$boot = atom(() => {
  const init = createEvent('$$boot.init');
  const reload = createEvent('$$boot.reload');

  const $loading = createStore(true, {
    name: '$$boot.$loading',
  });
  const $error = createStore(false, {
    name: '$$boot.$error',
  });

  listen({
    name: '$$boot.onStart',

    // Since it only fails if we don't have a way to load static features
    // we can merge this clocks together
    clock: merge([init, reload]),

    handler: async () => {
      try {
        setState($loading, true);
        setState($error, false);

        await $$featureFlags.loadStaticFeaturesFx();

        try {
          // It's expected to be sync call
          // but effect always wraps it into promise
          await $$monitoring.initFx();
        } catch (e) {
          // Since we failed to initialize monitoring, we have nowhere to report
          // But we still to capture it, so we can report it later
          //
          // Assuming that Sentry saving it to some sort of storage

          captureException(e);

          console.error('Failed to init analytics', e);
        }

        try {
          await $$i18n.initFx();
        } catch (e) {
          captureException(e);
          console.error('Failed to init i18n', e);
        }

        $$cookieConsent.init();
        $$session.init();
        void $$recaptcha.initFx();

        setState($loading, false);
        setState($error, false);
      } catch {
        setState($loading, false);
        setState($error, true);
      }
    },
  });

  return {
    init,

    $$ui: {
      reloadClicked: reload,

      $loading,
      $error,
    },
  };
});

export { $$boot };
