import type { CustomDetector } from 'i18next-browser-languagedetector';

import { getLanguageFromToken } from '../@x/api';

class JwtDetector implements CustomDetector {
  public name = 'jwt-detector';

  public lookup() {
    try {
      return getLanguageFromToken();
    } catch {
      return undefined;
    }
  }
}

export { JwtDetector };
