import type { Event, Store } from 'effector';
import { combine } from 'effector';
import { createEvent, createStore, sample } from 'effector';
import { not, reset } from 'patronum';
import type { AnySchema } from 'yup';
import { object } from 'yup';
import type Lazy from 'yup/lib/Lazy';

import { modelFactory } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';
import { createForm } from '@kuna-pay/form';

type Params<
  T extends Record<string, unknown>,
  V extends Record<string, unknown>,
> = {
  $disabled?: Store<boolean>;

  mapRowToForm: (data: T) => {
    value: V[keyof V];

    schema: AnySchema | Lazy<AnySchema>;
  };

  getRowId: (row: T) => any;

  // to prevent losing data if outside save process failed
  closeEditorOn: (Event<any> | Event<void>)[];
};

type AttachParams<T> = {
  $rawData: Store<T[]>;
};

const DataGridEditorModel = modelFactory(
  <T extends Record<string, unknown>, V extends Record<string, unknown>>({
    $disabled,

    getRowId,

    mapRowToForm,

    closeEditorOn,
  }: Params<T, V>) => {
    const _reset = createEvent();

    const editClicked = createEvent();

    const saveClicked = createEvent();

    const cancelClicked = createEvent();

    const edited = createEvent<V>();

    const $schema = createStore<any>(null!);

    const $$form = createForm<V>({
      $disabled,

      initialValues: {} as V,

      schema: $schema,
    });

    const $editing = createStore(false);

    const $saveDisabled = not($$form.$dirty);

    const $cache = createStore<{ values: V; schema: AnySchema } | null>(null);

    const $meta = combine(
      $$form.$errors,
      $$form.$dirtyFields,
      $editing,

      (errors, dirty, _) => {
        const errorsMeta: Record<string, string> = {};

        const dirtyFields = Array.from(
          new Set(Object.keys(dirty).map((key) => key.split('.')[0]))
        );

        Object.entries(errors).forEach(([key, value]) => {
          const [id] = key.split('.');

          const _errors = Object.values(value as object) as string[];

          if (!dirtyFields.includes(id)) return;

          if (!errorsMeta[id] && _errors.length) {
            errorsMeta[id] = _errors[0];
          }
        });

        return {
          dirtyFields,

          errors: errorsMeta,
        };
      }
    );

    const $errors = combine($meta, (state) => state.errors);

    const attachToTable = ({ $rawData }: AttachParams<T>) => {
      sample({
        clock: $rawData.updates,

        fn: (data) => {
          const fields: Record<string, AnySchema | Lazy<AnySchema>> = {};

          const values: V = {} as V;

          data.forEach((item) => {
            const id = getRowId(item);

            const result = mapRowToForm(item);

            values[id as keyof V] = result.value;

            fields[id] = result.schema;
          });

          return {
            values,

            schema: object(fields),
          };
        },

        target: $cache,
      });

      sample({
        source: $cache.updates,

        fn: (cache) => cache?.schema ?? null,

        target: $schema,
      });

      sample({
        clock: $cache.updates,

        source: {
          editing: $editing,

          values: $$form.$values,
        },

        fn: ({ editing, values }, cache) => {
          const cached = (cache?.values ?? {}) as V;

          return editing ? { ...cached, ...values } : cached;
        },

        target: $$form.put,
      });
    };

    // start editing
    atom(() => {
      sample({
        clock: editClicked,

        fn: () => true,

        target: $editing,
      });

      sample({
        clock: editClicked,

        source: $cache,

        fn: (cache) => (cache?.values ?? {}) as V & { formError: string },

        target: $$form.put,
      });
    });

    // finish editing
    atom(() => {
      sample({
        clock: saveClicked,

        target: $$form.submit,
      });

      sample({
        clock: $$form.submitted,

        source: $meta,

        fn: ({ dirtyFields }, values) => {
          const result = values;

          Object.keys(values).forEach((key) => {
            if (!dirtyFields.includes(key)) {
              delete result[key as keyof V];
            }
          });

          return result;
        },

        target: edited,
      });

      sample({
        clock: [...closeEditorOn, cancelClicked],

        fn: () => false,

        target: [$editing, $$form.reset],
      });
    });

    // reset
    atom(() => {
      sample({
        clock: _reset,

        target: $$form.reset,
      });

      reset({
        clock: _reset,

        target: [$editing, $cache, $schema],
      });
    });

    return {
      reset: _reset,

      edited,

      $errors,
      $editing,

      $$ui: {
        $$form,

        $editing,
        $saveDisabled,

        editClicked,
        saveClicked,
        cancelClicked,

        getRowId,
      },

      __: {
        use: attachToTable,
      },
    };
  }
);

export { DataGridEditorModel };
