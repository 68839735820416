import {
  PresignFeedbackAttachmentUrlsOutput,
  MutationPublicGetPresignUrlsForFeedbackAttachmentsArgs,
} from '@kuna-pay/merchant/generated/graphql';
import { subscription, query } from '@kuna-pay/merchant/shared/api/graphql';
import {
  Select,
  buildSelectFragmentFromSelect,
} from '@kuna/graphql-util/dist/select';

export const publicGetPresignUrlsForFeedbackAttachmentsRequest = (
  select: Select<PresignFeedbackAttachmentUrlsOutput> | string
) => `mutation publicGetPresignUrlsForFeedbackAttachments($feedbackId: String!, $previews: [PresignFeedbackAttachmentUrlInput!]!) {
  data: publicGetPresignUrlsForFeedbackAttachments(
    feedbackId: $feedbackId
    previews: $previews
  ) {
    ...Select
  }
} ${buildSelectFragmentFromSelect(select, 'PresignFeedbackAttachmentUrlsOutput')}`;
export const publicGetPresignUrlsForFeedbackAttachments =
  (select: Select<PresignFeedbackAttachmentUrlsOutput> | string) =>
  (args: MutationPublicGetPresignUrlsForFeedbackAttachmentsArgs) =>
    query<PresignFeedbackAttachmentUrlsOutput>(
      publicGetPresignUrlsForFeedbackAttachmentsRequest(select),
      args
    );
