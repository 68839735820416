import type {
  GraphqlResponse,
  IGraphqlClient,
} from '@kuna/graphql-client/dist/interface';
import type { Event, EventCallable } from 'effector';
import {
  attach,
  combine,
  createEffect,
  createEvent,
  is,
  merge,
  restore,
  sample,
  scopeBind,
} from 'effector';
import { debug, not } from 'patronum';
import type { ZodSchema } from 'zod';
import { z } from 'zod';

import { bridge } from '@kuna-pay/utils/misc';

// eslint-disable-next-line @typescript-eslint/consistent-type-definitions
interface RxJSCompatibleObservable<T> {
  /**
   * Should use this interface bcs of type incompatibility between RxJS and @kuna/graphql-client
   *
   * @note: type incompatibility — lack of fields in Observable interface or presence of extra fields in Subscription interface
   */
  subscribe: (next: (value: T) => void) => { unsubscribe: () => void };
}

const createSubscriptionBindingToEffector = (
  subscription: IGraphqlClient['subscription']
) => {
  type AbstractSubscriptionConfig<T> = {
    start?: EventCallable<void>;
    stop?: EventCallable<void>;

    contract?: ZodSchema<T>;
  };

  type AbstractDebugConfig = {
    channel?: string;
    log?: boolean;
    logPrefix?: string;
    logUnits?: 'all' | 'events';
  };

  const createHeadlessSubscription = <T>(
    config: AbstractSubscriptionConfig<T> & {
      observable$: RxJSCompatibleObservable<GraphqlResponse<T>>;

      debug?: AbstractDebugConfig;
    }
  ) => {
    const start = config.start ?? createEvent();
    const stop = config.stop ?? createEvent();
    const contract = config.contract ?? z.any();

    const observable$ = config.observable$;

    const next = createEvent<GraphqlResponse<T>>();

    const subscribeFx = createEffect(() => {
      const scopedNext = scopeBind(next, { safe: true });

      return observable$.subscribe(scopedNext);
    });

    const $subscription = restore(subscribeFx, null);

    const unsubscribeFx = attach({
      source: $subscription,
      effect: (subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      },
    });

    bridge(() => {
      const $isSubscribed = combine($subscription, Boolean);

      sample({
        clock: start,
        filter: not($isSubscribed),
        target: subscribeFx,
      });
    });

    bridge(() => {
      sample({
        clock: stop,
        target: unsubscribeFx,
      });

      $subscription.on(unsubscribeFx.done, () => null);
    });

    const done = next.filterMap((response) => {
      // skip errors
      if (response.errors) return;

      return response.data;
    });

    const doneData = done.map((data) => data!.data);

    const doneValidated = doneData.filterMap((data) => {
      const result = contract.safeParse(data);

      if (!result.success) {
        console.log(`Failed to parse subscription data.
${config.debug?.channel ? `Channel: ${config.debug.channel}` : ''}
Error: ${result.error}
Data: ${data}`);

        return;
      }

      return result.data;
    }) as Event<T>;

    const fail = next.filterMap((response) => {
      if (!response.errors) return;

      return response.errors;
    });

    const failData = fail.map((errors) => errors[0]);
    const _finally = merge([done, fail]);

    if (config.debug?.log) {
      const units = Object.fromEntries(
        Object.entries({
          start,
          stop,
          next,
          subscribeFx,
          $subscription,
          unsubscribeFx,
          done,
          doneData,
          doneValidated,
          fail,
          failData,
          finally: _finally,
        }).filter(([_, value]) => {
          if (config.debug?.logUnits === 'events') {
            return is.event(value);
          }

          return true;
        })
      );

      debug(
        !config.debug?.logPrefix
          ? units
          : Object.fromEntries(
              Object.entries(units).map(([key, value]) => {
                // Ts is not smart enough to understand that we already checked for debug.logPrefix existence
                if (config.debug?.logPrefix) {
                  return [`${config.debug.logPrefix}/${key}`, value];
                }

                return [key, value];
              })
            )
      );
    }

    return {
      //commands
      start,
      stop,

      //events
      done,
      doneData,
      doneValidated,
      fail,
      failData,
      finally: _finally,
    };
  };

  const createChannelSubscription = <T>({
    channel,
    debug = {},
    ...config
  }: AbstractSubscriptionConfig<T> & {
    channel: string;

    debug?: Omit<AbstractDebugConfig, 'channel'>;
  }) => {
    const observable$ = subscription<T>(channel);

    return createHeadlessSubscription({
      ...config,

      observable$: observable$,

      debug: {
        logPrefix: `$$${channel}`,
        ...debug,
        channel,
      },
    });
  };

  return { createChannelSubscription, createHeadlessSubscription };
};

export { createSubscriptionBindingToEffector };
