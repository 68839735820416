import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { FC, HTMLAttributes, Key } from 'react';
import { forwardRef, Fragment } from 'react';

import type { PartialField } from '@kuna-pay/utils/typescript';

import type { TypographyProps } from '../../typography';
import { Typography } from '../../typography';
import { DataGridConfig } from '../config';
import { isActionColumn } from '../lib';
import {
  DataGridEditorModel,
  DataGridModel,
  useInfiniteLoading,
  useRedirectOnRowClick,
} from '../model';
import type { GridColumn, InsertConfig } from '../types';
import styles from './data.module.scss';

type TableBodyDataProps<T extends Record<string, unknown>> = {
  columns: GridColumn<T>[];
  gridTemplateColumns: string;

  insert?: InsertConfig<T>;
  classNames?: {
    row?: string;
  };
  getRowId: (row: T) => Key;
  addRowProps?: (row: T) => Partial<TableRowProps>;
};

const useEditor = () => {
  const $$editor = DataGridEditorModel.useModel({ required: false });

  if (!$$editor) {
    return {
      editing: false,
    };
  }

  return useUnit({
    editing: $$editor.$editing,
  });
};

const TableBodyData = <T extends Record<string, unknown>>({
  columns,
  gridTemplateColumns,
  getRowId,
  insert,
  addRowProps,
  classNames,
}: TableBodyDataProps<T>) => {
  const $$model = DataGridModel.useModel<T>();

  const { editing } = useEditor();

  const [rows, errors, onRowClick] = useUnit([
    $$model.$rows,
    $$model.$errors,
    $$model.$$row.onClick,
  ]);

  return (
    <>
      {rows.map((row) => {
        const id = getRowId(row);

        const hasInsert = insert && insert?.after === id;

        const error = errors[id as keyof typeof errors];

        return (
          <Fragment key={id}>
            <TableBodyRow
              row={row}
              className={classNames?.row}
              style={{ gridTemplateColumns }}
              hasSeparator={!hasInsert}
              error={error}
              onClick={() => onRowClick(row)}
              {...(addRowProps ? addRowProps(row) : {})}
            >
              {columns.map((column) => {
                //Explicit bcs of typescript can't narrow
                if (isActionColumn(column)) {
                  const Component = column.render;

                  return (
                    <Fragment key={`${column.field}:${column.label}`}>
                      <Component row={row} column={column} />
                    </Fragment>
                  );
                }

                if (column.renderEdit && editing) {
                  const Component = column.renderEdit;

                  return (
                    <Fragment key={`${column.field}:${column.label}`}>
                      <Component row={row} column={column} />
                    </Fragment>
                  );
                }

                if (column.renderCell) {
                  const Component = column.renderCell;

                  return (
                    <Fragment key={`${column.field}:${column.label}`}>
                      <Component row={row} column={column} />
                    </Fragment>
                  );
                }

                return (
                  <DataGridCell
                    key={`${column.field}:${column.label}`}
                    className={clsx(column.className, column.classes?.cell)}
                    {...(column.props?.cell ?? {})}
                  >
                    <DataGridCellText
                      className={column.classes?.text}
                      title={`${row[column.field] || column.fallbackValue}`}
                      {...(column.props?.text ?? {})}
                    >
                      <>{row[column.field] || column.fallbackValue}</>
                    </DataGridCellText>
                  </DataGridCell>
                );
              })}
            </TableBodyRow>

            {hasInsert && (
              <insert.view
                row={row}
                gridTemplateColumns={gridTemplateColumns}
              />
            )}
          </Fragment>
        );
      })}

      <TableBodyInfiniteLoader columnsLength={columns.length} />
    </>
  );
};

const TableBodyInfiniteLoader = <T extends Record<string, unknown>>({
  columnsLength,
}: {
  columnsLength: number;
}) => {
  const $$infiniteLoading = useInfiniteLoading<T>();

  return (
    <div
      ref={$$infiniteLoading.ref}
      style={{ height: 1, gridColumn: `1 / ${columnsLength + 1}` }}
    />
  );
};

type TableRowProps = HTMLAttributes<HTMLDivElement> & {
  interactive?: boolean;
  hasSeparator?: boolean;
  error?: string;
};

const TableBodyRow = <Row extends Record<string, unknown>>({
  row,
  onMouseUp,
  onClick,
  ...props
}: Omit<TableRowProps, 'interactive'> & { row: Row }) => {
  const $$model = DataGridModel.useModel<Row>();
  const redirect = useRedirectOnRowClick(row);

  return (
    <TableRow
      {...props}
      interactive={$$model.$$row.interactive || redirect.can}
      onClick={(event) => {
        redirect.onClick(event);

        onClick?.(event);
      }}
      onMouseUp={(event) => {
        redirect.onMouseUp(event);

        onMouseUp?.(event);
      }}
    />
  );
};

const TableRow: FC<TableRowProps> = ({
  className,
  children,
  interactive,
  hasSeparator,
  style = {},
  error,
  ...props
}) => (
  <div
    className={clsx(styles.row, className, {
      [styles.interactive]: interactive,
      [styles.hasSeparator]: hasSeparator,
      [styles.hasError]: !!error,
    })}
    style={{
      ...style,
      paddingLeft: DataGridConfig.ROW_PADDING,
      paddingRight: DataGridConfig.ROW_PADDING,
    }}
    {...props}
  >
    {children}

    {error && <div className={styles.rowError}>{error}</div>}
  </div>
);

type TableCellProps = HTMLAttributes<HTMLDivElement> & {
  stopPropagation?: boolean;
};
const DataGridCell: FC<TableCellProps> = ({
  className,
  stopPropagation,
  onClick,
  ...props
}) => (
  <div
    className={clsx(styles.cell, className)}
    onClick={(e) => {
      if (stopPropagation) e.stopPropagation();
      onClick?.(e);
    }}
    {...props}
  />
);

const DataGridCellText = forwardRef<
  HTMLSpanElement,
  PartialField<TypographyProps, 'variant'>
>(({ className, ...props }, ref) => (
  <Typography
    ref={ref}
    className={clsx(styles.cellText, className)}
    variant='numberAdmin1'
    nowrap
    {...props}
    title={
      props.title ??
      (typeof props.children === 'string' ? props.children : undefined)
    }
  />
));

export { DataGridCell, DataGridCellText, TableBodyData, TableRow };
export type { TableCellProps, TableRowProps };
