import { createEffect } from 'effector';
import { createGate } from 'effector-react';

import { getState, listen } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';
import { objectKeys } from '@kuna-pay/utils/typescript';
import type { GetDataGridDataParam } from '@kuna-pay/ui/ui/table';
import { DataGrid } from '@kuna-pay/ui/ui/table';
import type { CoreAsset } from '@kuna-pay/core/entities/asset';
import { ASSET_SELECT } from '@kuna-pay/core/entities/asset';
import { createEQuery } from '@kuna-pay/core/shared/lib/effector-query';

import type { NotificationOutput } from '@kuna-pay/merchant/generated/graphql';
import { findManyAsset } from '@kuna-pay/merchant/shared/api/generated/Asset/request/findManyAsset';
import { findCountNotReadNotification } from '@kuna-pay/merchant/shared/api/generated/PushNotifications/request/findCountNotReadNotification';
import { findManyNotifications } from '@kuna-pay/merchant/shared/api/generated/PushNotifications/request/findManyNotifications';
import { createWebsocketSubscription } from '@kuna-pay/merchant/shared/api/ws';

import { OptimisticReadPushNotificationModel } from './optimistic-read-push-notification.model';

const $$pushNotifications = atom(() => {
  const Gate = createGate<{ companyId: string }>();

  const $$assets = createEQuery({
    initialData: {} as Record<CoreAsset['code'], CoreAsset>,
    query: async (_: void) => {
      const assets = await findManyAsset(ASSET_SELECT)({ skip: 0, take: 100 });

      return assets.reduce(
        (acc, asset) => {
          acc[asset.code] = asset;

          return acc;
        },
        {} as Record<CoreAsset['code'], CoreAsset>
      );
    },
  });

  const $$unreadNotificationCounter = createEQuery({
    initialData: null,
    query: async (_: void) => {
      const { count } = await findCountNotReadNotification({ count: true })();

      return { count };
    },
  });

  const $$notifications = DataGrid.factory.createModel({
    getDataFx: createEffect(
      async ({ take, skip }: GetDataGridDataParam<NotificationOutput>) => {
        const notifications = await findManyNotifications({
          id: true,
          userId: true,
          companyId: true,
          createdAt: true,
          entityId: true,
          group: true,
          payload: true,
          template: true,
          isRead: true,
        })({ skip, take });

        return notifications;
      }
    ),
  });

  const $$newNotificationSubscription = createWebsocketSubscription({
    channel: 'NotificationCreated',
  });

  const $$notificationCountSubscription = createWebsocketSubscription({
    channel: 'NotificationRead',
  });

  const { readNotificationFx, readAllNotificationFx } =
    OptimisticReadPushNotificationModel.createModel({
      $$notifications: {
        $data: $$notifications.$rawData,
      },
      $$counter: $$unreadNotificationCounter,
    });

  listen({
    clock: Gate.open,
    handler: async () => {
      const assets = await getState($$assets.$data);

      if (objectKeys(assets).length === 0) {
        void $$assets.startFx();
      } else {
        void $$assets.refreshFx();
      }
      $$notificationCountSubscription.start();
      $$newNotificationSubscription.start();
      $$notifications.load();
      void $$unreadNotificationCounter.startFx();
    },
  });

  listen({
    clock: $$notificationCountSubscription.doneData,
    handler: () => {
      void $$unreadNotificationCounter.refreshFx();
    },
  });

  listen({
    clock: $$newNotificationSubscription.doneData,
    handler: () => {
      $$notifications.refetch();
      void $$unreadNotificationCounter.refreshFx();
    },
  });

  listen({
    clock: Gate.close,
    handler: () => {
      $$notifications.reset();
      $$unreadNotificationCounter.reset();
      $$newNotificationSubscription.stop();
      $$notificationCountSubscription.stop();
    },
  });

  return {
    Gate,

    $$assets,
    $$notifications,
    $$unreadNotificationCounter,

    $$newNotificationSubscription,
    $$notificationCountSubscription,

    readNotificationFx,
    readAllNotificationFx,
  };
});

export { $$pushNotifications };
