import type { Store } from 'effector';
import { persist } from 'effector-storage/local';

import { LocalStorageSerializer } from './serialize.persist';

class APIPersistService {
  public constructor(public readonly key: string) {}

  public read(): string | null {
    try {
      return LocalStorageSerializer.deserialize(localStorage.getItem(this.key));
    } catch {
      return null;
    }
  }

  public write(value: string): void {
    try {
      localStorage.setItem(this.key, LocalStorageSerializer.serialize(value));
    } catch {
      // pass
    }
  }

  public connect($store: Store<string | null>): void {
    persist({
      store: $store,
      key: this.key,
      deserialize: LocalStorageSerializer.deserialize,
      serialize: LocalStorageSerializer.serialize,
    });
  }
}

export { APIPersistService };
