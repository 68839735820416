import type {
  Effect,
  EffectParams,
  Event,
  EventCallable,
  Store,
  StoreWritable,
} from 'effector';
import { attach, combine, createEvent, createStore, sample } from 'effector';
import { not } from 'patronum';
import type { PropsWithChildren } from 'react';
import { createContext, createElement, useContext } from 'react';

import { atom, bridge } from '@kuna-pay/utils/misc';
import { objectEntries } from '@kuna-pay/utils/typescript';

import { DataGridConfig } from '../config';
import { sortMapToOrderByArray } from '../lib';
import type { GridValueColumn } from '../types';
import { SortOrder } from '../types';
import type { DataGridEditorModel } from './edit-data';
import type { PublicRedirectConfig } from './redirect.model';
import { RedirectModel } from './redirect.model';

type GenericFilter<T> = { $filter: Store<T>; changed: Event<unknown> };

type BasicSearchFilter = GenericFilter<{ query: string }>;
type AdvancedSearchFilter = GenericFilter<{
  filter: string;
  query: string;
} | null>;
type SearchFilter = BasicSearchFilter | AdvancedSearchFilter;

type Filter = SearchFilter;

type GetDataGridDataParam<TRow extends Record<string, unknown>> = {
  skip: number;
  take: number;
  //TODO: Omit keys which are Relations
  orderBy?: Partial<{ [Key in keyof TRow]: SortOrder }>[];
};

type DataGridModelConfig<Row extends Record<string, unknown>> = {
  // For debug purposes
  name?: string;

  getDataFx: Effect<GetDataGridDataParam<NoInfer<Row>>, Row[]>;

  limit?: number;

  disableInfinityScroll?: boolean;

  default?: {
    skip?: number;
    sort?: Partial<{ [Key in keyof Row]: SortOrder }>;
  };

  sort?:
    | {
        type?: 'async';
        single?: boolean;
      }
    | {
        type: 'sync';
        single: true;
        comparator: (
          field: keyof Row,
          order: SortOrder,
          a: Row,
          b: Row
        ) => number;
      };

  $$editor?: ReturnType<typeof DataGridEditorModel.createModel<Row, any>>;

  enrich?: ($rows: Store<Row[]>) => Store<Row[]>;

  filter?: ($rows: Store<Row[]>) => Store<Row[]>;

  $$row?: {
    redirect?: PublicRedirectConfig<Row>;

    $$on?: {
      click?: EventCallable<Row>;
    };
  };
};

const DataGridModel = atom(() => {
  const createModel = <Row extends Record<string, unknown>>(
    config: DataGridModelConfig<Row>
  ) => {
    type SortMap = Partial<Record<keyof Row, SortOrder>>;

    const name = config.name ?? '$$datagrid.unnamed';

    //commands
    const $$load = atom(() => {
      const start = createEvent(`${name}.$$load.start`);
      const done = createEvent<Row[]>(`${name}.$$load.done`);
      const fail = createEvent<Error>(`${name}.$$load.fail`);
      const reset = createEvent(`${name}.$$load.reset`);

      const $pending = createStore(false, {
        name: `${name}.$$load.$pending`,
      });

      bridge(() => {
        $pending
          .on(start, () => true)
          .on(done, () => false)
          .on(fail, () => false)
          .reset(reset);
      });

      return { start, done, fail, reset, $pending };
    });

    const reload = createEvent(`${name}.reload`);
    const refetch = createEvent(`${name}.reload`);
    const reset = createEvent(`${name}.reset`);

    //private
    const getDataFx = attach({
      name: `${name}.getDataFx`,

      //TODO: Remove after https://github.com/effector/effector/issues/1000
      mapParams: (params: EffectParams<typeof config.getDataFx>, _: void) =>
        params,

      effect: config.getDataFx,
    });

    const endReached = createEvent(`${name}.endReached`);
    const reloadClicked = createEvent(`${name}.reloadClicked`);
    const headColumnClicked = createEvent<
      Omit<GridValueColumn<Row>, 'renderCell'>
    >(`${name}.headColumnClicked`);

    const sortableColumnClicked = createEvent<{
      key: string | keyof Row;
      value: SortOrder;
    }>(`${name}.sortableColumnClicked`);

    const replaceRow = createEvent<{
      comparator: (row: Row) => boolean;
      data: Row;
    }>(`${name}.updateRow`);

    const $rows = createStore<Row[]>([], { name: `${name}.$rawData` }).on(
      replaceRow,
      (rows, { comparator, data }) =>
        rows.map((row) => {
          if (comparator(row)) {
            return data;
          }

          return row;
        })
    );

    const $isError = createStore(false, {
      name: `${name}.$isError`,
    });

    const $take = createStore(config.limit ?? DataGridConfig.DEFAULT_LIMIT, {
      name: `${name}.$limit`,
    });

    const $sort = createStore<SortMap>(config.default?.sort ?? {}, {
      name: `${name}.$sort`,
    });

    //derived
    const $orderBy = combine($sort, sortMapToOrderByArray);

    let $errors:
      | StoreWritable<Record<string, string>>
      | Store<Record<string, string>> = createStore<Record<string, string>>(
      {},
      {
        name: `${name}.$errors`,
      }
    );

    if (config.$$editor) {
      config.$$editor.__.use({
        $rawData: $rows,
      });

      $errors = config.$$editor.$errors;
    }

    const refetchDataFx = attach({
      name: `${name}.getInitialDataFx`,

      //TODO: Remove after https://github.com/effector/effector/issues/1000
      mapParams: (params: EffectParams<typeof getDataFx>, _: void) => params,

      effect: getDataFx,
    });

    const getInitialDataFx = attach({
      name: `${name}.getInitialDataFx`,

      //TODO: Remove after https://github.com/effector/effector/issues/1000
      mapParams: (params: EffectParams<typeof getDataFx>, _: void) => params,

      effect: refetchDataFx,
    });

    bridge(() => {
      /**
       * @note Backward compatibility
       * Bcs in some places we use `$$table.load` in clock + layout loader,
       * which by default not fire `$$table.load` event
       */
      sample({
        clock: $$load.start,
        target: reload,
      });

      sample({
        clock: reload,
        //[$$load.start, reload]

        source: {
          take: $take,
          orderBy: $orderBy,
        },

        filter: not(refetchDataFx.pending),

        fn: ({ take, orderBy }) => ({
          take,
          orderBy,
          skip: config?.default?.skip ?? 0,
        }),

        target: getInitialDataFx,
      });

      sample({
        clock: refetch,
        //[$$load.start, reload]

        source: {
          take: $take,
          orderBy: $orderBy,
        },

        filter: not(refetchDataFx.pending),

        fn: ({ take, orderBy }) => ({
          take,
          orderBy,
          skip: config?.default?.skip ?? 0,
        }),

        target: refetchDataFx,
      });

      $rows.on(refetchDataFx.doneData, (_, rows) => rows);

      sample({
        clock: refetchDataFx.doneData,
        target: $$load.done,
      });
    });

    bridge(() => {
      $isError
        .on(refetchDataFx, () => false)
        .on(refetchDataFx.fail, () => true);

      sample({
        clock: refetchDataFx.failData,
        target: $$load.fail,
      });

      sample({
        clock: reloadClicked,
        target: reload,
      });
    });

    const { $isAllDataLoaded, loadMoreFx } = bridge(() => {
      const loadMoreFx = attach({
        name: `${name}.loadMoreFx`,

        //TODO: Remove after https://github.com/effector/effector/issues/1000
        mapParams: (params: EffectParams<typeof getDataFx>, _: void) => params,

        effect: getDataFx,
      });

      const $skip = combine($rows, (rows) => rows.length);

      const $isAllDataLoaded = combine(
        $take,

        $skip,

        (take, skip) => {
          if (config.disableInfinityScroll) return true;

          return skip % take !== 0;
        }
      );

      const $canLoadMore = combine(
        loadMoreFx.pending,
        $isAllDataLoaded,
        (pending, isAllDataLoaded) => !pending && !isAllDataLoaded
      );

      sample({
        clock: endReached,

        source: {
          take: $take,
          skip: $skip,
          orderBy: $orderBy,
        },

        filter: $canLoadMore,

        target: loadMoreFx,
      });

      $rows.on(loadMoreFx.doneData, (rows, newRows) => [...rows, ...newRows]);

      return { $isAllDataLoaded, loadMoreFx };
    });

    bridge(() => {
      $sort.on(
        sample({
          clock: headColumnClicked,
          filter: (column) => !!column.sortable,
        }),
        (sort, { field: column }) => {
          const currentSortOrder = sort[column];

          // 1. No selected -> Desc
          // 2. Desc -> ASC
          // 3. ASC -> No selected

          if (!currentSortOrder) {
            if (!!config.sort?.single) {
              return { [column]: SortOrder.Desc } as SortMap;
            }

            return { ...sort, [column]: SortOrder.Desc };
          }

          if (currentSortOrder === SortOrder.Desc) {
            if (!!config.sort?.single) {
              return { [column]: SortOrder.Asc } as SortMap;
            }

            return { ...sort, [column]: SortOrder.Asc };
          }

          if (currentSortOrder === SortOrder.Asc) {
            if (!!config.sort?.single) {
              return {} as SortMap;
            }

            const newSort = { ...sort };
            delete newSort[column];

            return newSort;
          }

          return sort;
        }
      );

      $sort.on(sortableColumnClicked, (sort, { key, value }) => {
        if (!!config.sort?.single) {
          return { [key]: value } as SortMap;
        }

        return { ...sort, [key]: value };
      });

      sample({
        clock: $sort,
        filter: () => config.sort?.type !== 'sync', // TODO: Add default values to config.sort and check sort.type  === 'async'
        target: reload,
      });
    });

    bridge(() => {
      $rows.reset(reset);
      $sort.reset(reset);

      sample({
        clock: reset,
        target: $$load.reset,
      });
    });

    const $enrichedData = config.enrich ? config.enrich($rows) : $rows;

    const $filteredData = config.filter
      ? config.filter($enrichedData)
      : $enrichedData;

    const $sortedRows = combine($filteredData, $sort, (rows, sort) => {
      if (
        !(config.sort && config.sort.type === 'sync') ||
        !Object.values(sort).length
      ) {
        return rows;
      }

      const comparator = config.sort.comparator;

      const [sortOptions] = objectEntries(sort)
        .filter(([_, order]) => !!order)

        .slice(0, 1)

        .map(([field, order]) => ({
          field,
          order: order!,
        }));

      return rows
        .slice()

        .sort((a, b) => comparator(sortOptions.field, sortOptions.order, a, b));
    });

    const rowClicked =
      config.$$row?.$$on?.click ?? createEvent<Row>(`${name}.rowClicked`);

    return {
      $$load,

      load: reload,
      refetch,
      reload,

      reset,

      $rawData: $rows,
      $data: $enrichedData,
      $rows: $sortedRows,
      $pending: getInitialDataFx.pending,
      $loading: refetchDataFx.pending,

      $$sort: {
        $sort,
        sortableColumnClicked,
      },

      $$row: {
        replaceRow,
        clicked: rowClicked,
      },

      $$loadMore: {
        fail: loadMoreFx.fail,
      },

      $$ui: {
        $errors,
        $isError,
        $rows: $sortedRows,
        $isNotFound: combine($sortedRows, (rows) => rows.length === 0),

        $sort,
        $limit: $take,

        $rowsCount: combine($sortedRows, (rows) => rows.length),

        $initialDataLoading: getInitialDataFx.pending,

        $$row: {
          interactive: !!config.$$row?.$$on?.click,
          $$redirect: RedirectModel.createModel(config.$$row?.redirect),
          onClick: rowClicked,
        },

        $$head: {
          clicked: headColumnClicked,
        },

        $$error: {
          reloadClicked,
        },

        $$infiniteScroll: {
          $isAllDataLoaded,

          endReached,
        },
      },

      __: {
        getDataFx,
        getInitialDataFx,
        loadMoreFx,
      },
    };
  };

  const Context = createContext<ReturnType<typeof createModel>>(null as never);

  type ProviderProps<T extends Record<string, unknown>> = PropsWithChildren & {
    value: ReturnType<typeof createModel<T>>['$$ui'];
  };

  return {
    createModel: createModel,

    useModel: function <T extends Record<string, unknown>>() {
      const model = useContext(Context);

      return model as unknown as ReturnType<typeof createModel<T>>['$$ui'];
    },

    Provider: function <T extends Record<string, unknown>>({
      value,
      children,
    }: ProviderProps<T>) {
      return createElement(
        Context.Provider,
        { value: value as unknown as GenericModel },
        children
      );
    },
  };
});

type SpecificModel<T extends Record<string, unknown>> = ReturnType<
  typeof DataGridModel.createModel<T>
>;

type GenericModel = ReturnType<typeof DataGridModel.createModel>;

export { DataGridModel };
export type {
  AdvancedSearchFilter,
  BasicSearchFilter,
  Filter,
  GenericModel,
  GetDataGridDataParam,
  SearchFilter,
  SpecificModel,
};
