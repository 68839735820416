import { createEvent, createStore } from 'effector';

import { listen, setState } from '@kuna-pay/utils/effector';
import { atom } from '@kuna-pay/utils/misc';

import { $$session } from '@kuna-pay/merchant/entities/session';
import { FreshDeskSDK } from '@kuna-pay/merchant/features/contact-support/fresh-desk';
import { $$api } from '@kuna-pay/merchant/shared/api';
import { disableSession } from '@kuna-pay/merchant/shared/api/generated/Auth/request/disableSession';
import { $$router, routes } from '@kuna-pay/merchant/shared/router';

const $$logout = atom(() => {
  const logout = createEvent();
  const $isPending = createStore(false);

  listen({
    clock: logout,
    source: {
      accessTokenJwt: $$session.$$jwt.$accessTokenJwt,
      isPending: $isPending,
    },
    handler: async (_, { isPending, accessTokenJwt }) => {
      try {
        setState($isPending, true);

        if (accessTokenJwt && !isPending) {
          await disableSession({ success: true })({
            fingerprint: accessTokenJwt.fingerprint,
          });
        }

        FreshDeskSDK.logout();
      } catch {
        //pass
      } finally {
        $$api.clearTokens();

        // TODO: Move before `disableSession` call (?)
        $$router.redirect(routes.auth.signIn);

        setState($isPending, false);
      }
    },
  });

  listen({
    clock: $$api.tokenService.onLoggedOut,
    handler: () => {
      $$router.redirect(routes.auth.signIn);
    },
  });

  return {
    logout,
  };
});

export { $$logout };
