import { useUnit } from 'effector-react';
import type { JSX, ReactNode } from 'react';

import { modelView } from '@kuna-pay/utils/effector';
import { invariant } from '@kuna-pay/utils/invariant';
import type { FieldModel, FormModel } from '@kuna-pay/form/lib';

import { Button, ButtonGroup } from '../../button';
import { DataGridEditorModel } from '../model';
import type { GridCustomValueColumnProps } from '../types';
import { DataGridCell } from './data';
import styles from './edit-data.module.scss';

type EditGridDataControlsProps = {
  text: {
    edit: ReactNode;
  };
};

const EditGridDataControls = modelView(
  DataGridEditorModel,
  ({ text }: EditGridDataControlsProps) => {
    const $$model = DataGridEditorModel.useModel();

    const [
      disabled,
      editing,
      saveDisabled,
      onCancelClick,
      onSaveClick,
      onEditClick,
    ] = useUnit([
      $$model.$$form.$disabled,
      $$model.$editing,
      $$model.$saveDisabled,
      $$model.cancelClicked,
      $$model.saveClicked,
      $$model.editClicked,
    ]);

    if (editing) {
      return (
        <ButtonGroup className={styles.buttons}>
          <Button
            variant='contained'
            color='secondary'
            size='md'
            onClick={onCancelClick}
            disabled={disabled}
          >
            Cancel Changes
          </Button>

          <Button
            variant='contained'
            color='primary'
            size='md'
            disabled={saveDisabled}
            onClick={onSaveClick}
            loading={disabled}
          >
            Save Changes
          </Button>
        </ButtonGroup>
      );
    }

    return (
      <ButtonGroup>
        <Button
          variant='contained'
          color='primary'
          size='md'
          onClick={onEditClick}
        >
          {text.edit}
        </Button>
      </ButtonGroup>
    );
  }
);

type CellEditorParams<T> = {
  name: string;

  render?: ({
    row,
  }: {
    row: T;
    field: FieldModel<any>;
    fields: Record<keyof T, FieldModel<any>>;
    form: FormModel<any>;
  }) => JSX.Element;

  renderCell?: ({
    row,
  }: {
    row: T;
    field: FieldModel<any>;
    form: FormModel<any>;
  }) => JSX.Element;
};

const useCellEditField = ({
  row,
  name,
}: Pick<GridCustomValueColumnProps<any>, 'row'> & {
  name: string;
}) => {
  const $$model = DataGridEditorModel.useModel();

  const id = $$model.getRowId(row);

  const fields = $$model.$$form.fields[
    id as keyof typeof $$model.$$form.fields
  ] as any as Record<string, FieldModel<any>>;

  const field = fields[name as keyof typeof fields];

  return { form: $$model.$$form, fields, field, id };
};

const CellEditor = Object.assign(
  ({
    render: Component,
    renderCell: RenderCell,
    name,
  }: CellEditorParams<any>) =>
    ({ row }: GridCustomValueColumnProps<any>) => {
      const { fields, field, id, form } = useCellEditField({ row, name });

      if (RenderCell) {
        return <RenderCell key={id} row={row} field={field} form={form} />;
      }

      if (!Component) {
        invariant.log(
          Component,
          `Either render or renderCell must be provided to CellEditor. Row id: ${id}`
        );

        return null;
      }

      return (
        <DataGridCell key={id}>
          <Component field={field} row={row} fields={fields} form={form} />
        </DataGridCell>
      );
    },
  {
    useCellEditField,
  }
);

export { CellEditor, EditGridDataControls };
