import type { FC, PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';

import { UIProvider } from '@kuna-pay/ui/provider';

import { i18next } from '@kuna-pay/merchant/shared/i18n';

const Provider: FC<PropsWithChildren> = ({ children }) => (
  <I18nextProvider i18n={i18next}>
    <UIProvider position='top-right'>{children}</UIProvider>
  </I18nextProvider>
);

const withProvider = (Component: FC) => () => (
  <Provider>
    <Component />
  </Provider>
);

export { Provider, withProvider };
