import { jwtDecode } from 'jwt-decode';

import { $$api } from '@kuna-pay/merchant/shared/api';

function getLanguageFromToken(): string | undefined {
  const payload = $$api.tokenService.accessTokenPersistService.read();

  if (!payload) return undefined;

  const decoded = jwtDecode<{ language: string }>(payload);

  if (!decoded) return undefined;

  return decoded.language;
}

export { getLanguageFromToken };
