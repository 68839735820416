import clsx from 'clsx';
import { useUnit } from 'effector-react';
import type { FC, HTMLAttributes, ImgHTMLAttributes } from 'react';

import { createCompoundComponent } from '@kuna-pay/utils/ui';
import { ResetIcon } from '@kuna-pay/ui/icons';
import type { ButtonProps } from '@kuna-pay/ui/ui/button';
import { Button } from '@kuna-pay/ui/ui/button';

import type { TypographyProps } from '../../typography';
import { Typography } from '../../typography';
import emptyBoxImg from '../assets/empty.png';
import { DataGridModel } from '../model';
import styles from './error.module.scss';

/**
 * TODO: Add i18n
 */
const DataGridError = createCompoundComponent(
  ({ Root, Image, Title, Description, ReloadButton }): FC =>
    () => (
      <Root>
        <Image />

        <Title>Unable to load the data</Title>

        <Description>
          This can happen if you are not connected to the internet, or server
          error
        </Description>

        <ReloadButton>Refresh page</ReloadButton>
      </Root>
    ),

  {
    Root: ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
      <div className={clsx(styles.root, className)} {...props} />
    ),

    Image: (props: ImgHTMLAttributes<HTMLImageElement>) => (
      <img src={emptyBoxImg} width={140} height={73} alt='' {...props} />
    ),

    Title: ({ className, ...props }: Partial<TypographyProps>) => (
      <Typography
        as='p'
        className={clsx(styles.title, className)}
        variant='numberAdmin2'
        center
        {...props}
      />
    ),

    Description: ({ className, ...props }: Partial<TypographyProps>) => (
      <Typography
        as='p'
        className={clsx(styles.subtitle, className)}
        variant='numbers2'
        center
        {...props}
      />
    ),

    ReloadButton: (props: Omit<Partial<ButtonProps>, 'onClick'>) => {
      const $$model = DataGridModel.useModel();
      const { onReloadClick } = useUnit({
        onReloadClick: $$model.$$error.reloadClicked,
      });

      return (
        <Button
          variant='text'
          color='primary'
          size='md'
          onClick={onReloadClick}
          start={<ResetIcon />}
          {...props}
        />
      );
    },
  }
);

export { DataGridError };
